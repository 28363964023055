<template>
  <div class="card-user-information">
  <v-row no-gutters :class="{ 'active': rowContent.isActive, 'mobile': mobileView }" class="metadata-row pill">

    <!-- Full View -->
    <template v-if="!mobileView">
      <v-col md="auto" class="ma-3" align-self="center" cols="auto">
        <SAvatar :src="rowContent.avatar" :size="70" :fallback-icon="rowContent.avatarIcon ? rowContent.avatarIcon : '$user'"
                 :fallback-icon-color="iconColor" fallback-background-color="mutedContrast"/>
      </v-col>
      <v-col class="middle-container" cols="9" dense align-self="center">
        <v-container>
          <v-row no-gutters class="text-row">
            <v-col cols="5" class="bold">
              {{ rowContent.upper.info }}
            </v-col>
            <v-col class="secondaryText--text" style="text-align: right">
              {{ rowContent.upper.key }}{{ rowContent.upper.key ? ':' : "" }}
            </v-col>
            <v-col cols="4" class="indented-text">
              {{ rowContent.upper.value }}
            </v-col>
          </v-row>
          <v-row no-gutters class="text-row">
            <v-col cols="5">
              {{ rowContent.middle.info.primary }}
              <span class="secondaryText--text"> {{ rowContent.middle.info.secondary }}</span>
            </v-col>
            <v-col class="secondaryText--text" style="text-align: right">
              {{ rowContent.middle.key }}{{ rowContent.middle.key ? ':' : "" }}
            </v-col>
            <v-col cols="4" class="indented-text">
              {{ rowContent.middle.value }}
            </v-col>
          </v-row>
          <v-row no-gutters class="text-row">
            <STooltip bottom open-delay="500" :disabled="rowContent.lower.length < 120" z-index="1000">
              <template #activator="{on}">
                <div v-on="on" class="lower-text">
                  {{ rowContent.lower }}
                </div>
              </template>
              <div> {{rowContent.lower}} </div>
            </STooltip>
          </v-row>
        </v-container>
      </v-col>
      <v-col md="auto" class="ma-3" dense align-self="center" cols="auto">
        <div>
          <STooltip bottom>
            <template #activator="{ on }">
              <SAvatar v-on="on"
                       :size="70"
                       :fallback-icon="rowContent.icon.text"
                       :fallback-icon-color="rowContent.icon.color"
                       :fallback-background-color="rowContent.icon.background"
              />
            </template>
            <span>  {{rowContent.icon.tooltipText}}  </span>
          </STooltip>
        </div>
      </v-col>
    </template>


    <!-- Mobile View -->
    <template v-else>
      <v-col class="ma-2" cols="1">
        <SAvatar :src="rowContent.avatar" :size="30" :fallback-icon="rowContent.avatarIcon ? rowContent.avatarIcon : '$user'"
                 :fallback-icon-color="iconColor" fallback-background-color="mutedContrast"/>
      </v-col>
      <v-col class="ma-1">
        <div class="bold">{{ rowContent.upper.info }}</div>
        <div class="d-flex flex-wrap">
          <div>{{ rowContent.middle.info.primary}}&nbsp;</div>
          <div class="secondaryText--text">{{rowContent.middle.info.secondary }}</div>
        </div>
        <v-row no-gutters :class="{'mt-5': rowContent.upper.value || rowContent.middle.value}">
          <v-col cols="5">
            <div class="d-flex align-center">
              <v-icon v-if="rowContent.middle.value" v-text="'$' + mobileIcon(rowContent.middle)" size="15" class="mr-2"/>
              <div class="text-no-wrap">{{ rowContent.middle.value }}</div>
            </div>
          </v-col>
          <v-col cols="7">
            <div class="d-flex align-center">
              <v-icon v-if="rowContent.upper.value" v-text="'$' + mobileIcon(rowContent.upper)" size="15" class="mr-2"/>
              <div>{{ rowContent.upper.value }}</div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1" class="ma-2 d-flex justify-end">
        <STooltip bottom>
          <template #activator="{ on }">
            <SAvatar v-on="on"
                     :src="undefined" :size="30" :fallback-icon="rowContent.icon.text"
                     :fallback-icon-color="rowContent.icon.color"
                     :fallback-background-color="rowContent.icon.background"
            />
          </template>
          <span> {{ rowContent.icon.tooltipText }} </span>
        </STooltip>
      </v-col>
    </template>



  </v-row>
  </div>
</template>

<script>
import {IMAGE_DATA_URI_PREFIX} from "@/utils/constants";
import SAvatar from "@/component/ui/SAvatar";
import {ROW_TYPE} from "@/mixins/metadataMixin";
import {formatDateShort} from "@/utils/dateUtils";
import {nameToDisplay} from "@/utils/accountUtils";
import STooltip from "@/component/ui/STooltip.vue";

export default {
  name: 'MetadataRow',
  components: {STooltip, SAvatar},

  props: {

    /*  Info - text zarovnany vlevo
     *  Key - zarovnany vpravo
     *  Value - zarovnany vlevo
     *  Lower - posledni radek ma jen jeden sloupec s textem
     *  *MiddleInfo:
     *    - primary - tucny text na zacatku (obvykle jmeno)
     *    - secondary - normalni text na konci (obvykle akce)
     *
     *   +---------+---------------+-------------+-----------------+---------+
     *   |         |  UpperInfo... |  ...UpperKey | UpperValue...  |         |
     *   |         +---------------+--------------+----------------+         |
     *   |  Avatar | MiddleInfo*.. | ...MiddleKey | MiddleValue... |  Icon   |
     *   |         +---------------+--------------+----------------+         |
     *   |         | Lower........................................ |         |
     *   +---------+-----------------------------------------------+---------+
     */
    rowContent: {
      avatar: String,
      avatarIcon: String,
      upper: {
        info: String,
        key: String,
        value: String
      },
      middle: {
        info: {
          primary: String,
          secondary: String
        },
        key: String,
        value: String
      },
      lower: String,
      lowerSecondaryText: Boolean,
      icon: {
        text: String,
        background: String,
        color: String,
        tooltipText: String
      }
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    mobileView: Boolean
  },

  data() {
    return {
      imagePrefix: IMAGE_DATA_URI_PREFIX
    }
  },

  computed: {

    iconForDisplay() {
      if (!this.step)
        if (this.flowState.toLowerCase() === 'draft')
          return {
            text: '$draft',
            background: 'mainBackground',
            color: 'mutedContrast'
          };
        else
          return {
            text: '$send',
            background: 'secondaryContrast',
            color: 'secondaryContrastText'
          };
      if (this.step.state.toLowerCase() === 'finished')
        return {
          text: '$sig',
          background: 'secondaryContrast',
          color: 'secondaryContrastText'
        }
      else if (this.step.state.toLowerCase() === 'rejected')
        return {'text': '$rejectDoc', 'background': 'mainContrast', 'color': 'mainContrastText'};
      else
        return {
          text: '$hourglass',
          background: 'mainBackground',
          color: 'mutedContrast'
        };
    },

    isActive() {
      return this.step && (this.flowCurrentPhase === this.step.stepOrder);
    },

    actionToDisplay: function () {
      if (!this.step) {
        return this.flowState === 'draft' ?
            this.$t('docDetail.metadata.ownerDrafted')
            : this.$t('docDetail.metadata.ownerSent');
      }

      let action = '';

      switch (this.step.state.toLowerCase()) {
        case 'new':
          action = this.flowState === 'draft' ? '' : this.$t('docDetail.metadata.userWaiting');
          break;
        case 'active':
        case 'processing':
        case 'error':
          action = this.$t('docDetail.metadata.usersTurn');
          break;
        case 'finished':
          action = this.$t('docDetail.metadata.userSigned');
          break;
        case 'rejected':
          action = this.$t('docDetail.metadata.userRejected');
          break;
        case 'inactive':
          action = '';
          break;
      }

      return action;
    },

    userNameToDisplay() {
      return nameToDisplay(this.step ? this.step._embedded.account : this.flowOwner);
    },

    actionDateToDisplay() {
      if (this.step)
        if (this.step.state.toLowerCase() === 'finished' || this.step.state.toLowerCase() === 'rejected')
          return formatDateShort(this.step.changedAt);
        else if (this.step.dueDate)
          return this.$t('docDetail.metadata.until') + ' ' + formatDateShort(this.step.dueDate);
        else
          return this.$t('docDetail.metadata.noDueDate');
      else
        return formatDateShort(this.flowCreationDate);
    },

    dueDateToDisplay() {
      return this.step ? formatDateShort(this.step.dueDate) : this.flowDueDate;
    },

    methodUsedToDisplay() {
      return (this.step && this.step.state.toLowerCase() === 'finished')
          ? this.step.signingMethodName : '';
    },

    backgroundColor() {
      return this.darkMode ? 'secondaryText' : 'transparent';
    },

    avatarColor() {
      return this.darkMode ? 'mainBackground' : 'mutedText';
    },

    iconColor() {
      return this.darkMode ? 'black' : 'white';
    }

  },

  methods: {
    mobileIcon(row){
      switch (row.type) {
        case ROW_TYPE.TIME:
          return 'clock';
        case ROW_TYPE.SIGNATURE_LEVEL:
          return 'stepCurrent';
        case ROW_TYPE.PEOPLE:
          return 'users'
        default:
          return undefined;
      }
    },
  }

}
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.metadata-row {
  /*noinspection CssUnresolvedCustomProperty*/
  background: var(--v-defaultBackground-base);
  margin: 10px 20px;
  border-radius: 100px;
  flex-wrap: nowrap !important;
}

.metadata-row.mobile {
  border-radius: 30px;
}

.metadata-row.active {
  /*noinspection CssUnresolvedCustomProperty*/
  border: 3px solid var(--v-secondaryContrast-base);
}

.text-row {
  min-height: 1.65em; /*27px;*/
  margin: 0;
}

.indented-text {
  margin-left: 7px;
}

.lower-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.middle-container {
  margin-right: auto;
  margin-left: 0%;
}

@media only screen and (max-width: 1500px){
  .middle-container {
    flex-basis: 0;
    flex-grow: 1;
  }
}
</style>
