















































































import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import signatureOnlineMixin from "@/mixins/signatureOnlineMixin";
import {apiFlowStepsReject} from "@/api/flowStepsApi";
import {apiWorkflowGet} from "@/api/workflowsApi";
import AccountInfo from "@/component/AccountInfo.vue";
import CenteredPanelLayout from "@/component/layouts/CenteredPanelLayout.vue";
import SignatureContentAlert from "@/component/signature/SignatureContentAlert.vue";
import SignatureContentLogo from "@/component/signature/SignatureContentLogo.vue";
import SignatureExternalContent from "@/component/signature/external/SignatureExternalContent.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import {
  SignatureExternalPhase,
  SignatureExternalPhaseExt,
  SignatureOnlinePhase,
  SignatureOnlineRejectionPhase,
  UserAccount
} from "@/types";
import {parseJwtPayload, parseJwtTokenData} from "@/utils/utils";

/*
 * Stránka podpisu externím uživatelem je téměř shodná se stránkou online podpisu (SignatureOnlineView.vue). Avšak z
 * důvodu přehlednosti a budoucího rozvoje jsou stránky oddělené.
 */
export default mixins(displayMixin, signatureOnlineMixin).extend({
  name: 'SignatureExternalView',

  components: {
    AccountInfo,
    CenteredPanelLayout,
    SIconButton,
    SignatureContentAlert,
    SignatureContentLogo,
    SignatureExternalContent
  },

  props: {
    personalInfo: {
      type: Object as PropType<UserAccount>,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      phase: undefined as SignatureExternalPhaseExt | undefined,
      phaseOrig: undefined as SignatureExternalPhaseExt | undefined
    }
  },

  computed: {
    phaseDocumentDetail(): boolean {
      return this.phase === SignatureOnlinePhase.DOCUMENT_DETAIL;
    }
  },

  async created () {
    const jwt = parseJwtPayload(this.token);
    const jwtTokenData = parseJwtTokenData(jwt);

    if (jwtTokenData.get('external') === 'true') {
      if (!this.flowId || this.flowId.toString() !== jwtTokenData.get('flowId') || !this.flowStepId ||
          this.flowStepId.toString() !== jwtTokenData.get('stepId')) {
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;
      }
      await this.$store.dispatch('profile/loadVisualisations');
      const visualisations = this.$store.getters['profile/visualisations'];
      //if (this.personalInfo.userFirstName && this.personalInfo.userLastName) {
      if (visualisations && visualisations.length > 0) {
        this.phase = this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO;
        await this.loadFlow();
      }
      else {
        this.phase = SignatureExternalPhase.USER_NAME;
      }
    }
    else if (jwtTokenData.get('temporary') === 'true') {
      if (!this.flowId || this.flowId.toString() !== jwtTokenData.get('flowId') || !this.flowStepId ||
          this.flowStepId.toString() !== jwtTokenData.get('stepId')) {
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;
      }
      else {
        this.phase = this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO;
        await this.loadFlow();
      }
    }
    else
      console.error('JWT token data does not contain the external or temporary flag set to true');
  },

  methods: {
    changePhase(phase: SignatureExternalPhaseExt): void {
      this.phase = phase;
    },
    async loadFlow(setPhase = true): Promise<void> {
      if (!this.flowId || !this.flowStepId)
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;

      try {
        this.flow = await apiWorkflowGet(this.flowId);
        if (setPhase)
          this.onFlowLoaded();
      }
      catch (e) {
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;
      }
    },
    onFlowLoaded(): void {
      if (!this.step || !this.flow)
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;
      else
        this.phase = this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO;
    },
    async onFlowRejected(): Promise<void> {
      if (this.redirectUri)
        this.redirectToSystem(true);
      else {
        this.phase = SignatureOnlinePhase.REJECTION_CONFIRMATION;
        await this.loadFlow(false);
      }
    },
    async rejection(rejectionPhase: SignatureOnlineRejectionPhase, rejectionReason?: string): Promise<void> {
      switch (rejectionPhase) {
        case SignatureOnlineRejectionPhase.CANCEL:
          this.phase = this.phaseOrig;
          break;
        case SignatureOnlineRejectionPhase.CONFIRMATION:
          if (this.phase !== SignatureOnlinePhase.REJECTION)
            this.phaseOrig = this.phase;
          this.phase = SignatureOnlinePhase.REJECTION;
          break;
        case SignatureOnlineRejectionPhase.CONFIRMED:
          /*
           * V případě podpisu externím uživatelem je umožněno pouze odmítnutí podpisu, protože odvolání podpisu z
           * pohledu business procesu nedává smysl.
           */
          if (this.flowStepId) {
            try {
              const data = await apiFlowStepsReject([this.flowStepId], rejectionReason);
              if (data.succeeded[0] === this.flowStepId) {
                this.phase = SignatureOnlinePhase.REJECTION_CONFIRMATION;
                await this.onFlowRejected();
              }
              else
                console.error(`An incorrect step ${data.succeeded[0]} has been rejected`);
            }
            catch (e) {
              console.error(`An error occurred while rejecting the step ${this.flowStepId}`, e);
            }
          }
          else
            console.warn('The step cannot be rejected due to a missing identifier');
          break;
      }
    },
    async success(): Promise<void> {
      this.changePhase(SignatureOnlinePhase.SUCCESS);
      await this.loadFlow(false);
    },
    async userConfigured(): Promise<void> {
      await this.loadFlow(false);
      this.phase = this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO;
    }
  }
})
