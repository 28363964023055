<template>
  <div class="mb-3 mt-1">
    <h3>{{ section.title }}</h3>
    <FlowDetailAuditSubsection v-for="subsection in section.subsections" :subsection="subsection"
                               :key="subsection"></FlowDetailAuditSubsection>
  </div>
</template>

<script>

import FlowDetailAuditSubsection from "@/component/workflow/detail/audit/FlowDetailAuditSubsection";

export default {
  name: "FlowDetailAuditSection",
  components: {FlowDetailAuditSubsection},
  props: {
    section: {
      title: String,
      subsections: Array
    }
  }
}
</script>

<style scoped>

</style>