






















import Vue from "vue";

export default Vue.extend({
  name: 'PDFToolbarPagination',

  props: {
    total: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      localValue: 0 as number
    }
  },

  watch: {
    value(newValue: number): void {
      this.localValue = newValue;
    }
  },

  created() {
    this.localValue = this.value;
  },

  methods: {
    change(changedValue: string): void {
      const newValue = parseInt(changedValue, 10);
      if (isNaN(newValue) || newValue < 1 || newValue > this.total)
        this.localValue = this.value;
      else
        this.$emit('input', newValue);
    }
  }
})
