







































































































import Vue, {PropType} from "vue";
import {Flow, Tag} from "@/types";
import {formatDateShort} from "@/utils/dateUtils";
import {nameToDisplay} from "@/utils/accountUtils";
import {isCurrentUserActiveSigner} from "@/utils/flowUtils";
import STooltip from "@/component/ui/STooltip.vue";

export default Vue.extend({
  name: 'FlowListItem',
  components: {STooltip},
  props: {
    flow: {
      type: Object as PropType<Flow>,
      required: true
    },
    color: {
      type: String
    },
    textColor: {
      type: String
    },
    batchMode: {
      type: Boolean,
      default: false
    },
    navCategory: {
      type: String
    },
    disabled: {
      type: Boolean,
      required: true,
    }
  },

  computed: {
    documentTags(): Array<Tag> | undefined {
      return this.flow?._embedded?.document?._embedded?.tags;
    },

    selectable(): boolean{
      return this.batchMode && this.currentUserActive;
    },

    currentUserActive(){
      return isCurrentUserActiveSigner(this.flow);
    },

    inError(): boolean {
      if (this.flow.stepState === 'error') return true;
      let errored = this.$store.getters["workflow/flowInError"] as Array<Flow>;
      let index = errored.findIndex(e => e.flowId === this.flow.flowId);
      return index !== -1;
    },

    highlighted(): boolean {
      return this.flow.highlighted;
    },

    selected(): boolean {
      let selected = this.$store.getters["workflow/flowSelected"] as Array<Flow>;
      let index = selected.findIndex(s => s.flowId === this.flow.flowId);
      return index !== -1;
    },

    active(): boolean {
      let active = this.$store.getters["workflow/flowActive"];
      if (active) {
        return active.flowId === this.flow.flowId;
      }
      else {
        return false;
      }
    },

    name(): string {
      return nameToDisplay(this.flow._embedded.owner);
    },

    displayBadge(): boolean {
      return this.inError || this.highlighted;
    },

    badgeColor(): string {
      return this.inError ? 'mainContrast' : 'secondaryContrast';
    },

  },
  methods: {
    onKeyUp(event: KeyboardEvent): void {
      let handled = true;
      switch (event.key) {
        case 'ArrowUp' :
          this.$store.commit('workflow/flowPrev');
          break;
        case 'ArrowDown' :
          this.$store.commit('workflow/flowNext');
          break;
        default:
          handled = false;
      }
      if (handled) {
        event.preventDefault();
      }
    },
    onKeyDown(event: KeyboardEvent): void {
      let handled = true;
      switch (event.key) {
        case 'ArrowUp' :
        case 'ArrowDown' :
          break;
        default:
          handled = false;
      }
      if (handled) {
        event.preventDefault();
      }
    },
    selectItem(): void {
      let selected = this.$store.getters["workflow/flowSelected"];
      let remove = false;
      if (!Array.isArray(selected)) {
        selected = [];
      }
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].flowId === this.flow.flowId) {
          remove = true;
          selected.splice(i, 1);
        }
      }
      if (!remove) {
        selected.push(this.flow);
      }
      this.$store.commit('workflow/setFlowSelected', {flowSelected: selected});
    },
    formatDate(value: string){
      return formatDateShort(value);
    },
    formatSubjectName(text: string): string {
      let formatText = text;
      if (formatText.endsWith(".pdf")) {
        formatText = formatText.substring(0, formatText.length - 4);
      }
      return formatText;
    }
  },
  watch: {
    active(value) {
      if (value) {
        ((this.$refs.item as Vue).$el as HTMLElement).focus();
      }
      else {
        ((this.$refs.item as Vue).$el as HTMLElement).blur();
      }
    }
  }
})
