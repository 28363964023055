





















import Vue from 'vue'
import clientDownloadMixin from "@/client/clientDownloadMixin";

export default Vue.extend({
  name: 'ClientSignatureAppWarning',
  mixins: [clientDownloadMixin],
  props: {}
})
