<template>
  <div
      v-scroll.self="onScroll"
      :id="containerId"
      class="pdf-viewer-core"
      ref="container"
      :style="containerStyle"
  >
    <!-- Název CSS třídy "pdfViewer" neměnit, protože se na ní vážou styly z knihovny PDF.js -->
    <div class="pdfViewer" :id="id"></div>
  </div>
</template>

<script>
import {EventBus, PDFViewer} from "pdfjs-dist/web/pdf_viewer";
import {MissingPDFException} from "pdfjs-dist";
import * as pdfjsLib from "pdfjs-dist/webpack";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";

const getPageSource = (pdfjsPageRendered) => {
  const { pageNumber, source } = pdfjsPageRendered;
  const { div, rotation, scale, viewport } = source // PDF.js Viewer - class PDFPageView
  const { height, rawDims, rotation: viewportRotation, scale: viewportScale, width } = viewport;

  return {
    div,
    info: {
      rotation,
      scale,
      viewport: {
        height,
        rawDims,
        rotation: viewportRotation,
        scale: viewportScale,
        width
      }
    },
    pageNumber
  };
}

const getScrollValue = (event) => {
  const left = event.target.scrollLeft;
  const top = event.target.scrollTop;

  return {
    left,
    top
  };
}

/**
 * Wrapper prohlížeče PDF dokumentů (PDFViewer) implementovaného v rámci knihovny PDF.js.
 *
 * Starší verze PDF.js Viewer example lze prohlížet např. ve snapshots Wayback Machine
 * @see {@link http://mozilla.github.io/pdf.js/web/viewer.html | PDF.js Viewer example}
 * @see {@link https://github.com/mozilla/pdf.js/blob/master/examples/components/simpleviewer.html | PDF.js Viewer code example - HTML}
 * @see {@link https://github.com/mozilla/pdf.js/blob/master/examples/components/simpleviewer.js | PDF.js Viewer code example - JS}
 */
export default mixins(displayMixin).extend({
  name: 'PDFViewerCore',

  props: {
    authToken: String,
    dataUrl: String,
    id: {
      type: String,
      default: 'pdfViewer'
    },
    pageNumber: Number
  },

  data() {
    return {
      pdfDocumentAttachments: undefined,
      pdfDocumentData: undefined,
      pdfViewer: undefined
    }
  },

  computed: {
    authHeader() {
      return {
        Authorization: this.authToken && `Bearer ${this.authToken}`
      };
    },
    containerId() {
      return `${this.id}Container`;
    },
    containerStyle() {
      /* PDF.js Viewer vyžaduje nastavení "position: absolute" a "overflow: auto" - viz https://github.com/mozilla/pdf.js/issues/11626) */
      return {
        outline: 'none',
        overflow: 'auto',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      };
    },
    currentPageNumber() {
      return this.pdfViewer?.currentPageNumber;
    },
    currentScaleValue() {
      return this.pdfViewer?.currentScaleValue;
    },
    pagesCount() {
      return this.pdfViewer?.pagesCount;
    },
    pagesRotation() {
      return this.pdfViewer?.pagesRotation;
    }
  },

  watch: {
    currentPageNumber(value) {
      this.$emit('current-page', value);
    },
    currentScaleValue(value) {
      this.$emit('current-scale', value);
    },
    async dataUrl(value) {
      await this.loadPdfDocument(value);
    },
    pagesCount(value) {
      this.$emit('pages-count', value);
    },
    pagesRotation(value) {
      this.$emit('pages-rotation', value);
    },
    pdfDocumentAttachments(value) {
      this.$emit('document-attachments', value);
    }
  },

  async mounted() {
    await this.initPdfViewer();
    await this.loadPdfDocument();
  },

  methods: {
    exposeInterface() {
      this.$emit('interface', {
        documentDataReload: async () => await this.loadPdfDocument(),
        pageNext: () => this.pageNext(),
        pagePrev: () => this.pagePrev(),
        pagesRefresh: () => this.pagesRefresh(),
        pagesRotate: (delta) => this.pagesRotate(delta),
        setCurrentPageNumber: (value) => this.setCurrentPageNumber(value),
        setCurrentScaleValue: (value) => this.setCurrentScaleValue(value),
        zoomIn: () => this.zoomIn(),
        zoomOut: () => this.zoomOut()
      });
    },
    initPdfViewer() {
      const container = this.$refs.container;
      const eventBus = new EventBus();
      this.pdfViewer = new PDFViewer({
        container,
        eventBus,
        annotationMode: pdfjsLib.AnnotationMode.ENABLE,
        annotationEditorMode: pdfjsLib.AnnotationEditorType.DISABLE
      });

      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      eventBus.on('pagesinit', (event) => {
        /* Výchozí měřítko */
        this.setCurrentScaleValue(this.displayDesktop ? 'auto' : 'page-width');

        this.$emit('pages-init');
      });

      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      eventBus.on('pagesloaded', (event) => {
        this.$emit('pages-loaded');
      });

      eventBus.on('pagerendered', (event) => {
        const source = getPageSource(event);
        this.$emit('page-rendered', source);
      });

      this.exposeInterface();
    },
    async loadPdfDocument() {
      try {
        this.$emit('document-loading', 0);

        const loadingTask = pdfjsLib.getDocument({
          url: this.dataUrl,
          httpHeaders: {
            ...this.authHeader
          }
        });
        /* Preparation for showing actual progress in indicator, not possible because of pdfjsLib issue getting NaN from progress.total. */
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        loadingTask.onProgress = (progress) => {
          // const loadingProgress = (progress.loaded / progress.total) * 100;
          // this.$emit('document-loading', loadingProgress);
        }

        const pdfDocument = await loadingTask.promise;
        this.pdfViewer.setDocument(pdfDocument);
        this.pdfDocumentAttachments = await pdfDocument.getAttachments();
        this.pdfDocumentData = await pdfDocument.getData();
        this.$emit('document-attachments', this.pdfDocumentAttachments);
        this.$emit('document-loaded');
      }
      catch (e) {
        console.error('An error occurred while loading PDF document data');

        const { name, status } = e;
        let errorEvent = {
          name,
          status
        };

        if (e instanceof MissingPDFException)
          errorEvent.status = 404;

        this.$emit('document-error', errorEvent);
      }
    },
    onScroll(event) {
      const scrollValue = getScrollValue(event);
      this.$emit('scroll', scrollValue);
    },
    pageNext() {
      this.pdfViewer.nextPage();
    },
    pagePrev() {
      this.pdfViewer.previousPage();
    },
    pagesRefresh() {
      this.pdfViewer.refresh();
    },
    pagesRotate(delta) {
      this.pdfViewer.pagesRotation += delta;
    },
    reset() {
      this.pdfDocumentAttachments = undefined;
      this.pdfDocumentData = undefined;
      this.pdfViewer = undefined;
    },
    setCurrentPageNumber(value) {
      this.pdfViewer.currentPageNumber = value;
    },
    setCurrentScaleValue(value) {
      this.pdfViewer.currentScaleValue = value;
    },
    zoomIn() {
      this.pdfViewer.increaseScale();
    },
    zoomOut() {
      this.pdfViewer.decreaseScale();
    }
  }
})
</script>

<style scoped>
.pdf-viewer-core {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
</style>

<style>
/*
 * Výchozí CSS styly prohlížeče PDF implementovaného v rámci knihovny PDF.js.
 */
@import "pdfjs-dist/web/pdf_viewer.css";
</style>
