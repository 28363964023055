



























import Vue, {PropType} from "vue";
import {UserAccount} from "@/types";
import {ACCOUNT_TYPE} from "@/utils/constants";

export default Vue.extend({
  name: 'SignerEmail',
  props: {
    account: {
      type: Object as PropType<UserAccount>,
      required: true
    },
    emailIcon: Boolean,
    removeAction: Boolean,
    myself: Boolean
  },
  computed: {
    external(): boolean {
      return this.account.accountType === ACCOUNT_TYPE.EXTERNAL;
    },
    icon(): string {
      return this.external ? 'external' : 'envelope';
    }
  },
  methods: {
    remove(): void {
      this.$emit('remove');
    }
  }
})
