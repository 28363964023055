import { render, staticRenderFns } from "./InteractionCenterBatchAuthorization.vue?vue&type=template&id=54829e9f&scoped=true&"
import script from "./InteractionCenterBatchAuthorization.vue?vue&type=script&lang=ts&"
export * from "./InteractionCenterBatchAuthorization.vue?vue&type=script&lang=ts&"
import style0 from "./InteractionCenterBatchAuthorization.vue?vue&type=style&index=0&id=54829e9f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54829e9f",
  null
  
)

export default component.exports