




























import SignatureMethodListItem from "@/component/profile/signatures/SignatureMethodListItem.vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import {SignatureMethod} from "@/types";

export default mixins(displayMixin).extend({
  name: 'SignatureMethodList',
  components: {SignatureMethodListItem},
  data() {
    return {
      selected: undefined as SignatureMethod | undefined
    }
  },
  computed: {
    methods(): Array<SignatureMethod>{
      let methods =  this.$store.getters["profile/signatureMethods"];
      return methods ? methods : [];
    }
  },
  created() {
    if (!this.$store.getters["profile/signatureMethods"]) {
      this.$store.dispatch('profile/loadSignatureMethods');
    }
  },
  methods: {
    onMethodSelect() {
      this.$store.commit("profile/selectSignatureMethod", {method: this.selected});
      if (this.selected !== undefined) {
        this.$emit('show-detail');
      }
    },
    unselect() {
      this.selected = undefined;
    }
  }
})
