import Vue, {PropType} from "vue";
import {ButtonVariant} from "@/types";

interface ButtonConfig {
  backgroundColor: string;
  textColor: string;
  outlined: boolean;
}

export default Vue.extend({
  props: {
    dataTestid: String,
    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'primary-success'
    }
  },
  data() {
    return {
      variants: {
        'primary-danger': {
          backgroundColor: 'mainContrast',
          textColor: 'mainContrastText--text',
          outlined: false
        },
        /* Vyhrazená pro použití u SButton obsahující text-only prop nebo SIconButton bez barvy pozadí */
        'primary-danger-accent': {
          backgroundColor: 'mainContrast',
          textColor: 'mainContrast--text',
          outlined: false
        },
        'primary-muted': {
          backgroundColor: 'mutedContrast',
          textColor: 'white--text',
          outlined: false
        },
        /* Vyhrazená pro použití u SButton obsahující text-only prop nebo SIconButton bez barvy pozadí */
        'primary-muted-accent': {
          backgroundColor: 'mutedContrast',
          textColor: 'mutedContrast--text',
          outlined: false
        },
        'primary-success': {
          backgroundColor: 'secondaryContrast',
          textColor: 'secondaryContrastText--text',
          outlined: false
        },
        'primary-success-green': {
          backgroundColor: 'success',
          textColor: 'successText--text',
          outlined: false
        },
        'primary-background': {
          backgroundColor: 'mainBackground',
          textColor: 'white--text',
          outlined: false
        },
        'secondary-danger': {
          backgroundColor: 'mainContrast',
          textColor: 'mainContrast--text',
          outlined: true
        },
        'secondary-muted': {
          backgroundColor: 'mutedContrast',
          textColor: 'white--text',
          outlined: true
        },
        /* Vyhrazená pro použití u SButton obsahující text-only prop nebo SIconButton bez barvy pozadí */
        'secondary-success-accent': {
          backgroundColor: 'secondaryContrast',
          textColor: 'secondaryContrast--text',
          outlined: false
        },
        'secondary-background': {
          backgroundColor: 'secondaryBackground',
          textColor: 'white--text',
          outlined: false
        },
        'secondary-background-tinted': {
          backgroundColor: 'secondaryBackground',
          textColor: 'mainContrast--text',
          outlined: false
        },
      } as Record<ButtonVariant, ButtonConfig>
    }
  },
  computed: {
    backgroundColor(): string {
      return this.variants[this.variant as ButtonVariant].backgroundColor;
    },
    textColor(): string {
      return this.variants[this.variant as ButtonVariant].textColor;
    },
    outlined(): boolean {
      return this.variants[this.variant as ButtonVariant].outlined;
    },
  }
})
