import {OS} from "@/utils/constants";
import {AccessTokenPayload} from "@/types";

/**
 * Converts image to base64. File must be instance of File or Blob, otherwise this method returns undefined.
 * @param file File or Blob
 * @returns Promise
 */
export const imageToBase64 = (file: File | Blob | string) => new Promise<string>((resolve, reject) => {
    if (file instanceof File || file instanceof Blob) {
        return fileToBase64(file).then(base64 => {
            resolve(base64);
        });
    } else {
        if (file.startsWith('data')) {
            resolve(file);
        } else {
            reject("Invalid input");
        }
    }
});

/**
 *  Converts any object with type File to base64.
 *  @param file File
 *  @returns Promise
 */
export const fileToBase64 = (file: Blob) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
});

/**
 * If dimensions of image are greater than specified params, then image is resized to fit the parameters and then converted to base64
 * @param file File
 * @param maxWidth maximal width of an image
 * @param maxHeight maximal height of an image
 * @returns Promise
 */
export const resizeImageAndConvertToBase64 = (file: Blob, maxWidth: number, maxHeight: number) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (fileEvent) => {
        const image = new Image();
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context === null) {
            reject("Failed to create canvas context");
            return;
        }
        image.onload = function (imgEvent) {
            let destWidth = maxWidth, destHeight = maxHeight;
            if (image.width > maxWidth || image.height > maxHeight) {
                if (image.width > image.height)
                    destHeight *= image.height / image.width;
                else if (image.width < image.height)
                    destWidth *= image.width / image.height;

                canvas.setAttribute('width', destWidth.toString());
                canvas.setAttribute('height', destHeight.toString());
                context.drawImage(imgEvent.target as CanvasImageSource, 0, 0, image.width, image.height, 0, 0, destWidth, destHeight);
                const base64 = canvas.toDataURL('image/png');
                resolve(base64);
            } else {
                imageToBase64(file).then(base64 => resolve(base64));
            }
        };
        image.src = fileEvent.target?.result as string;
    };
    reader.onerror = error => reject(error);
});

/**
 * Returns image with written text in it converted to base64
 * @param text content of the image
 * @param width final width of the image
 * @param height final height of the image
 * @param fontSize default font size. Will be decreased if text can´t fit into the image
 * @param fontFamily font family to be used
 */
export const convertTextToImage = (text: string, width: number, height: number, fontSize = 60, fontFamily = 'Dancing Script'): string => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    if (ctx === null) {
        throw new Error("Failed to create canvas context");
    }
    ctx.font = fontSize + 'px ' + fontFamily;

    const textWidth = ctx.measureText(text).width;
    if (textWidth > canvas.width) {
        if (fontSize - 3 > 0) {
            return convertTextToImage(text, width, height, fontSize - 3, fontFamily);
        }
    }
    ctx.fillText(text, (canvas.width / 2) - (textWidth / 2), canvas.height / 2);
    return canvas.toDataURL();
}

// This function's accuracy should be around 90% as it is almost impossible detect user's OS perfectly from Javascript
export const getOS = (): OS | null => {
    const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os: OS | null = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = OS.MACOS;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = OS.IOS;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = OS.WINDOWS;
    } else if (/Android/.test(userAgent)) {
        os = OS.ANDROID;
    } else if (/Linux/.test(platform)) {
        os = OS.LINUX;
    }

    return os;
}

export const parseJwtPayload = (token: string): AccessTokenPayload => {
    const base64Payload = token.split('.')[1];
    const payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
}

export const parseJwtTokenData = (jwt: AccessTokenPayload): Map<string, string> => {
    const map = new Map<string, string>();
    if (!jwt || !jwt['magiclink-data']) return map;
    const tokenData = jwt['magiclink-data'].split(';');
    for (const data of tokenData) {
        const pair = data.split('=');
        if (!pair || pair.length !== 2) continue;
        map.set(pair[0], pair[1]);
    }
    return map;
}

export function toArray<T>(value: T | Array<T> | undefined): Array<T> {
    if (!value) {
        return [];
    } else if (Array.isArray(value)) {
        return value;
    } else {
        return [value];
    }
}

export function tag(value: string) {
    if(!value || value.length < 1) return "";
    return  value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();
}

export function truncateNumber(value: number|string): string {
    if(!value) return "";
    const tmp: number = typeof value === "number" ? value : Number(value);
    return tmp >= 1000 ? '999+' : tmp.toString();
}

export function fileSize(value: number): string {
    if (value < 10_000) {
        return `${(value / 1000).toFixed(2)}KB`
    }
    return `${(value / 1_000_000).toFixed(2)}MB`
}

export function redirectTo(url: string, newTab = false){
    if(newTab){
        const win = window.open(url, '_blank');
        if(win) win.focus();
    } else {
        window.location.replace(url);
    }
}

export function downloadBlob(blob: Blob, filename: string){
    const fileURL = window.URL.createObjectURL(blob);
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
}

/**
 * Serializes object to JSON a returns a Blob with correct type containing the serialized object.
 *
 * Axios doesn't allow setting a content-type for parts of a multipart/form-data. This allows sending a JSON value with
 * correct content-type.
 * @param object object to serialize
 */
export function objectToFormJsonBlob(object: unknown): Blob {
    return new Blob([JSON.stringify(object)], {
        type: 'application/json'
    });
}

export function flattenHierarchy<T extends { [key in K]: T[] }, K extends keyof T>(rootObject: T,
                                                                                   childrenKey: K): Array<T> {
    const flatten = (item: T, collector: Array<T>) => {
        collector.push(item)
        item[childrenKey]?.forEach(item => flatten(item, collector))
    }
    const result = [] as Array<T>
    flatten(rootObject, result)
    return result
}

// just for debugging purposes
// usage json`test ${{a:5, b: 'c'}}`
export const json = (strings: TemplateStringsArray, ...objects: unknown[]): string => {
    let str = '';
    strings.forEach((string, i) => {
        str += string + (objects[i] ? JSON.stringify(objects[i]) : '');
    });
    return str;
}
