













































import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import SLoader from "@/component/ui/SLoader.vue";
import FlowListGroup from "@/component/workflow/list/FlowListGroup.vue";
import {PartialRecord} from "@/types";
import {Flow, FlowListCategory, FlowListCategoryConfig, FlowListCategoryName} from "@/types/workflow";
import {WorkflowResponse} from "@/types/dto";
import {
  isBeforeNotSameNotYesterday,
  isDayBefore,
  isLaterNotSame,
  isLaterNotSameNotNextDay,
  isNextDay,
  isSameDay
} from "@/utils/dateUtils";
import {isCurrentUserActiveSigner} from "@/utils/flowUtils";

//Maximální počet dokumentů co se může načíst do prostředního panelu
const MAX_LIMIT_FLOWS = 1000

export default mixins(mutationMixin).extend({
  name: 'FlowList',

  components: {FlowListGroup, SLoader},

  props: {
    flowsUrl: {
      type: String,
      required: true
    },
    state: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      flows: [] as Array<Flow>,
      maxLimitMsg: false,
      listCategories: {
        missed: {
          title: 'workflow.list.navCategories.missed',
          color: 'mainContrast',
          textColor: 'mainContrastText',
          valid: isLaterNotSame
        },
        earlier: {
          title: 'workflow.list.navCategories.earlier',
          color: 'mainContrast',
          textColor: 'mainContrastText',
          valid: isLaterNotSameNotNextDay
        },
        yesterday: {
          title: 'workflow.list.navCategories.yesterday',
          color: 'mainContrast',
          textColor: 'mainContrastText',
          valid: isDayBefore
        },
        today: {
          title: 'workflow.list.navCategories.today',
          color: 'mainContrast',
          textColor: 'mainContrastText',
          valid: isSameDay
        },
        tomorrow: {
          title: 'workflow.list.navCategories.tomorrow',
          color: 'mainContrast',
          textColor: 'mainContrastText',
          valid: isNextDay
        },
        later: {
          title: 'workflow.list.navCategories.later',
          color: 'secondaryContrast',
          textColor: 'secondaryContrastText',
          valid: isBeforeNotSameNotYesterday
        }
      } as Record<FlowListCategoryName, FlowListCategoryConfig>,
      loading: false,
      loadedAll: false,
      getLoadAllFlowsLoading: false,
      initialLoadingUrl: undefined as string | undefined,
      nextUrl: undefined as string | undefined
    }
  },

  computed: {
    availableListCategories(): Array<FlowListCategoryName> {
      return this.$store.getters['navigation/flowListCategories'](this.state);
    },
    batchMode(): boolean {
      return this.$store.getters['workflow/batchMode'];
    },
    flowActive: {
      get(): Flow | undefined {
        return this.$store.getters['workflow/flowActive'];
      },
      set(value: Flow | undefined) {
        if (!value)
          this.$store.commit('workflow/clearDetail');
        else if (value.highlighted)
          this.$store.dispatch('workflow/unmarkFlow', { flow: value });

        this.$store.commit('workflow/setFlowActive', { flowActive: value });
      }
    },
    flowListItemActive: {
      get(): Flow | undefined {
        return this.flowActive;
      },
      set(value: Flow | undefined) {
        /*
         * Vuetify komponenta v-list-item-group se chová tak, že při opakovaném kliknutí na aktivní položku seznamu se
         * její aktivace zruší. V seznamu flow je toto chování nežádoucí, a proto v případě seznamu flow probíhá
         * nastavení aktivního flow odděleně.
         */
        if (value && this.flowActive !== value)
          this.flowActive = value;
      }
    },
    flowsCategorized(): Record<FlowListCategoryName, FlowListCategory> {
      const now = new Date();
      const flowsCategorized = {} as Record<FlowListCategoryName, FlowListCategory>;

      for (const flow of this.flows) {
        if (!flow.orderingDate)
          continue;

        const orderingDate = new Date(flow.orderingDate);
        for (const category of this.availableListCategories) {
          if (this.listCategories[category].valid(now, orderingDate)) {
            // Pořadí kategorií odpovídá pořadí příchozích flows
            if (!flowsCategorized[category])
              flowsCategorized[category] = { ...this.listCategories[category], flows: [] };

            flowsCategorized[category].flows.push(flow);
            break;
          }
        }
      }

      return flowsCategorized;
    },
    flowsDisabledMap(): PartialRecord<number, boolean> {
      const flowsDisabled: Array<Flow> = this.$store.getters['workflow/flowDisabled'];
      return flowsDisabled.reduce(
          (flowsDisabledMap, flowDisabled: Flow) => ({ ...flowsDisabledMap, [flowDisabled.flowId]: true }), {});
    },
    flowsSelected: {
      get(): Array<Flow> {
        return this.$store.getters['workflow/flowSelected'];
      },
      set(value: Array<Flow>) {
        this.$store.commit('workflow/setFlowSelected', { flowSelected: value });
      }
    }
  },

  watch: {
    flows(value: Array<Flow>): void {
      const selectableFlowCount = value.filter((flow: Flow) => isCurrentUserActiveSigner(flow)).length;
      this.$store.commit('workflow/setSelectableFlowCount', {count: selectableFlowCount});
      if (value.length === 0)
        this.flowActive = undefined;
    },
    flowsUrl: {
      immediate: true,
      async handler() {
        this.clear();
        await this.loadFlowsInitial();
      }
    }
  },

  created() {
    this.subscribe(async (mutation) => {
      if (mutation.type === 'navigation/foldersRefresh') {
        this.clear();
        await this.loadFlowsInitial();
      }
      if (mutation.type === 'workflow/flowNext') {
        this.activateFlowNext();
      }
      if (mutation.type === 'workflow/flowPrev') {
        this.activateFlowPrev();
      }
      if (mutation.type === 'workflow/flowCancelSelection') {
        this.clearFlowsSelected();
      }
      if (mutation.type === 'workflow/flowSelectAll') {
        this.setAllFlowsSelected();
      }
      if (mutation.type === 'workflow/flowsUpdate') {
        const updateFlowIds = mutation.payload.flowIds as Array<number>;
        const flowsPath = mutation.payload.flowsPath as string | undefined;
        if (this.batchMode && flowsPath && flowsPath === this.$route.path)
          await this.updateFlows(updateFlowIds);
      }
      if (mutation.type === 'workflow/updateFlow') {
        this.updateFlow(mutation.payload.flow);
      }
      if (mutation.type === 'workflow/draftDeleted') {
        this.deleteFlows([mutation.payload.flow.flowId]);
      }
      if (mutation.type === 'workflow/setFlowActive') {
        this.deleteFlowsDisabled();
      }
      if (mutation.type === 'workflow/flowLoadAll') {
        await this.loadEveryFlows();
      }
    });
  },

  methods: {
    async loadFlowsInitial(): Promise<void> {
      if (!this.flowsUrl || this.flowsUrl === this.initialLoadingUrl)
        return;

      this.initialLoadingUrl = this.flowsUrl;
      this.loading = true;
      try {
        let tmpFlowUrl = this.flowsUrl;
        let response = await this.axios.get(this.flowsUrl);
        if (this.flowsUrl !== tmpFlowUrl) {
          this.loading = false;
          return;
        }
        this.setNextUrl(response.data);
        this.flows = response.data._embedded.workflows;
        this.activateFlowFirst();
      }
      catch (e) {
        console.error('Error occurred while loading initial flows', e);
      }
      finally {
        this.loading = false;
        this.initialLoadingUrl = undefined;
      }
    },
    async loadEveryFlows(): Promise<void> {
      if (!this.flowsUrl)
        return;
      this.loading = true;
      try {
        this.clear();
        let response = await this.axios.get(this.flowsUrl, {params: {limit: MAX_LIMIT_FLOWS}})
        this.flows = response.data._embedded.workflows;
        this.setNextUrl(response.data);
        this.activateFlowFirst();
        this.loading = false;
      }
      catch (e) {
        console.error('Error occurred while loading initial flows', e);
      }
      finally {
        this.loading = false;
      }
    },
    async loadFlowsNext(entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean): Promise<void> {
      if (!isIntersecting)
        return;
      if (this.loading || this.initialLoadingUrl)
        return;
      if (!this.nextUrl)
        return;

      this.loading = true;
      try {
        if (this.flows.length < MAX_LIMIT_FLOWS) {
          let tmpFlowUrl = this.flowsUrl;
          let response = await this.axios.get(this.nextUrl);
          if (this.flowsUrl !== tmpFlowUrl) {
            this.loading = false;
            return;
          }
          let flows = response.data._embedded.workflows;
          if (flows.length > 0) {
            this.setNextUrl(response.data);
          }
          else {
            this.nextUrl = undefined;
            this.loadedAll = true;
          }
          this.flows = this.flows.concat(flows);
        }
        else {
          this.maxLimitMsg = true;
        }
      }
      catch (e) {
        console.error(`Error occurred while loading next flows`, e);
      }
      finally {
        this.loading = false;
      }
    },
    activateFlowFirst(): void {
      if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm')
        return;

      if (this.flows.length > 0)
        this.flowActive = this.flows[0];
    },
    activateFlowNext(): void {
      if (this.flows.length === 0)
        return;

      if (!this.flowActive) {
        this.flowActive = this.flows[0];
      }
      else {
        const activeFlowId = this.flowActive.flowId;
        const flowActiveIndex = this.flows.findIndex((flow: Flow) => flow.flowId === activeFlowId);
        if (flowActiveIndex > -1) {
          const flowNext = this.findFlowNext(flowActiveIndex + 1);
          if (flowNext)
            this.flowActive = flowNext;
        }
        else {
          // Aktivní flow nenalezeno
          this.flowActive = this.flows[0];
        }
      }
    },
    activateFlowPrev(): void {
      if (this.flows.length === 0)
        return;

      if (!this.flowActive) {
        this.flowActive = this.flows[0];
      }
      else {
        const activeFlowId = this.flowActive.flowId;
        const flowActiveIndex = this.flows.findIndex((flow: Flow) => flow.flowId === activeFlowId);
        if (flowActiveIndex > -1) {
          const flowPrev = this.findFlowPrev(flowActiveIndex - 1);
          if (flowPrev)
            this.flowActive = flowPrev;
        }
        else {
          // Aktivní flow nenalezeno
          this.flowActive = this.flows[0];
        }
      }
    },
    clear() {
      this.clearFlowsDisabled();
      this.clearFlowsError();
      this.clearFlowsSelected();

      this.flows = [];
      this.loadedAll = false;
    },
    clearFlowsDisabled(): void {
      this.$store.commit('workflow/setFlowDisabled', { flowDisabled: [] });
    },
    clearFlowsSelected(): void {
      this.$store.commit('workflow/setFlowSelected', { flowSelected: [] });
    },
    clearFlowsError(): void {
      this.$store.commit('workflow/setErroredFlow', { erroredFlow: [] });
    },
    deleteFlows(deleteFlowIds: Array<number>) {
      const activeFlowId = this.flowActive?.flowId;
      if (activeFlowId && deleteFlowIds.includes(activeFlowId)) {
        // Aktuálně zobrazené flow je mezi flows, které budou odstraněny
        const flowActiveIndex = this.flows.findIndex((flow: Flow) => flow.flowId === activeFlowId);
        const flowNext = this.findFlowNext(flowActiveIndex + 1, deleteFlowIds);
        this.flowActive = flowNext || this.findFlowPrev(flowActiveIndex - 1, deleteFlowIds);
      }

      this.flowsSelected = this.flowsSelected.filter((flow: Flow): boolean => !deleteFlowIds.includes(flow.flowId));
      this.flows = this.flows.filter((flow: Flow): boolean => !deleteFlowIds.includes(flow.flowId));
    },
    deleteFlowsDisabled(): void {
      const flowsDisabled: Array<Flow> = this.$store.getters['workflow/flowDisabled'];
      if (flowsDisabled.length === 0)
        return;

      const disabledFlowIds = flowsDisabled.map((flow: Flow): number => flow.flowId);
      this.deleteFlows(disabledFlowIds);
      this.clearFlowsDisabled();
    },
    findFlowNext(indexFrom: number, excludeFlowIds: Array<number> | undefined = undefined): Flow | undefined {
      if (indexFrom < 0 || indexFrom >= this.flows.length)
        return undefined;

      const flowsDisabled = this.$store.getters['workflow/flowDisabled'];
      const disabledFlowIds = flowsDisabled.map((flow: Flow): number => flow.flowId);
      const excludedFlowIds = [...disabledFlowIds, ...(excludeFlowIds ?? [])];

      for (let i = indexFrom; i < this.flows.length; i++) {
        const flowNext = this.flows[i];
        if (!excludedFlowIds.includes(flowNext.flowId))
          return flowNext;
      }

      return undefined;
    },
    findFlowPrev(indexFrom: number, excludeFlowIds: Array<number> | undefined = undefined): Flow | undefined {
      if (indexFrom < 0 || indexFrom >= this.flows.length)
        return undefined;

      const flowsDisabled = this.$store.getters['workflow/flowDisabled'];
      const disabledFlowIds = flowsDisabled.map((flow: Flow): number => flow.flowId);
      const excludedFlowIds = [...disabledFlowIds, ...(excludeFlowIds ?? [])];

      for (let i = indexFrom; i >= 0; i--) {
        const flowPrev = this.flows[i];
        if (!excludedFlowIds.includes(flowPrev.flowId))
          return flowPrev
      }

      return undefined;
    },
    setAllFlowsSelected(): void {
      const allFlowsSelected = this.flows.filter((flow: Flow) => isCurrentUserActiveSigner(flow));
      this.$store.commit('workflow/setFlowSelected', { flowSelected: allFlowsSelected });
    },
    setNextUrl(data: WorkflowResponse): void {
      if (data._links['sef:cursor-next']) {
        this.nextUrl = data._links['sef:cursor-next'].href;
      }
      else {
        this.nextUrl = undefined;
        this.loadedAll = true;
      }
    },
    updateFlow(flowUpdate: Flow): void {
      let index = this.flows.findIndex((flow: Flow) => flow.flowId === flowUpdate.flowId);
      if (index !== -1) {
        flowUpdate.orderingDate = flowUpdate.createdAt;
        this.$set(this.flows, index, flowUpdate);
      }
      else {
        this.clear();
        this.loadFlowsInitial();
      }
    },
    async updateFlows(updateFlowIds: Array<number>): Promise<void> {
      if (!this.flowsUrl || updateFlowIds.length === 0)
        return;

      const arraySize = 100;
      const chunks = this.splitArrayIntoSmallChunks(updateFlowIds, arraySize);
      let allRemainingFlows = [] as Array<Flow>;

      for (const chunk of chunks) {
        const response = await this.axios.get(this.flowsUrl, {
          params: {
            flowId: chunk
          }
        });
        const remainingFlows = response.data._embedded.workflows;
        allRemainingFlows = allRemainingFlows.concat(remainingFlows);
      }

      const activeFlowId = this.flowActive?.flowId;
      const remainingFlowIds = allRemainingFlows.map((flow: Flow): number => flow.flowId);
      let remainingActiveFlow = undefined;

      // Aktualizace flows, které zůstávají ve vybrané složce nebo stále vyhovují parametrům vyhledávání
      for (const remainingFlow of allRemainingFlows) {
        const updatedFlowIndex = this.flows.findIndex((flow: Flow): boolean => flow.flowId === remainingFlow.flowId);
        if (updatedFlowIndex > -1)
          this.flows.splice(updatedFlowIndex, 1, remainingFlow);
        else
          console.warn(`Flow ${remainingFlow.flowId} cannot be updated: this flow is not included in the list`);

        if (activeFlowId && activeFlowId === remainingFlow.flowId)
          remainingActiveFlow = remainingFlow;
      }
      const deleteFlowIds = updateFlowIds.filter((flowId: number): boolean => !remainingFlowIds.includes(flowId));

      // Odstranění flows, které už nepatří do vybrané složky nebo už nevyhovují parametrům vyhledávání
      this.deleteFlows(deleteFlowIds);

      // Aktualizace aktivního flow, pokud se během aktualizace flows nezměnilo
      if (activeFlowId === this.flowActive?.flowId && remainingActiveFlow) {
        this.flowActive = remainingActiveFlow;
        // TODO: Pouze dočasně, detail dokumentu by měl reagovat na změny flow provedené skrze store - asi až v nové verzi detailu
        this.$store.commit('workflow/updateDetail');
      }
    },
    splitArrayIntoSmallChunks(updateFlowsIds: Array<number>, arraySize: number): Array<Array<number>> {
      return Array.from(
          {length: Math.ceil(updateFlowsIds.length / arraySize)},
          (_, i) => updateFlowsIds.slice(i * arraySize, i * arraySize + arraySize)
      );
    }
  }
})
