import {HalResource, Link} from "@/types/hal";
import {FlowListCategoryName, FlowState} from "@/types/workflow";
import {BusinessLevel} from "@/types/other";

export enum NavigationMode {
    NAV = "nav",
    PROFILE = "profile",
    NOTIFICATIONS = "notifications",
    ONLINE_SIGNATURE = "online-signature"
}

export enum itemCountDisplayModes {
    TOTAL = "total_only",
    HIGHLIGHTED = "highlighted_only",
    NONE = "node"
}

export interface Category extends HalResource<CategoryEmbedded, CategoryLinks>{
    categoryCode: string;
    categoryName: string;
    dueDateModeDef: string;
}

interface CategoryEmbedded {
    "sef:business-levels-for-category": Array<BusinessLevel>
}

export interface CategoryLinks {
    self: Link;
    "sef:upload-document-data": Link;
}

export enum FolderMode {
    BATCH = 'batch',
    COUNTERS = 'counters',
    STANDARD = 'standard'
}

export interface Folder extends HalResource<FolderEmbedded, FolderLinks>{
    errorCount?: number;
    folderId: number | string;
    folderName: string;
    highlightedItemCount?: number;
    isDefaultFolder?: boolean;
    itemCountDisplayMode?: string;
    itemCount?: number;
    showContent: boolean;
    state: FlowState;
    spacing: FolderSpacing;
    external?: boolean;
    enabled?: boolean;
}

interface FolderEmbedded {
    nestedFolders?: Array<Folder>;
    category?: Category;
}

interface FolderLinks {
    self?: Link;
    "sef:folder-content"?: Link;
}

export type FolderSpacing = "none"|"margin_top"|"margin_bottom"|"margin_both";

export interface NavCategory {
    title: string;
    icon: string;
    flowListCategories: Array<FlowListCategoryName>;
    defaultOrderingDateAttribute: string;
}

export interface SearchKeywordsDTO {
    category?: string|null;
    account?: Array<string>;
    subject?: Array<string>;
    tag?: Array<string>;
}

export interface SearchKeyword {
    text: string;
    subText?: string;
    type: string;
    value?: string;
    icon?: string;
}

export interface CategorySearchKeyword extends SearchKeyword {
    categoryCode: string;
}

export interface AccountSearchKeyword extends SearchKeyword {
    accountName: string;
}
