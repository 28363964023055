import { render, staticRenderFns } from "./SCombobox.vue?vue&type=template&id=37dfb780&scoped=true&"
import script from "./SCombobox.vue?vue&type=script&lang=ts&"
export * from "./SCombobox.vue?vue&type=script&lang=ts&"
import style0 from "./SCombobox.vue?vue&type=style&index=0&id=37dfb780&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37dfb780",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
installComponents(component, {VChip,VCombobox})
