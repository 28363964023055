











import Vue from "vue";
import {organisationLogoURL} from "@/api/appDetailsApi";
import SImg from "@/component/ui/SImg.vue";

export default Vue.extend({
  name: 'SignatureContentLogo',

  components: {SImg},

  computed: {
    organisationLogoURL(): string {
      return organisationLogoURL();
    }
  }
})
