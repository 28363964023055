




















































































































































import {AxiosProgressEvent, CancelTokenSource} from "axios";
import {ValidationProvider} from "vee-validate";
import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import formMixin from "@/mixins/formMixin";
import mobileMixin from "@/mixins/mobileMixin";
import SImg from "@/component/ui/SImg.vue";
import SProgressLinear from "@/component/ui/SProgressLinear.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {imageToBase64} from "@/utils/utils";

export default mixins(formMixin, mobileMixin).extend({
  name: 'SFileSelector',

  components: {
    SImg,
    SProgressLinear,
    STextButton,
    ValidationProvider
  },

  props: {
    appendText: String,
    disabled: Boolean,
    disableSelection: Boolean,
    imageFallback: String,
    filePlaceholder: String,
    permanentSelection: Boolean,
    label: String,
    type: {
      type: String as PropType<'pdf' | 'image' | 'generic' >,
      default: 'pdf' // backwards compatibility
    },
    uploadUrl: String,
    value: Object as PropType<File|undefined>,
    showUploadedFile:{
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      dropzoneOver: false,
      hover: false,
      uploading: false,
      progressIndeterminate: false as boolean,
      progressValue: 0 as number,
      uploadError: undefined as Error|undefined,
      source: undefined as CancelTokenSource|undefined,
      imageBase64: undefined as string|undefined,

      // Icons
      iconPdf: require('@/assets/icon-pdf.svg'),
      iconImage: require('@/assets/icon-image.svg'),
      iconGenericFile: require('@/assets/icon-file-simple-generic.svg'),
      iconInvalidFile: require('@/assets/icon-wrong-file.svg'),
      iconFileUploaded: require('@/assets/icon-file-uploaded.svg')
    }
  },

  computed: {
    localValue: {
      get(): File|undefined {
        return this.value;
      },
      set(value: File|undefined) {
        this.$emit('input', value);
      }
    },
    isFilePresent(): boolean {
      return this.localValue !== undefined || !!this.filePlaceholder || !!this.imageFallback;
    },
    filename(): string|undefined {
        if(this.filePlaceholder){
          return this.filePlaceholder;
        } else {
          return this.localValue?.name;
        }
    },
    image () {
      return this.type === 'image';
    },
    dropzoneText(): string {
      switch (this.type) {
        case "image":
          return this.$t('uploadWizard.steps.docUpload.selectImage').toString()
        case "pdf":
          return this.$t('uploadWizard.steps.docUpload.selectPDF').toString();
        case "generic":
          return this.$t('uploadWizard.steps.docUpload.selectGeneric').toString();
        default:
          throw "Unsupported type";
      }
    },
    docTypeText(): string {
      switch (this.type) {
        case "image":
          return this.$t('common.fileUploader.docType.image').toString();
        case "pdf":
          return this.$t('common.fileUploader.docType.pdf').toString();
        case "generic":
          return this.$t('common.fileUploader.docType.generic').toString();
        default:
          throw "Unsupported type";
      }
    },
    validFileIcon(): string {
      switch (this.type) {
        case "image":
          return this.iconImage;
        case "pdf":
          return this.iconPdf;
        case "generic":
          return this.iconGenericFile;
        default:
          throw "Unsupported type";
      }
    },
    invalidFileIcon(): string {
      return this.iconInvalidFile;
    },
    validationsDisabled(): boolean {
      return !!this.filePlaceholder;
    },
    imageSrc(): string|undefined {
      if(this.imageBase64){
        return this.imageBase64;
      } else {
        return this.imageFallback;
      }
    }
  },

  watch: {
    localValue(value: File|undefined){
      if(!value) this.reset();
    }
  },

  methods: {
    async onFileChange(e: InputEvent): Promise<void> {
      // If some file is present and user canceled file selection, then do nothing
      if(!!this.localValue && (e.target as HTMLInputElement).files?.length === 0) return;

      const {valid} = await (this.$refs.fileValidator as InstanceType<typeof ValidationProvider>).validate(e);
      if (valid) {
        const file: File|undefined = (e.target as HTMLInputElement).files?.[0];
        this.localValue = file;
        if(this.image && file){
          imageToBase64(file).then(base64 => {
            this.imageBase64 = base64;
          });
        }
        if(this.uploadUrl && file){
          this.uploadFile(file);
        }
      }
    },
    uploadFile(file: File): void {
      if (!file || !this.uploadUrl) return;

      this.uploading = true;
      this.progressValue = 0;
      this.source = this.axios.CancelToken.source();

      this.axios.post(this.uploadUrl, file,
          {
            headers: {
              'Content-Type': file.type,
              'OB-SP-Filename': encodeURIComponent(file.name)
            },
            cancelToken: this.source.token,
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              if (progressEvent.total) {
                let innerProgress: number = Math.round(progressEvent.loaded / progressEvent.total * 100);
                if (innerProgress === 100)
                  innerProgress = 99;
                this.progressValue = innerProgress;
              }
              else
                this.progressIndeterminate = true;
            }
          }
      ).then((response) => {
        this.progressValue = 100;
        this.progressIndeterminate = false;
        setTimeout(() => {
          this.uploading = false;
          this.$emit('uploaded', response.data);
        }, 500);
      }).catch((e: Error) => {
        this.localValue = undefined;
        this.uploading = false;
        this.uploadError = e;
        this.reset();
      });
    },
    cancelUpload() {
      if (this.source) {
        this.source.cancel();
        this.removeFile();
      }
    },
    removeFile(): void {
      this.localValue = undefined;
      this.$emit('file-remove');
    },
    reset() {
      this.imageBase64 = undefined;
      (this.$refs.fileValidator as InstanceType<typeof ValidationProvider>).value = undefined;
      (this.$refs.fileValidator as InstanceType<typeof ValidationProvider>).reset();
      this.progressValue = 0;
      this.progressIndeterminate = false;
      this.uploadError = undefined;
      this.uploading = false;
      // Any is used instead of HtmlInputElement type, because HtmlInputElement.value is defined
      // as string type and resetting works only with @null (not with empty string)
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (this.$refs.fileInput as any).value = null;
    },
    resetValidations() {
      (this.$refs.fileValidator as InstanceType<typeof ValidationProvider>).reset();
    },
  }
})
