import Vue, {PropType} from "vue";
import {PdfPageInfo} from "@/types";

export default Vue.extend({
  props: {
    pageInfo: {
      type: Object as PropType<PdfPageInfo>,
      required: true
    }
  },

  computed: {
    /**
     * Aktuální výška zobrazené stránky PDF (po aplikaci měřítka a rotace).
     */
    pageHeight(): number {
      return this.pageInfo.viewport.height;
    },
    /**
     * Originální výška stránky PDF (bez měřítka a rotace).
     * Například pro A4 (72 PPI) je výška 842 pixelů.
     */
    pageHeightRaw(): number {
      return this.pageInfo.viewport.rawDims.pageHeight;
    },
    /**
     * Aktuální šířka zobrazené stránky PDF (po aplikaci měřítka a rotace).
     */
    pageWidth(): number {
      return this.pageInfo.viewport.width;
    },
    /**
     * Originální šířka stránky PDF (bez měřítka a rotace).
     * Například pro A4 (72 PPI) je šířka 595 pixelů.
     */
    pageWidthRaw(): number {
      return this.pageInfo.viewport.rawDims.pageWidth;
    },
    /**
     * Aktuální otočení zobrazené stránky PDF.
     */
    pageRotation(): number {
      return this.pageInfo.rotation;
    },
    /**
     * Aktuální měřítko zobrazené stránky PDF.
     */
    pageScale(): number {
      return this.pageInfo.viewport.scale;
    }
  }
});