


















































import Vue, {PropType} from "vue";
import {SignatureProfile} from "@/types";

export default Vue.extend({
  name: 'ProfileBatchItem',
  props: {
    signatureProfile: {
      type: Object as PropType<SignatureProfile>,
      required: true
    },
    documentCount: Number,
    info: String,
    collapsed: Boolean,
  },
  computed: {
    localCollapsed: {
      get(): boolean {
        return this.collapsed;
      },
      set(value: boolean){
        this.$emit('update:collapsed', value);
      }
    },
    sigProfileTitle(): string {
      return `${this.signatureProfile.profileName} (${this.signatureProfile.legalLevel})`;
    },
    documentCountInfo(): string {
      return this.$tc('common.documentPlural', this.documentCount);
    }
  }
})
