import { render, staticRenderFns } from "./SignatureOnlineDocumentDetail.vue?vue&type=template&id=5100d8f1&scoped=true&"
import script from "./SignatureOnlineDocumentDetail.vue?vue&type=script&lang=ts&"
export * from "./SignatureOnlineDocumentDetail.vue?vue&type=script&lang=ts&"
import style0 from "./SignatureOnlineDocumentDetail.vue?vue&type=style&index=0&id=5100d8f1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5100d8f1",
  null
  
)

export default component.exports