











import displayMixin from "@/mixins/displayMixin";
import mixins from "vue-typed-mixins";

export default mixins(displayMixin).extend({
  name: 'AddVisualisationInfo',
  props: {
    i18nText: String
  },
  methods: {
    addVisualisation() {
      this.$router.push({name: 'visualisation-new', query: {...this.$router.currentRoute.query}}).catch();
    },
  }
})
