


























import {IMAGE_DATA_URI_PREFIX} from "@/utils/constants";
import avatarMixin from "@/mixins/avatarMixin";
import mutationMixin from "@/mixins/mutationMixin";
import mixins from "vue-typed-mixins";

export default mixins(avatarMixin, mutationMixin).extend({
  name: 'SAvatar',
  props: {
    apiLink: {
      type: Boolean,
      default: true
    },
    base64: Boolean,
    border: Boolean,
    borderColor: {
      type: String,
      default: 'mutedText'
    },
    borderWidth: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 40
    },
    src: String,
    fallbackBackgroundColor: {
      type: String,
      default: 'mutedText'
    },
    fallbackIcon: {
      type: String,
      default: '$user'
    },
    fallbackIconColor: {
      type: String,
      default: 'mainBackground'
    }
  },
  data(){
    return {
      base64ImagePrefix: IMAGE_DATA_URI_PREFIX,
      localSrc: undefined as string|undefined
    }
  },
  computed:{
    avatarBorderStyle(): object {
      return {
        border: this.border ? this.borderWidth + 'px solid ' + this.$vuetify.theme.currentTheme[this.borderColor] + ' !important' : ''
      }
    },
    empty(): boolean {
      return !this.src || !this.localSrc;
    }
  },
  methods: {
    async setSource(): Promise<void> {
      if(!this.src) this.localSrc = undefined;
      if(this.base64){
        if(this.src.startsWith("data:image")){
         this.localSrc = this.src;
        } else {
          this.localSrc = IMAGE_DATA_URI_PREFIX + this.src;
        }
      } else if(this.apiLink) {
         this.localSrc = await this.getUserAvatar(this.src);
      } else {
        this.localSrc = this.src;
      }
    },
  },
  watch: {
    src(){
      this.setSource();
    }
  },
  created() {
    this.subscribe((mutation) => {
      if (mutation.type === 'profile/setPersonalInfo') {
        this.setSource();
      }
    })

    this.setSource();
  }
})
