import axios from "@/plugin/vueAxios";
import {Category, Locale} from "@/types";

export const BUSINESS_CONFIG_PATH = '/business-config'
export const CATEGORIES_PATH = `${BUSINESS_CONFIG_PATH}/categories`

export async function apiCategoryGet(categoryCode: string): Promise<Category> {
  const response = await axios.get(`${CATEGORIES_PATH}/${categoryCode}`);
  return response.data as Category;
}

export async function apiActiveLocalesGet(): Promise<Array<Locale>> {
  const response = await axios.get(BUSINESS_CONFIG_PATH);
  return response.data.activeLocales;
}