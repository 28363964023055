import { render, staticRenderFns } from "./FlowList.vue?vue&type=template&id=70f6b770&scoped=true&"
import script from "./FlowList.vue?vue&type=script&lang=ts&"
export * from "./FlowList.vue?vue&type=script&lang=ts&"
import style0 from "./FlowList.vue?vue&type=style&index=0&id=70f6b770&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f6b770",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VList,VListItemGroup})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
