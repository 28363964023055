import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {apiBatchCreate, apiBatchGet} from "@/api/batchesApi";
import {RootState} from "@/store/store";
import {Batch, BatchCreatePayload, BatchInProgress, BatchProcessed, BatchProcessedAction, BatchState} from "@/types";

interface BatchesState {
  batchesInProgress: Array<BatchInProgress>
}

const checkBatchProcessing = async (context: ActionContext<BatchesState, RootState>, batchId: number): Promise<void> => {
  const { commit, state } = context;
  try {
    const batchInProgress = state.batchesInProgress.find(batchInProgress => batchInProgress.batch.batchId === batchId);
    const batchUpdated = await apiBatchGet(batchId);
    if (batchUpdated.batchState === BatchState.IN_PROGRESS) {
      if (batchInProgress)
        batchInProgress.checkTimeoutId = window.setTimeout(checkBatchProcessing, 2000, context, batchId);
      else
        console.warn(`Batch ${batchId} not found in queue`);
    }
    else {
      commit('removeBatchInProgress', { batchId: batchId });
      commit('batchProcessed', toBatchProcessed(batchInProgress, batchUpdated));
    }
  }
  catch (e) {
    console.error(`Error occurred while getting the batch ${batchId}`, e);
  }
}

const toBatchProcessed = (batchInProgress: BatchInProgress | undefined, batchUpdated: Batch): BatchProcessed => {
  return {
    action: BatchProcessedAction.SIGN,
    batch: batchUpdated,
    flowIds: batchInProgress?.flowIds ?? [],
    flowsPath: batchInProgress?.flowsPath
  }
}

const state = (): BatchesState => ({
  batchesInProgress: []
})

const mutations: MutationTree<BatchesState> = {
  addBatchInProgress(state, payload: BatchInProgress) {
    state.batchesInProgress.push(payload);
  },
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  batchProcessed(state, payload: Batch) {
    // Empty on purpose, acts as global event
  },
  removeBatchInProgress(state, payload: { batchId: number }) {
    const index = state.batchesInProgress.findIndex(batchInProgress => batchInProgress.batch.batchId === payload.batchId);
    if (index > -1) {
      const checkTimeoutId = state.batchesInProgress[index].checkTimeoutId;
      if (checkTimeoutId)
        window.clearTimeout(checkTimeoutId);

      state.batchesInProgress.splice(index, 1);
    }
    else
      console.warn(`Batch ${payload.batchId} not found in queue`);
  }
}

const getters: GetterTree<BatchesState, RootState> = {}

const actions: ActionTree<BatchesState, RootState> = {
  async createBatch(context, payload: BatchCreatePayload): Promise<Batch> {
    const { commit } = context;
    try {
      const batch = await apiBatchCreate(payload.stepIds, payload.batchName, payload.signatureImage);
      if (batch.batchState === BatchState.IN_PROGRESS) {
        const batchInProgress: BatchInProgress = {
          batch: batch,
          checkTimeoutId: window.setTimeout(checkBatchProcessing, 2000, context, batch.batchId),
          flowIds: payload.flowIds,
          flowsPath: payload.flowsPath
        };
        commit('addBatchInProgress', batchInProgress);
      }
      else {
        // Dávka byla vytvořena a i zpracována (tato situace pravděpodobně nikdy nenastane)
        const batchProcessed: BatchProcessed = {
          action: BatchProcessedAction.SIGN,
          batch: batch,
          flowIds: payload.flowIds,
          flowsPath: payload.flowsPath
        };
        commit('batchProcessed', batchProcessed);
      }

      return batch;
    }
    catch (e) {
      console.error(`Error occurred while creating the batch "${payload.batchName}"`, e);
      throw e;
    }
  }
}

export const batches: Module<BatchesState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}