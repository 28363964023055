import {ObeliskConfiguration} from "@/types";

export default {
    // eslint-disable-next-line no-undef
    guiUrl: obeliskConfig.guiUrl,
    // eslint-disable-next-line no-undef
    apiUrl: obeliskConfig.apiUrl,
    // eslint-disable-next-line no-undef
    authUrl: obeliskConfig.keyCloakUrl,
    // eslint-disable-next-line no-undef
    authRealm: obeliskConfig.keyCloakRealm,
    clientId: 'obelisk-sp-gui'
} as ObeliskConfiguration;
