<template>
  <div class="audit-view s-scrollbar">
    <div class=" ml-12 mr-12 ">
      <h2 class="mb-4">{{ $t('docDetail.audit.title') }}</h2>
      <FlowDetailAuditSection v-for="section in this.loadedAuditSections" :key="section" :section="section">
      </FlowDetailAuditSection>
      <SLoader class="mx-auto" style="width: 100%; position: absolute" v-if="!this.loadedAuditSections"></SLoader>
    </div>
  </div>
</template>

<script>
import FlowDetailAuditSection from "@/component/workflow/detail/audit/FlowDetailAuditSection";
import auditMixin from "@/mixins/auditMixin";
import SLoader from "@/component/ui/SLoader";

export default {
  name: "FlowDetailAuditView",
  components: {SLoader, FlowDetailAuditSection},
  mixins: [auditMixin],
  props: {
    flow: Object
  },
  methods: {
    reloadLogs(flow) {
      if (flow)
        (() => this.loadedAuditSections = this.auditSections(flow)).call(this);
    }
  },
  computed: {},
  data() {
    return {
      loadedAuditSections: undefined
    }
  },
  watch: {
    flow(flow) {
      this.loadedAuditSections = null;
      this.reloadLogs(flow);
    }
  },
  mounted() {
    if (this.flow)
      this.reloadLogs(this.flow);
  }
}
</script>

<style scoped>
.audit-view {
  object-fit: fill;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: var(--v-scrollbar-base) var(--v-mainBackground-base);
}
</style>
