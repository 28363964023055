import {SIGNING_PROFILES} from "@/utils/urls";
import Vue from "vue";
import {SignatureProfile} from "@/types";

export default Vue.extend({
  methods: {
    async apiUpdateProfile(profile: SignatureProfile): Promise<SignatureProfile> {
      return (await this.axios.put(`${SIGNING_PROFILES}/${profile.profileId}`, profile)).data as SignatureProfile;
    },
    areAnyProfilesAvailable(applicableProfiles: Array<SignatureProfile>): boolean {
      return applicableProfiles && applicableProfiles.length > 0;
    },
    isValidProfileSelected(profile: SignatureProfile, applicableProfiles: Array<SignatureProfile>): boolean {
      return profile && !!applicableProfiles.find(p => p.profileId === profile.profileId);
    }
  }
})
