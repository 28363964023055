





































import Vue, {PropType} from "vue";
import SignatureContentLayout from "@/component/signature/SignatureContentLayout.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import UserInfo from "@/component/UserInfo.vue";
import {Flow, UserAccount} from "@/types";

export default Vue.extend({
  name: 'SignatureOnlineIntro',

  components: {SignatureContentLayout, SIconButton, STextButton, UserInfo},

  props: {
    flow: {
      type: Object as PropType<Flow>,
      required: true
    },
    navCancel: Boolean
  },

  computed: {
    note(): string | undefined {
      return this.flow?.note;
    },
    subject(): string | undefined {
      return this.flow?.subject;
    },
    user(): UserAccount | undefined {
      return this.flow?._embedded?.owner;
    },
    recallAvailable(): boolean {
      return this.flow?.state === 'active' && this.flow?._embedded?.owner.accountName ===
          this.$store.getters['profile/accountName'];
    },
    cancelType(): string {
      return this.recallAvailable ? 'recall' : 'rejection';
    },
    cancelText(): string {
      return this.$t(`signature.online.${this.cancelType}.actions.submit`).toString();
    }
  },

  methods: {
    cancel(): void {
      this.$emit('cancel');
    },
    next(): void {
      this.$emit('next');
    }
  }
})
