











import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import signatureOnlineContentMixin from "@/mixins/signatureOnlineContentMixin";
import SignatureExternalUserName from "@/component/signature/external/SignatureExternalUserName.vue";
import SignatureExternalUserVisualisation from "@/component/signature/external/SignatureExternalUserVisualisation.vue";
import SignatureOnlineDocumentDetail from "@/component/signature/online/SignatureOnlineDocumentDetail.vue";
import SignatureOnlineDocumentInvalid from "@/component/signature/online/SignatureOnlineDocumentInvalid.vue";
import SignatureOnlineIntro from "@/component/signature/online/SignatureOnlineIntro.vue";
import SignatureOnlineRejection from "@/component/signature/online/SignatureOnlineRejection.vue";
import SignatureOnlineRejectionConfirmation
  from "@/component/signature/online/SignatureOnlineRejectionConfirmation.vue";
import SignatureOnlineSuccess from "@/component/signature/online/SignatureOnlineSuccess.vue";
import {
  PartialRecord,
  SignatureExternalConfig,
  SignatureExternalPhase,
  SignatureExternalPhaseExt,
  SignatureOnlinePhase
} from "@/types";
import {PERSONAL_INFO, VISUALISATION} from "@/utils/urls";
import {ACCOUNT_TYPE} from "@/utils/constants";

export default mixins(signatureOnlineContentMixin).extend({
  name: 'SignatureExternalContent',

  components: {
    SignatureExternalUserName,
    SignatureExternalUserVisualisation,
    SignatureOnlineDocumentDetail,
    SignatureOnlineDocumentInvalid,
    SignatureOnlineIntro,
    SignatureOnlineRejection,
    SignatureOnlineRejectionConfirmation,
    SignatureOnlineSuccess
  },

  props: {
    phase: {
      type: String as PropType<SignatureExternalPhaseExt>,
      required: true
    },
    redirectUri: String
  },

  data() {
    return {
      firstName: undefined as string | undefined,
      lastName: undefined as string | undefined,
      visualisation: undefined as string | undefined,
    }
  },
  created() {
    let personalInfo = this.$store.getters['profile/info'];
    this.firstName = personalInfo.userFirstName;
    this.lastName = personalInfo.userLastName;
  },
  computed: {
    currentContentConfig(): SignatureExternalConfig | undefined {
      return this.externalContentConfig[this.phase];
    },
    temporaryAccount(): boolean {
      return this.$store.getters['profile/accountType'] === ACCOUNT_TYPE.TEMPORARY;
    },
    externalContentConfig(): PartialRecord<SignatureExternalPhaseExt, SignatureExternalConfig> {
      let config = {
        ...this.onlineContentConfig,
        [SignatureExternalPhase.USER_NAME]: {
          componentName: 'SignatureExternalUserName',
          props: {
            firstName: this.firstName,
            lastName: this.lastName
          },
          listeners: {
            next: () => this.changePhase(SignatureExternalPhase.USER_VISUALISATION),
            'update:firstName': (firstName: string | undefined) => this.firstName = firstName,
            'update:lastName': (lastName: string | undefined) => this.lastName = lastName
          }
        },
        [SignatureExternalPhase.USER_VISUALISATION]: {
          componentName: 'SignatureExternalUserVisualisation',
          props: {
            name: this.firstName + ' ' + this.lastName,
            visualisation: this.visualisation
          },
          listeners: {
            back: () => this.changePhase(SignatureExternalPhase.USER_NAME),
            next: () => this.visualisationConfirmed(),
            'update:visualisation': (visualisation: string | undefined) => this.visualisation = visualisation
          }
        }
      };
      // Změna nastavení některých komponent online podpisu dle potřeb podpisu externím uživatelem
      (config[SignatureOnlinePhase.DOCUMENT_DETAIL] as SignatureExternalConfig).props.navCancel = false;
      (config[SignatureOnlinePhase.INTRO] as SignatureExternalConfig).props.navCancel = false;
      (config[SignatureOnlinePhase.REJECTION_CONFIRMATION] as SignatureExternalConfig).props.actionBack = true;
      (config[SignatureOnlinePhase.SUCCESS] as SignatureExternalConfig).props.actionBack = true;
      (config[SignatureOnlinePhase.SUCCESS] as SignatureExternalConfig).props.actionNext = this.temporaryAccount &&
          !!this.redirectUri;

      return config;
    }
  },

  methods: {
    changePhase(phase: SignatureExternalPhaseExt): void {
      this.$emit('change-phase', phase);
    },
    async visualisationConfirmed(): Promise<void> {
      try {
        // Uložení jména a příjmení uživatele
        let personalInfo = this.$store.getters['profile/info'];
        personalInfo.userFirstName = this.firstName;
        personalInfo.userLastName = this.lastName;
        await this.axios.put(PERSONAL_INFO, personalInfo);
        // Vytvoření nové vizualizace
        let visualisation = {
          name: this.firstName + ' ' + this.lastName,
          signatureImage: this.visualisation,
          visualName: this.$t('signature.external.userVisualisation.visualisationName')
        };
        await this.axios.post(VISUALISATION, visualisation);
        await this.$store.dispatch('auth/updateToken', 999999);
        await this.$store.dispatch('profile/loadPersonalInfo');
        this.$emit('user-configured');
      }
      catch (e) {
        console.error('Error occurred while saving user profile settings', e);
      }
    }
  }
})
