import store from "@/store/store";
import {
  DocumentField,
  DocumentFieldType,
  PdfPageInfo,
  PdfRectDims,
  PdfRectDimsOptional,
  PdfWidgetConfig,
  PdfWidgetRect,
  PdfWidgetRestrictionExpressions,
  PdfWidgetRestrictions,
  PdfWidgetRestrictionScope,
  PdfWidgetSignatureParams,
  PdfWidgetType,
  SigningProfileFieldRestrictions
} from "@/types";

export const PDF_FILENAME_DEFAULT = 'SP Document.pdf';
export const PDF_SCALE_MAX = 10;
export const PDF_SCALE_MIN = 0.1;
export const PDF_WIDGET_HEIGHT_DEFAULT = 50;
export const PDF_WIDGET_HEIGHT_MIN = 10;
export const PDF_WIDGET_WIDTH_DEFAULT = 200;
export const PDF_WIDGET_WIDTH_MIN = 20;

export const defaultFieldRestrictions: SigningProfileFieldRestrictions = {
  maxHeight: [],
  maxWidth: [],
  minHeight: [`${PDF_WIDGET_HEIGHT_MIN}`],
  minWidth: [`${PDF_WIDGET_WIDTH_MIN}`]
}

export const defaultWidgetRect: PdfWidgetRect = {
  height: PDF_WIDGET_HEIGHT_DEFAULT,
  width: PDF_WIDGET_WIDTH_DEFAULT,
  left: 0,
  bottom: 0
}

export const emptyDocumentField: DocumentField = {
  _links: undefined,
  height: PDF_WIDGET_HEIGHT_DEFAULT,
  width: PDF_WIDGET_WIDTH_DEFAULT,
  left: 0,
  bottom: 0,
  name: '',
  page: 1,
  setBySigner: false,
  type: DocumentFieldType.SP,
  uiName: ''
}

export function attachSignHereWidget(targetWidget: PdfWidgetConfig, attachedWidget: PdfWidgetConfig): PdfWidgetConfig {
  const fieldAttached: DocumentField = attachedWidget.params.field;
  const fieldOrig: DocumentField = structuredClone(targetWidget.params.field);

  targetWidget.params.field = {
    ...fieldOrig,
    stepInputNumber: fieldAttached.stepInputNumber
  };
  targetWidget.params.fieldOrig = fieldOrig;
  targetWidget.params.title = attachedWidget.params.title;
  targetWidget.params.transformed = true;
  targetWidget.removable = true;

  return targetWidget;
}

export function detachSignHereWidget(widget: PdfWidgetConfig): PdfWidgetConfig {
  const fieldOrig: DocumentField = structuredClone(widget.params.fieldOrig ?? emptyDocumentField);

  widget.params.field = fieldOrig;
  widget.params.fieldOrig = undefined;
  widget.params.title = fieldOrig?.uiName;
  widget.params.transformed = false;
  widget.removable = false;

  return widget;
}

export function calcWidgetRestrictions(expressions: PdfWidgetRestrictionExpressions,
                                       scope: PdfWidgetRestrictionScope): PdfWidgetRestrictions {
  const {
    maxHeight: maxHeightExpressions,
    maxWidth: maxWidthExpressions,
    minHeight: minHeightExpressions,
    minWidth: minWidthExpressions
  } = expressions;

  let maxHeight: number | undefined = undefined;
  let maxWidth: number | undefined = undefined;
  let minHeight: number | undefined = undefined;
  let minWidth: number | undefined = undefined;

  if (maxHeightExpressions.length > 0)
    maxHeight = roundDimension(Math.min(...maxHeightExpressions.map(r => r.evaluate(scope))));

  if (maxWidthExpressions.length > 0)
    maxWidth = roundDimension(Math.min(...maxWidthExpressions.map(r => r.evaluate(scope))));

  if (minHeightExpressions.length > 0)
    minHeight = roundDimension(Math.max(...minHeightExpressions.map(r => r.evaluate(scope))));

  if (minWidthExpressions.length > 0)
    minWidth = roundDimension(Math.max(...minWidthExpressions.map(r => r.evaluate(scope))));

  return {
    maxHeight,
    maxWidth,
    minHeight,
    minWidth
  };
}

export function compareWidgets(widget1: PdfWidgetConfig, widget2: PdfWidgetConfig): number {
  /* Porovnání podle stránky */
  const pageResult = widget1.pageNumber - widget2.pageNumber;
  if (pageResult === 0) {
    /* Porovnání podle vertikálního umístění na stránce (větší číslo znamená výš) */
    const verticalResult = widget2.rect.bottom - widget1.rect.bottom;
    if (verticalResult === 0) {
      /* Porovnání podle horizontálního umístění na stránce (zleva doprava) */
      return widget1.rect.left - widget2.rect.left;
    }
    else
      return verticalResult;
  }
  else
    return pageResult;
}

export function computeWidgetStyle(widgetSize: PdfRectDims, pageInfo: PdfPageInfo): Record<string, string> {
  const { height, width } = widgetSize;
  const { rotation: pageRotation, viewport: { scale: pageScale } } = pageInfo;

  const calcHeight = height * pageScale;
  const calcWidth = width * pageScale;
  let calcLeft = 0;
  let calcTop = 0;

  if (pageRotation % 180 === 90) {
    calcLeft = (calcHeight - calcWidth) / 2;
    calcTop = (calcWidth - calcHeight) / 2;
  }

  return {
    height: `${calcHeight}px`,
    width: `${calcWidth}px`,
    left: `${calcLeft}px`,
    top: `${calcTop}px`,
    transform: `rotate(${pageRotation}deg)`
  };
}

export async function createSignatureWidget(pdfViewerId: number, documentField: DocumentField, removable = false,
                                            editable = false): Promise<PdfWidgetConfig> {
  const { page, height, width, left, bottom, setBySigner, type, uiName } = documentField;
  const rect: PdfWidgetRect = {
    height,
    width,
    left,
    bottom
  };
  const params: PdfWidgetSignatureParams = {
    field: documentField,
    title: uiName,
    transformed: type === DocumentFieldType.PDF_VISIBLE && setBySigner
  };
  return await createWidget(pdfViewerId, PdfWidgetType.SIGNATURE, page, rect, params, editable, removable);
}

export async function createSignHereWidget(pdfViewerId: number, documentField: DocumentField, removable = false,
                                           editable = false): Promise<PdfWidgetConfig> {
  const { page, height, width, left, bottom, uiName } = documentField;
  const rect: PdfWidgetRect = {
    height,
    width,
    left,
    bottom
  };
  const params: PdfWidgetSignatureParams = {
    field: documentField,
    title: uiName,
    transformed: false
  };
  return await createWidget(pdfViewerId, PdfWidgetType.SIGN_HERE, page, rect, params, editable, removable);
}

export async function createWidget(pdfViewerId: number, type: PdfWidgetType, pageNumber: number, rect: PdfWidgetRect,
                                   params: PdfWidgetSignatureParams, editable = true,
                                   removable = true): Promise<PdfWidgetConfig> {
  const id: number = await store.dispatch('pdfViewer/nextWidgetId', pdfViewerId);
  return {
    editable,
    id,
    pageNumber,
    params,
    rect,
    removable,
    type
  }
}

export function getRectDimsOptional(height: number | undefined, width: number | undefined,
                                    rotationDeg: number): PdfRectDimsOptional {
  const dimensions = [height, width];

  for (let i = 0; i < rotationDeg / 90; i++)
    dimensions.push(dimensions.shift());

  return {
    height: dimensions[0] && roundDimension(dimensions[0]),
    width: dimensions[1] && roundDimension(dimensions[1])
  };
}

export function getRectDims(height: number, width: number, rotationDeg: number): PdfRectDims {
  const { height: rectHeight, width: rectWidth } = getRectDimsOptional(height, width, rotationDeg);

  return {
    height: rectHeight ?? 0,
    width: rectWidth ?? 0
  };
}

export function roundDimension(dimension: number): number {
  return Math.round((dimension + Number.EPSILON) * 100) / 100;
}

export function transformToDocumentField(widget: PdfWidgetConfig): DocumentField {
  return {
    ...widget.params.field,
    ...widget.rect,
    page: widget.pageNumber
  }
}

export function transformToSignatureWidget(signHereWidget: PdfWidgetConfig): PdfWidgetConfig {
  return {
    ...signHereWidget,
    params: {
      ...signHereWidget.params,
      transformed: true
    },
    removable: true,
    type: PdfWidgetType.SIGNATURE
  }
}

export function transformToSignHereWidget(signatureWidget: PdfWidgetConfig): PdfWidgetConfig {
  return {
    ...signatureWidget,
    params: {
      ...signatureWidget.params,
      transformed: false
    },
    removable: false,
    type: PdfWidgetType.SIGN_HERE
  }
}