




































import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";

export default mixins(displayMixin).extend({
  name: 'PDFToolbarBtn',

  props: {
    active: Boolean,
    contentClass: String,
    disabled: Boolean,
    highlighted: Boolean,
    icon: {
      type: String,
      required: true
    },
    text: String
  }
})
