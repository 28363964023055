






























import SDialog from "@/component/ui/SDialog.vue";
import Vue from 'vue';
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SSignatureSelector from "@/component/ui/form/SSignatureSelector.vue";

export default Vue.extend({
  name: "AdHocSignatureDialog",
  components: {SSignatureSelector, STextButton, SDialog},
  props: {
    value: Boolean,
    imageMethods: Array<string>
  },
  data(){
    return{
      image: undefined as string | undefined,
      draw: false,
      text: false,
      picture: false,
    }
  },
  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
  },
  methods: {
    async sign(): Promise<void> {
      let signatureValid = await (this.$refs.signatureSelector as InstanceType<typeof SSignatureSelector>).validate();
      if(!signatureValid)return ;
      this.$emit('sign', {image: this.image});
      this.localValue = false;
    },
    adHocAvailableSigningMethods(): void {
      if (this.imageMethods) {
        this.imageMethods.find((value) => {
          if (value === 'DRAW') {
            this.draw = true;
          }
          if (value === 'LOAD_FILE') {
            this.picture = true;
          }
          if (value === 'TEXT') {
            this.text = true;
          }
        })
      }
    },
    clear(): void {
      (this.$refs.signatureSelector as InstanceType<typeof SSignatureSelector>).clear();
    }
  },
  watch: {
    localValue(value: boolean) {
      if (!value) {
        this.clear();
      }
    }
  },
  created() {
    this.adHocAvailableSigningMethods();
  },
  updated() {
    this.adHocAvailableSigningMethods();
  }
})
