import Vue from "vue";
import {Flow, FlowStep, ThemeOnlineSignatureCustomization} from "@/types";

export default Vue.extend({
  props: {
    flowId: {
      type: Number,
      required: true
    },
    flowStepId: {
      type: Number,
      required: true
    },
    redirectUri: {
      type: String,
      required: false
    },
    skipIntro: {
      type: Boolean,
      default: false
    },
    skipConfirmation: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      flow: undefined as Flow | undefined
    }
  },

  computed: {
    accountName(): string | undefined {
      return this.$store.getters['profile/accountName'];
    },
    applicableSigningProfiles(): boolean {
      return !!this.flow && !!this.step && !!this.step._embedded?.applicableSigningProfiles?.length;
    },
    flowActive(): boolean {
      return !!this.flow && this.flow.state === 'active';
    },
    flowOwner(): boolean {
      return !!this.flow && this.flow._embedded?.owner?.accountName === this.accountName;
    },
    step(): FlowStep | undefined {
      return !this.flow ? undefined : this.flow._embedded.steps.find(s => s.stepId == this.flowStepId);
    },
    stepActive(): boolean {
      return !!this.step && this.step._embedded?.account?.accountName === this.accountName && (this.step.state ===
          'active' || this.step.state === 'error');
    },
    stepProcessing(): boolean {
      return this.step?.state === 'processing';
    },
    themeCustomization(): ThemeOnlineSignatureCustomization {
      return this.$store.getters['theme/onlineGuiCustomization'];
    },
    autoredirect(): boolean {
      return this.$store.getters['config/autoredirect'];
    },
    autoredirectDelay(): number {
      return this.$store.getters['config/autoredirectDelay'];
    }
  },

  methods: {
    signatureSettings(): void {
      const queryObject = Object.assign({...this.$router.currentRoute.query},
          {skipIntro: 'true'}) as Record<string, string>;
      const redirectUri = this.$router.currentRoute.path + '?' + new URLSearchParams(queryObject).toString();

      this.$router.push({
        path: '/profile/signature',
        query: {'online-signature-redirect-uri': redirectUri}
      }).catch(() => { /* ignore */ });
    },
    redirectToSystem(instant?: boolean): void {
      if (this.redirectUri) {
        if (!instant && this.autoredirect && this.autoredirectDelay) {
          setTimeout(() => {
            window.location.href = this.redirectUri;
          }, this.autoredirectDelay * 1000);
        }
        else window.location.href = this.redirectUri;
      }
      else {
        console.error('No redirect URL provided');
      }
    }
  }
})