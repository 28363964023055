
















































import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import OverviewBatchActions from "@/component/workflow/overview/OverviewBatchActions.vue";
import OverviewSearch from "@/component/workflow/overview/OverviewSearch.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import SIconSelect from "@/component/ui/form/SIconSelect.vue";


export default mixins(displayMixin).extend({
  name: 'OverviewHeader',
  components: {OverviewBatchActions, OverviewSearch, SIconButton, SIconSelect},
  mixins: [displayMixin],
  props: {
    color: String
  },
  computed: {
    batchMode: {
      get(): boolean {
        return this.$store.getters["workflow/batchMode"];
      },
      set(value: string) {
        this.$store.commit('workflow/setBatchMode', {batchMode: value});
      }
    },
    selectedCount(): number {
      return this.$store.getters['workflow/flowSelectedCount'];
    },
    totalCount(): number {
      return this.$store.getters['workflow/selectableFlowCount'];
    },
    nothingSelected(): boolean {
      return this.selectedCount === 0;
    },
    everythingSelected(): boolean {
      return this.selectedCount === this.totalCount
    },
    indeterminateState(): boolean {
      return !this.everythingSelected && !this.nothingSelected;
    },
    everythingSelectedCheckbox: {
      get(): boolean {
        return !this.nothingSelected;
      },
      set(value: boolean) {
        if (this.indeterminateState) {
          this.cancelSelection();
        }
        else if (value) {
          this.selectAll();
        }
        else {
          this.cancelSelection();
        }
      }
    }
  },
  methods: {
    navOpen(): void {
      this.$emit('nav-open');
    },
    cancelSelection(): void {
      this.$store.commit('workflow/flowCancelSelection');
    },
    selectAll(): void {
      this.$store.commit('workflow/flowSelectAll');
    },
    loadAllFlows(): void {
      this.$store.commit('workflow/flowLoadAll');
    },
    onCheckboxAction() {
      if (this.indeterminateState) {
        this.cancelSelection();
      }
      else if (this.everythingSelected) {
        this.selectAll();
      }
      else {
        this.cancelSelection();
      }
    }
  }
})
