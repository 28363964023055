









import Vue, {PropType} from 'vue'
import {SignatureProfile, SignatureProfileFormMode} from "@/types";

export default Vue.extend({
  name: 'ClientProviderCardCertCreateWarning',
  props: {
    item: {
      type: Object as PropType<SignatureProfile>,
      required: true
    },
    mode: {
      type: Object as PropType<SignatureProfileFormMode>,
      required: true
    }
  },
  computed: {
    show(): boolean {
      return !this.item.isCaProfile && (this.mode === SignatureProfileFormMode.CREATE || !this.item.signingCertificate)
    }
  },
  methods: {
    clear(): void {
      // nothing here
    },
    valid(): boolean {
      return true;
    }
  }
})
