




































































import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import pdfPageInfoMixin from "@/mixins/pdfPageInfoMixin";
import PDFWidgetToolbarBtn from "@/component/pdf/widgets/PDFWidgetToolbarBtn.vue";
import SDragResize, {DragEvent, ResizeEvent} from "@/component/ui/SDragResize.vue";
import {PdfRectDims, PdfWidgetRect} from "@/types";
import {getRectDims, getRectDimsOptional, roundDimension} from "@/utils/pdfViewerUtils";

export default mixins(pdfPageInfoMixin).extend({
  name: 'PDFWidget',

  components: {PDFWidgetToolbarBtn, SDragResize},

  props: {
    editable: Boolean,
    maxHeight: Number,
    maxWidth: Number,
    minHeight: Number,
    minWidth: Number,
    removable: Boolean,
    value: {
      type: Object as PropType<PdfWidgetRect>,
      required: true
    },
    zIndex: [Number, String]
  },

  data() {
    return {
      rect: {
        height: 0,
        width: 0,
        left: 0,
        top: 0
      }
    }
  },

  computed: {
    calcMaxHeight(): number | undefined {
      const maxHeight =  getRectDimsOptional(this.maxHeight, this.maxWidth, this.pageRotation).height;
      return maxHeight && maxHeight * this.pageScale;
    },
    calcMaxWidth(): number | undefined {
      const maxWidth = getRectDimsOptional(this.maxHeight, this.maxWidth, this.pageRotation).width;
      return maxWidth && maxWidth * this.pageScale;
    },
    calcMinHeight(): number | undefined {
      const minHeight = getRectDimsOptional(this.minHeight, this.minWidth, this.pageRotation).height;
      return minHeight && minHeight * this.pageScale;
    },
    calcMinWidth(): number | undefined {
      const minWidth = getRectDimsOptional(this.minHeight, this.minWidth, this.pageRotation).width;
      return minWidth && minWidth * this.pageScale;
    },
    localValue: {
      get(): PdfWidgetRect {
        return this.value;
      },
      set(value: PdfWidgetRect): void {
        this.$emit('input', value);
      }
    },
    size(): PdfRectDims {
      const { height, width } = this.rect;
      return this.origWidgetSize(height, width);
    }
  },

  created() {
    this.initWidgetRect(this.localValue);
  },

  methods: {
    initWidgetRect(rect: PdfWidgetRect): void {
      const {height, width, left, bottom} = rect;
      /* Výchozí rozměry stránky (bez rotace) */
      const {height: pageHeight, width: pageWidth} = getRectDims(this.pageHeight, this.pageWidth,
          this.pageRotation);
      /* Výpočet chybějících souřadnic a přizpůsobení měřítku */
      const calcLeft = left * this.pageScale,
            calcTop = pageHeight - (height + bottom) * this.pageScale,
            calcRight = pageWidth - (width + left) * this.pageScale,
            calcBottom = bottom * this.pageScale;
      /* Rotace souřadnic dle aktuálního nastavení */
      const calcPosition = [calcLeft, calcTop, calcRight, calcBottom];
      for (let i = 0; i < this.pageRotation / 90; i++)
        calcPosition.unshift(calcPosition.pop() ?? 0);
      /* Rozměry widgetu respektující měřítko a rotaci */
      const {height: calcHeight, width: calcWidth} = getRectDims(height * this.pageScale, width * this.pageScale,
          this.pageRotation);

      this.rect.height = roundDimension(calcHeight);
      this.rect.width = roundDimension(calcWidth);
      this.rect.left = roundDimension(calcPosition[0]);
      this.rect.top = roundDimension(calcPosition[1]);
    },
    onDragging(event: DragEvent): void {
      this.rect.left = roundDimension(event.x);
      this.rect.top = roundDimension(event.y);
    },
    onResizing(event: ResizeEvent): void {
      this.rect.height = roundDimension(event.height);
      this.rect.width = roundDimension(event.width);
      this.rect.left = roundDimension(event.x);
      this.rect.top = roundDimension(event.y);
    },
    origWidgetRect(): PdfWidgetRect {
      const {height, width, left, top} = this.rect;
      /* Výpočet chybějících souřadnic */
      const calcLeft = left,
            calcTop = top,
            calcRight = this.pageWidth - (width + calcLeft),
            calcBottom = this.pageHeight - (height + calcTop);
      /* Odstranění měřítka a rotace souřadnic do výchozího nastavení (pageRotation = 0) */
      const calcPosition = [calcLeft, calcTop, calcRight, calcBottom].map(value => value / this.pageScale);
      for (let i = 0; i < this.pageRotation / 90; i++)
        calcPosition.push(calcPosition.shift() ?? 0);
      /* Rozměry widgetu po odstranění měřítka a rotace */
      const calcOrigWidgetSize = this.origWidgetSize(height, width);

      return {
        ...calcOrigWidgetSize,
        left: roundDimension(calcPosition[0]),
        bottom: roundDimension(calcPosition[3])
      }
    },
    origWidgetSize(height: number, width: number): PdfRectDims {
      /* Rozměry widgetu po odstranění měřítka a rotace */
      return getRectDims(height / this.pageScale, width / this.pageScale, this.pageRotation);
    },
    updateWidgetRect(): void {
      /*
       * Optimalizace: Aktualizace hodnot se provádí až po dokončení přesunu nebo změny velikosti, aby k výpočtům
       * nedocházelo příliš často. Hodnoty události jsou ignorovány.
       */
      this.localValue = this.origWidgetRect();
    },
    widgetRemove(): void {
      this.$emit('remove');
    },
    widgetRotate(): void {
      // TODO: Realizovat otočení widgetu
      console.log('rotate');
    }
  }
})
