











import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import signatureOnlineContentMixin from "@/mixins/signatureOnlineContentMixin";
import SignatureOnlineDocumentDetail from "@/component/signature/online/SignatureOnlineDocumentDetail.vue";
import SignatureOnlineDocumentInvalid from "@/component/signature/online/SignatureOnlineDocumentInvalid.vue";
import SignatureOnlineIntro from "@/component/signature/online/SignatureOnlineIntro.vue";
import SignatureOnlineRejection from "@/component/signature/online/SignatureOnlineRejection.vue";
import SignatureOnlineRejectionConfirmation
  from "@/component/signature/online/SignatureOnlineRejectionConfirmation.vue";
import SignatureOnlineSuccess from "@/component/signature/online/SignatureOnlineSuccess.vue";
import {SignatureOnlineConfig, SignatureOnlinePhase} from "@/types";

export default mixins(signatureOnlineContentMixin).extend({
  name: 'SignatureOnlineContent',

  components: {
    SignatureOnlineDocumentDetail,
    SignatureOnlineDocumentInvalid,
    SignatureOnlineIntro,
    SignatureOnlineRejection,
    SignatureOnlineRejectionConfirmation,
    SignatureOnlineSuccess
  },

  props: {
    phase: {
      type: String as PropType<SignatureOnlinePhase>,
      required: true
    }
  },

  computed: {
    currentContentConfig(): SignatureOnlineConfig | undefined {
      return this.onlineContentConfig[this.phase];
    }
  }
})
