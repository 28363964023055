<template>
  <div>
    <SLoader v-if="table.loading" class="mx-auto" style="width: 100%"></SLoader>
    <v-container v-else>
      <h5 v-if="table.subtitle">{{ table.subtitle }}</h5>
      <v-divider v-if="table.subtitle && table.header"></v-divider>
      <FlowDetailAuditRow class="table-header" v-if="table.header" :row="table.header"></FlowDetailAuditRow>
      <template v-for="(row, index) in table.rows">
        <v-divider v-if="index !== -1" :key="row"></v-divider>
        <FlowDetailAuditRow :key="row" :row="row">
        </FlowDetailAuditRow>
      </template>
    </v-container>
  </div>
</template>

<script>
import FlowDetailAuditRow from "@/component/workflow/detail/audit/FlowDetailAuditRow";
import SLoader from "@/component/ui/SLoader";

export default {
  name: "FlowDetailAuditTable",
  components: {SLoader, FlowDetailAuditRow},
  props: {
    table: {
      subtitle: String,
      header: Array,
      rows: Array,
      loading: Boolean
    }
  }
}
</script>

<style scoped>
.table-header {
  font-weight: bold;
  background-color: var(--v-secondaryBackground-base);
}
</style>
