

























































import Vue, {PropType} from "vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SDialog from "@/component/ui/SDialog.vue";
import {SignatureMethod} from "@/types";

export type NoSignatureDialogType = "noSuitableProfile" | "noSelectedProfile";

export default Vue.extend({
  name: 'NoSignatureProfileDialog',
  components: {STextButton, SDialog},
  props: {
    value: Boolean,
    dialogType: String as PropType<NoSignatureDialogType>,
    applicableProviderTypes: {
      type: Array as PropType<Array<SignatureMethod>>,
      required: true
    },
    onlineSignature: Boolean,
  },

  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    isNoSuitableProfile(): boolean {
      return this.dialogType === 'noSuitableProfile';
    },
    isNoSelectedProfile(): boolean {
      return this.dialogType === 'noSelectedProfile';
    }
  },

  methods: {
    goToProfile(): void {
      if (this.onlineSignature) {
        const queryObject = Object.assign({...this.$router.currentRoute.query},
            {skipIntro: 'true'}) as Record<string, string>;
        const redirectUri = this.$router.currentRoute.path + "?" + new URLSearchParams(queryObject).toString();

        this.$router.push({
          path: '/profile/signature',
          query: {'online-signature-redirect-uri': redirectUri}
        }).catch(() => {/**/});
      } else {
        this.$router.push('/profile/signature');
      }
    },
  }
})
