import Vue from "vue";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {apiCategoryGet} from "@/api/businessConfigApi";
import {RootState} from "@/store/store";
import {BusinessLevel} from "@/types";
import {SIGNATURE_PHASE} from "@/utils/constants";

interface SignatureState {
  signatureCategories: { [key: string]: Array<BusinessLevel> | undefined };
  signatureLevels: Array<BusinessLevel> | undefined;
  signaturePhase: SIGNATURE_PHASE;
}

const state = (): SignatureState => ({
  signatureCategories: {} as { [key: string]: Array<BusinessLevel> },
  signatureLevels: undefined,
  signaturePhase: SIGNATURE_PHASE.NONE
})

const mutations: MutationTree<SignatureState> = {
  setSignatureCategories(state, payload: { levels: Array<BusinessLevel>, index: string }) {
    Vue.set(state.signatureCategories, payload.index, payload.levels);
  },
  setSignatureLevels(state, payload: { levels: Array<BusinessLevel> }) {
    state.signatureLevels = payload.levels;
  },
  setSignaturePhase(state, payload: { phase: SIGNATURE_PHASE }) {
    state.signaturePhase = payload.phase;
  },
  /*
   * Global events
   */
  addSignatureField() {
    // Empty on purpose, acts as global event
  },
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  cancelAuthorization(state: SignatureState, payload) {
    // Empty on purpose, acts as global event
  },
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  cardAuthorization(state: SignatureState, payload) {
    // Empty on purpose, acts as global event
  },
  validateProfileAndSign() {
    // Empty on purpose, acts as global event
  }
}

const actions: ActionTree<SignatureState, RootState> = {
  async loadSignatureLevels({ commit }, categoryCode): Promise<void> {
    try {
      const category = await apiCategoryGet(categoryCode);
      commit('setSignatureCategories', {
        levels: category._embedded?.['sef:business-levels-for-category'],
        index: categoryCode
      });
    }
    catch (e) {
      console.error(`An error occurred while loading category with code "${categoryCode}"`, e);
    }
  }
}

const getters: GetterTree<SignatureState, RootState> = {
  signatureCategories: (state): { [key: string]: Array<BusinessLevel> | undefined } => {
    return state.signatureCategories;
  },
  signatureLevels: (state): Array<BusinessLevel> | undefined => {
    return state.signatureLevels;
  },
  signaturePhase: (state) => {
    return state.signaturePhase;
  }
}

export const signature: Module<SignatureState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
