import Vue from "vue";
import {Flow, FlowState, InputFlow, Tag, UserAccount} from "@/types";
import {formatDate} from "@/utils/dateUtils";
import {DateTime} from "luxon";
import {AxiosResponse} from "axios";

export default Vue.extend({
    methods: {
        parseDto(dto: InputFlow): InputFlow {
            // Date
            if (dto.dueDate) {
                dto.dueDate = formatDate(new Date(dto.dueDate), 'yyyy-LL-dd')
            }

            // Null checks
            if(!dto._embedded.finalRecipients) dto._embedded.finalRecipients = [];
            if(!dto._embedded.steps) dto._embedded.steps = [];

            // Must be set to null due to reactivity for availableStep computed property´s reactivity
            for (const step of dto._embedded.steps) {
                if (!step.requestedSignatureLevel) {
                    step.requestedSignatureLevel = null;
                }
            }
            return dto;
        },
        createDto(flow: InputFlow, state: FlowState, owner: UserAccount): InputFlow {
            const dto = {...flow};
            dto.state = state;
            // DateFormat
            if (dto.dueDate) {
                // Nastavení termínu podpisu na konec vybraného dne, aby bylo možné podpis řádně provést i v rámci tohoto dne
                dto.dueDate = DateTime.fromISO(dto.dueDate).plus({hours: 23, minutes: 59, seconds: 59}).toISO();
            }
            if(!dto._embedded.document._embedded)  dto._embedded.document._embedded = {tags: []}
            // Tags
            dto._embedded.document._embedded.tags = this.tagsDto(dto._embedded.document._embedded.tags || []);
            // Owner
            if (!dto._embedded.owner) {
                dto._embedded.owner = owner;
            }
            // Signer order
            for (let i = 0; i < dto._embedded.steps.length; i++) {
                const step = dto._embedded.steps[i];
                step.stepOrder = i + 1;
            }
            return dto;
        },
        tagsDto(tags: Array<string|Tag>): Array<Tag> {
            const dto = [];
            for (const tag of tags) {
                if (typeof tag === 'object') {
                    dto.push(tag);
                }
                else {
                    const trimValue = tag.trim();
                    if(trimValue.length === 0 || trimValue.length < 3 || trimValue.length > 15) continue;
                    dto.push({text: trimValue});
                }
            }
            return dto;
        },
        async loadFlow(url: string): Promise<InputFlow> {
            const response = await this.axios.get(url);
            return this.parseDto(response.data);

        },
        submitFlow(link: string, flow: InputFlow, owner: UserAccount): Promise<AxiosResponse<Flow>> {
            return this.axios.put(link, this.createDto(flow, "active", owner));
        },
        updateDraft(link: string, flow: InputFlow, owner: UserAccount): Promise<AxiosResponse<InputFlow>> {
            return this.axios.put(link, this.createDto(flow, "draft", owner));
        },
    }
})
