import {IDENTITY_PROVIDERS} from '@/utils/urls'
import axios from "axios";
import Vue from "vue";
import {IdentityProvidersResponse} from "@/types/dto";

const identityProvidersMixin = Vue.extend({
  methods: {
    async apiGetProviders(): Promise<IdentityProvidersResponse|undefined> {
      try {
        return (await axios.get(IDENTITY_PROVIDERS)).data as IdentityProvidersResponse;
      }
      catch (ignore) {
        return undefined;
      }
    },
    async apiCreateAccountLink(providerCode: string, url: string): Promise<{redirectUrl: string}|undefined> {
      try {
        return (await axios.post(url, {code: providerCode})).data as {redirectUrl: string};
      }
      catch (ignore) {
        return undefined;
      }
    },
    async apiDeleteAccountLink(url: string): Promise<void> {
      try {
        await axios.delete(url);
      }
      catch (ignore) {
        /**/
      }
    }
  }
})

export default identityProvidersMixin;
