<template>
  <v-container class="metadata-view s-scrollbar" v-resize="onResize" :style="mobileView ? 'font-size: 12px' : ''">
    <v-row no-gutters justify="end" class="mx-9">
      <v-col>
        <h2 v-if="!mobileView"> {{ $t('docDetail.metadata.title') }} </h2>
        <h4 v-else> {{ $t('docDetail.metadata.title') }} </h4>
      </v-col>
      <v-col style="text-align: right">
        <div :class="{'text-xlarge': !mobileView, 'text-large': mobileView}">{{ formatDate(flow.changedAt) }}</div>
        {{ $t('docDetail.metadata.lastChange') }}
      </v-col>
    </v-row>
    <div v-for="(stepOrder, index) in formatArrayByStepOrder" :key="index" class="card__byOrderNumber">
      <div v-for="(rowContent, key) in stepOrder" :key="key" class="card__userInformation">
        <MetadataRow :key="rowContent" :row-content="rowContent" :mobile-view="mobileView" />
      </div>
    </div>
    <div v-if="!draft" class="hard-due-date__info">
      <div class="hard-due-date__info-text">
        {{ hardDueDateLabel }} {{ formatDate(flow.dueDate) }}
      </div>
    </div>

  </v-container>
</template>

<script>

import MetadataRow from "@/component/workflow/detail/FlowDetailMetadataRow";
import metadataMixin from "@/mixins/metadataMixin";
import {formatDateShort} from "@/utils/dateUtils";

export default {
  name: 'MetadataView',
  components: {
    MetadataRow
  },
  mixins: [metadataMixin],

  props: {flow: Object},

  data(){
    return {
      elemWidth: undefined
    }
  },

  computed: {
    stepsAsRowContents() {
      let rowContents = [];
      // odeslani flow neni opravdovy krok, ale my ho tak zobrazime
      rowContents.push(this.ownerFirstActionRow(this.flow));
      // transformujeme kroky embedovane ve flow
      let showActiveStep = this.showActiveStep(this.flow);
      let activeStepIndex = this.activeStepIndex(this.flow);
      let transformedSteps = this.flow._embedded.steps.map(
          step => this.stepToRowContent(step, showActiveStep, activeStepIndex))
          // nezname kroky se mapuji na null - nebudeme je zobrazovat
          .filter(step => step !== null);
      // pokud bylo flow odvolano, vlozime za posledni dokonceny krok novy fiktivni krok zadavatele
      if (this.flow.state === 'recalled')
        transformedSteps.splice(this.getLastActionableStepIndex(this.flow), 0, this.ownerRecallRow(this.flow))
      rowContents.push(...transformedSteps);
      return rowContents;
    },
    //Funkce pro vytvoření polí podle stepOrder
    formatArrayByStepOrder(){
      let formatArray = {};
      for(let i = 1; i < this.stepsAsRowContents.length; i++){
        if(!formatArray[this.stepsAsRowContents[i]["stepOrder"]]) formatArray[this.stepsAsRowContents[i]["stepOrder"]] = [];
        formatArray[this.stepsAsRowContents[i]["stepOrder"]].push(this.stepsAsRowContents[i]);
      }
      return formatArray;
    },
    mobileView() {
      return this.elemWidth < 600;
    },
    hardDueDateLabel() {
      let text = " ";
      if (this.flow.dueDateMode === 'SOFT') {
        text = this.$tc('docDetail.metadata.hardDueDateLabelSoft', this.stepsAsRowContents.length - 1);
      }
      else {
        text = this.$tc('docDetail.metadata.hardDueDateLabelHard', this.stepsAsRowContents.length - 1);
      }
      return text;
    },
    draft() {
      return this.flow?.state === 'draft';
    }
  },

  methods: {
    onResize() {
      this.elemWidth = this.$el.clientWidth;
    },
    formatDate(value){
      return formatDateShort(value);
    }
  },

}
</script>

<style scoped>
.metadata-view {
  object-fit: fill;
  overflow-x: hidden;
  overflow-y: auto;
  /*noinspection CssUnresolvedCustomProperty*/
  scrollbar-color: var(--v-scrollbar-base) var(--v-mainBackground-base);
  font-size: 14px;
}

.card__byOrderNumber {
  border-radius: 35px;
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--v-secondaryBackground-base);
  margin-bottom: 1.5%;
}

.card__userInformation {
  padding-bottom: 2px;
  padding-top: 2px;
  margin-bottom: -10px;
}

.hard-due-date__info {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 1%;
}

.hard-due-date__info-text {
  padding-top: 2px;
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--v-mutedContrast-base);
}

</style>
