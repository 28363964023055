import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import {DisplayVariant} from "@/types";

export default mixins(displayMixin).extend({
  props: {
    mobileBreakpoint: [Number, String] as PropType<number | 'xs' | 'sm' | 'md' | 'lg'>
  },
  computed: {
    mobile(): boolean {
      if (!this.mobileBreakpoint) {
        return this.displayMobile;
      }
      else if (this.forcedDisplay) {
        return this.forcedDisplay === DisplayVariant.MOBILE;
      }
      else {
        const {width} = this.$vuetify.breakpoint;
        if (typeof this.mobileBreakpoint === 'number')
          return width < this.mobileBreakpoint;
        else
          return width < this.$vuetify.breakpoint.thresholds[this.mobileBreakpoint];
      }
    }
  }
})
