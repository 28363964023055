import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {apiAboutGet} from "@/api/appDetailsApi";
import {RootState} from "@/store/store";
import {AppAbout} from "@/types";

interface AppDetailsState {
  about: AppAbout | undefined;
}

const state = (): AppDetailsState => ({
  about: undefined
})

const mutations: MutationTree<AppDetailsState> = {
  setAbout(state, payload: AppAbout) {
    state.about = payload;
  }
}

const actions: ActionTree<AppDetailsState, RootState> = {
  async loadAppDetails({ commit }): Promise<void> {
    try {
      const response = await apiAboutGet();
      commit('setAbout', response);
    }
    catch (e) {
      console.error('An error occurred while loading application details', e);
    }
  }
}

const getters: GetterTree<AppDetailsState, RootState> = {
  applicationVersion: (state): string | undefined => {
    return state.about?.applicationVersion;
  }
}

export const appDetails: Module<AppDetailsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
