













import Vue, {PropType} from "vue";
import STextField from "@/component/ui/form/STextField.vue";
import {SignatureProfile} from "@/types";

export default Vue.extend({
  name: 'ExternalIdFeature',
  components: {STextField},
  props: {
    item: {
      type: Object as PropType<SignatureProfile>,
      required: true
    },
    titleI18n: {
      type: String,
      required: true
    }
  },
  methods: {
    clear(): void {
      // nothing here
    },
    valid(): boolean {
      return true;
    }
  }
})
