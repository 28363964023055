import Vue from "vue";

export default Vue.extend({
  props: {
    validations: [String, Object],
    vid: String // identifikátor pole v rámci validačního mechanismu
  },
  methods: {
    isRequired(): boolean {
      if (this.validations) {
        if (typeof this.validations === 'object')
          return this.validations.required;
        else
          return this.validations.includes('required');
      }
      else
        return false;
    }
  }
})
