import Vue from "vue";
import Vuetify from "vuetify/lib";
import {dark, light} from "@/plugin/vuetifyTheme";
import {FONT_AWESOME_ICONS} from "@/plugin/fontAwesome";
import {i18n} from "@/plugin/i18n";

Vue.use(Vuetify)

//Custom font

export const vuetify = new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: FONT_AWESOME_ICONS
  },
  lang: {
    t: (key: string, ...params: Array<string | number>) => i18n.t(key, params).toString()
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: light,
      dark: dark
    }
  }
})
