




















import Vue, {PropType} from "vue";
import SImg from "@/component/ui/SImg.vue";
import {IMAGE_DATA_URI_PREFIX} from "@/utils/constants";
import {Visualisation} from "@/types";

export default Vue.extend({
  name: 'VisualisationSignature',

  components: {SImg},

  props: {
    height: String,
    visualisation: {
      type: Object as PropType<Visualisation>,
      required: true
    },
    width: String
  },

  computed: {
    logoImageSource(): string | undefined {
      if (this.visualisation?.logo)
        return IMAGE_DATA_URI_PREFIX + this.visualisation.logo;
      else
        return undefined;
    },
    logoStyle(): Record<string, string | undefined> {
      const background = this.logoImageSource ? `url(${this.logoImageSource}) center/contain no-repeat` : undefined;

      return {
        background,
        minHeight: '100%',
        minWidth: '100%'
      };
    },
    signatureImageSource(): string | undefined {
      if (this.visualisation?.signatureImage)
        return IMAGE_DATA_URI_PREFIX + this.visualisation.signatureImage;
      else
        return undefined;
    },
    visualisationHeight(): string {
      return this.height || 'auto';
    },
    visualisationWidth(): string {
      return this.width || 'auto';
    },
    visualisationText(): string | undefined {
      if (!this.visualisation)
        return undefined;
      else if (this.visualisation.signingText)
        return this.visualisation.signingText;
      return undefined;
    }
  }
})
