











import FlowDetail from "@/component/workflow/detail/FlowDetail.vue";
import Vue, {PropType} from "vue";
import {Flow} from "@/types";

export default Vue.extend({
  name: 'FlowDetailDesktop',
  components: {FlowDetail},
  props: {
    flow: Object as PropType<Flow>,
    standalone: Boolean,
    customization: Object
  },
  computed: {
    flowUrl(): string|undefined {
      return this.flow ? this.flow._links.self.href : undefined;
    }
  }
})
