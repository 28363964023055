






















import Vue from "vue";
import {PdfScaleOption} from "@/types";

export default Vue.extend({
  name: 'PDFToolbarScaleSelect',

  props: {
    value: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      customScaleOption: {
        custom: true,
        value: ''
      } as PdfScaleOption,
      customScaleOptionActive: false, // příznak aktivní dynamické volby
      scaleOptions: [
        {
          textKey: 'pdfViewer.scale.auto',
          value: 'auto'
        },
        {
          textKey: 'pdfViewer.scale.pageActual',
          value: 'page-actual'
        },
        {
          textKey: 'pdfViewer.scale.pageFit',
          value: 'page-fit'
        },
        {
          textKey: 'pdfViewer.scale.pageWidth',
          value: 'page-width'
        },
        {
          value: '0.5'
        },
        {
          value: '0.75'
        },
        {
          value: '1'
        },
        {
          value: '1.25'
        },
        {
          value: '1.5'
        },
        {
          value: '2'
        },
        {
          value: '3'
        },
        {
          value: '4'
        }
      ] as Array<PdfScaleOption>
    }
  },

  computed: {
    localValue: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    value(value: string): void {
      const selectedOption = this.scaleOptions.find(option => option.value === value);
      const customOptionIndex = this.scaleOptions.findIndex(option => option.custom);
      if (!selectedOption) {
        if (customOptionIndex > -1) {
          // Aktualizace hodnoty dynamické volby
          this.$set(this.scaleOptions[customOptionIndex], 'value', value);
        }
        else {
          // Vložení dynamické volby s aktuální hodnotou
          this.scaleOptions.push({
            ...this.customScaleOption,
            value
          });
          this.customScaleOptionActive = true;
        }
      }
      else if (!selectedOption.custom && customOptionIndex > -1) {
        // Vybrána je předdefinovaná volba, dynamická volba je odstraněna
        this.scaleOptions.splice(customOptionIndex, 1);
        this.customScaleOptionActive = false;
      }
    }
  },

  methods: {
    displayScaleOption(item: PdfScaleOption): string | null {
      if (item.textKey)
        return this.$t(item.textKey).toString();
      else if (item.value)
        return this.$tc('pdfViewer.scale.value', Math.round(Number(item.value) * 100)).toString();
      else
        return null;
    }
  }
})
