















































































import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import signatureOnlineMixin from "@/mixins/signatureOnlineMixin";
import {apiFlowStepsReject} from "@/api/flowStepsApi";
import {apiWorkflowGet, apiWorkflowsRecall} from "@/api/workflowsApi";
import AccountInfo from "@/component/AccountInfo.vue";
import CenteredPanelLayout from "@/component/layouts/CenteredPanelLayout.vue";
import SignatureContentAlert from "@/component/signature/SignatureContentAlert.vue";
import SignatureContentLogo from "@/component/signature/SignatureContentLogo.vue";
import SignatureOnlineContent from "@/component/signature/online/SignatureOnlineContent.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import {
  SignatureOnlinePhase,
  SignatureOnlineRejectionPhase,
} from "@/types";
import {isStepActive} from "@/utils/flowUtils";

export default mixins(displayMixin, signatureOnlineMixin).extend({
  name: 'SignatureOnlineView',

  components: {
    AccountInfo,
    CenteredPanelLayout,
    SIconButton,
    SignatureContentAlert,
    SignatureContentLogo,
    SignatureOnlineContent
  },

  data() {
    return {
      phase: undefined as SignatureOnlinePhase | undefined,
      phaseOrig: undefined as SignatureOnlinePhase | undefined,
    }
  },

  computed: {
    flowRecallAvailable(): boolean {
      return this.flowActive && this.flowOwner;
    },
    phaseDocumentDetail(): boolean {
      return this.phase === SignatureOnlinePhase.DOCUMENT_DETAIL;
    }
  },

  created() {
    this.loadFlow();
  },

  methods: {
    cancel(): void {
      this.rejection(SignatureOnlineRejectionPhase.CONFIRMATION);
    },
    changePhase(phase: SignatureOnlinePhase): void {
      this.phase = phase;
    },
    async loadFlow(setPhase = true): Promise<void> {
      if (!this.flowId || !this.flowStepId)
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;

      try {
        this.flow = await apiWorkflowGet(this.flowId);
        if (setPhase)
          this.onFlowLoaded();
      }
      catch (e) {
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;
      }
    },
    onFlowLoaded(): void {
      if (!this.step || !this.flow)
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;
      // 'finished' je jediná situace, kdy chceme říkat "úspěšně jste podepsali"
      else if (this.step.state === 'finished')
        this.phase = SignatureOnlinePhase.SUCCESS;
      // kromě 'finished' vyřazeného výše zahrnují neaktivní kroky i 'rejected' a 'inactive' (recalled flow nebo rejectnutý jiný krok)
      else if (!isStepActive(this.step, this.flow))
        this.phase = SignatureOnlinePhase.DOCUMENT_INVALID;
      else
        this.phase = this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO;
    },
    async onFlowRejected(): Promise<void> {
      if (this.redirectUri)
        this.redirectToSystem(true);
      else {
        this.phase = SignatureOnlinePhase.REJECTION_CONFIRMATION;
        await this.loadFlow(false);
      }
    },
    async rejection(rejectionPhase: SignatureOnlineRejectionPhase, rejectionReason?: string): Promise<void> {
      switch (rejectionPhase) {
        case SignatureOnlineRejectionPhase.CANCEL:
          this.phase = this.phaseOrig;
          break;
        case SignatureOnlineRejectionPhase.CONFIRMATION:
          if (this.phase !== SignatureOnlinePhase.REJECTION)
            this.phaseOrig = this.phase;
          this.phase = SignatureOnlinePhase.REJECTION;
          break;
        case SignatureOnlineRejectionPhase.CONFIRMED:
          if (this.flowRecallAvailable) {
            // Odvolání
            if (this.flowId) {
              try {
                const data = await apiWorkflowsRecall([this.flowId], rejectionReason);
                if (data.succeeded[0] === this.flowId)
                  await this.onFlowRejected();
                else
                  console.error(`An incorrect flow ${data.succeeded[0]} has been recalled`);
              }
              catch (e) {
                console.error(`An error occurred while recalling the flow ${this.flowId}`, e);
              }
            }
            else
              console.warn('The flow cannot be recalled due to a missing identifier');
          }
          else {
            // Odmítnutí
            if (this.flowStepId) {
              try {
                const data = await apiFlowStepsReject([this.flowStepId], rejectionReason);
                if (data.succeeded[0] === this.flowStepId)
                  await this.onFlowRejected();
                else
                  console.error(`An incorrect step ${data.succeeded[0]} has been rejected`);
              }
              catch (e) {
                console.error(`An error occurred while rejecting the step ${this.flowStepId}`, e);
              }
            }
            else
              console.warn('The step cannot be rejected due to a missing identifier');
          }
          break;
      }
    },
    success(): void {
      if (this.skipConfirmation && this.redirectUri) {
        this.redirectToSystem(true);
      }
      else {
        this.changePhase(SignatureOnlinePhase.SUCCESS);
      }
    }
  }
})
