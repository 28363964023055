






















































































import debounce from "lodash/debounce";
import {AUTOCOMPLETE, CREATE_USER} from "@/utils/urls";
import {ACCOUNT_TYPE, AUTOCOMPLETE_MIN_LENGTH} from "@/utils/constants";
import SLoader from "@/component/ui/SLoader.vue";
import {nameToDisplay} from "@/utils/accountUtils";
import {PropType} from "vue";
import {UserAccount} from "@/types";
import {AccountAutocompleteResponse} from "@/types/dto";
import displayMixin from "@/mixins/displayMixin";
import mixins from "vue-typed-mixins";

interface Blurable {
  blur: () => void;
}

export default mixins(displayMixin).extend({
  name: 'SignerSearch',
  components: {SLoader},

  props: {
    allowExternalUsers: Boolean,
    enableExternalUserCreation: Boolean,
    label: String,
    signers: {
      type: Array as PropType<UserAccount[]>,
      required: false
    },
    unique: Boolean,
  },

  data() {
    return {
      selected: undefined as Array<UserAccount>|undefined,
      loading: false,
      externalUserLoading: false,
      externalUserError: false,
      search: '' as string,
      users: [] as Array<UserAccount>,
      loadUsersDebouncedFunc: () => {/**/},
      emailRegex: /[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{1,63}/,
      isFocused: false
    }
  },

  computed: {
    validEmailAddress(): boolean {
      return this.emailRegex.test(this.search);
    }
  },

  methods: {

    async loadUsers(): Promise<void> {
      if (!this.search || this.search.length < AUTOCOMPLETE_MIN_LENGTH) {
        this.users = [];
        return;
      }
      if (this.loading) return;
      this.loading = true;

      try {
        let url = AUTOCOMPLETE + '?text=' + encodeURIComponent(this.search) +
            '&include-accounts=true&include-tags=false&include-subjects=false&include-external-accounts='
            + this.allowExternalUsers;
        let response = (await this.axios.get(url)).data as AccountAutocompleteResponse;
        if (this.unique) {
          this.users = response._embedded.accounts.filter(u => !this.isSignerPresent(u));
        } else {
          this.users = response._embedded.accounts;
        }
        this.users.sort((a, b) => {
          if ((this.isExternal(a) && this.isExternal(b)) || (!this.isExternal(a) && !this.isExternal(b))) {
            return 0;
          } else if (this.isExternal(a) && !this.isExternal(b)){
            return 1;
          } else {
            return -1;
          }
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    isSignerPresent(signer: UserAccount): boolean {
      if (!this.signers) return false;
      return this.signers.findIndex(s => s.accountName === signer.accountName) !== -1;
    },

    isExternal(account: UserAccount): boolean {
      return account.accountType === ACCOUNT_TYPE.EXTERNAL;
    },

    onSelect(item: UserAccount) {
      if(item) {
        this.$emit('add', item);
      }
      this.clear();
      if(!this.displayDesktop) this.blur();
    },

    async createExternalUser(): Promise<void> {
      if (!this.validEmailAddress) return;
      this.externalUserError = false;
      let item;
      try {
        this.externalUserLoading = true;
        item = (await this.axios.post(CREATE_USER, {
          userEmail: this.search,
          accountType: ACCOUNT_TYPE.EXTERNAL
        })).data as UserAccount;
        this.externalUserLoading = false;
      } catch (e) {
        this.externalUserLoading = false;
        return;
      }
      this.$emit('add', item);
      this.clear();
    },

    clear() {
      this.search = '';
      this.selected = [];
      this.users = [];
    },

    name(account: UserAccount) {
      return nameToDisplay(account);
    },

    blur(): void {
      (this.$refs.autocomplete as unknown as Blurable).blur();
      this.onBlur();
    },

    onFocus(): void {
      this.isFocused = true;
      this.clear();
      this.$emit('focus');
    },

    onBlur(): void {
      this.isFocused = false;
      this.$emit('blur');
    }

  },

  watch: {
    search(value) {
      if (!value || value.length < AUTOCOMPLETE_MIN_LENGTH) {
        return
      }
      this.loadUsersDebouncedFunc();
    }
  },

  created(){
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let vm = this;
    this.loadUsersDebouncedFunc = debounce(function () {
      vm.loadUsers();
    }, 200)
  }

})
