























import Vue from "vue";

export default Vue.extend({
  name: "SRemoveBadge",
  props: {
    disabled: Boolean,
    size: {
      type: Number,
      default: 20
    }
  }
})
