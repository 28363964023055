import Vue from 'vue'
import Router, {NavigationGuardNext, Route} from 'vue-router'
import store from "@/store/store";
import {ACCOUNT_TYPE} from "@/utils/constants";
import IdentityProviders from "@/component/identityProviders/IdentityProviders.vue";
import PersonalInfo from "@/component/profile/info/PersonalInfo.vue";
import SignatureMethodList from "@/component/profile/signatures/SignatureMethodList.vue";
import SignatureProfilesOverview from "@/component/profile/signatures/SignatureProfilesOverview.vue";
import UserActions from "@/component/profile/UserActions.vue";
import UserProfileView from "@/view/UserProfileView.vue";
import VisualisationDetail from "@/component/profile/visualisation/VisualisationDetail.vue";
import VisualisationList from "@/component/profile/visualisation/VisualisationList.vue";
import ContentView from "@/view/ContentView.vue";
import SupportView from "@/component/profile/support/SupportView.vue";
import NotificationsView from "@/view/NotificationsView.vue";
import SignatureViewResolver from "@/view/SignatureViewResolver.vue";

Vue.use(Router)

async function isExternalUser(): Promise<boolean> {
  return new Promise(resolve => {
    const accountType = store.getters['profile/accountType']
    if (accountType) {
      resolve(store.getters['profile/accountType'] === ACCOUNT_TYPE.EXTERNAL);
    } else {
      const unsubscribe = store.subscribe((mutation) => {
        if (mutation.type === 'profile/setPersonalInfo') {
          unsubscribe();
          resolve(mutation.payload.info.accountType === ACCOUNT_TYPE.EXTERNAL);
        }
      });
    }
  })
}

const checkUserRights = (to: Route, from: Route, next: NavigationGuardNext) => {
  isExternalUser().then(isExternal => {
    isExternal ? next(false) : next();
  })
}

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/overview'
    },
    {
      path: '/overview',
      component: ContentView,
      beforeEnter: (to, from, next) => checkUserRights(to, from, next)
    },
    {
      path: '/overview/:folderId',
      component: ContentView,
      props: true,
      beforeEnter: (to, from, next) => checkUserRights(to, from, next)
    },
    {
      path: '/profile',
      component: UserProfileView,
      children: [
        {
          path: 'info',
          components: {
            main: PersonalInfo,
            detail: UserActions
          },
          meta: {
            title: 'profile.personalInfo.headline'
          }
        },
        {
          path: 'visualisation',
          name: 'visualisation',
          components: {
            main: VisualisationList,
            detail: VisualisationDetail
          },
          meta: {
            title: 'profile.visualisation.headline'
          },
          props: {main: {routingToNew: false}}
        },
        {
          path: 'visualisation',
          name: 'visualisation-new',
          components: {
            main: VisualisationList,
            detail: VisualisationDetail
          },
          meta: {
            title: 'profile.visualisation.headline'
          },
          props: {main: {routingToNew: true}}
        },
        {
          path: 'signature',
          components: {
            main: SignatureMethodList,
            detail: SignatureProfilesOverview
          },
          meta: {
            title: 'profile.signature.headline'
          }
        },
        {
          path: 'identities',
          components: {
            main: IdentityProviders
          },
          meta: {
            layout: 'single',
            title: 'profile.externalIdentities.title'
          },
          beforeEnter: (to, from, next) => checkUserRights(to, from, next)
        },
        {
          path: 'support',
          name: 'profile-support',
          components: {
            main: SupportView
          },
          meta: {
            layout: 'single',
            title: 'profile.support.title'
          },
          beforeEnter: (to, from, next) => checkUserRights(to, from, next)
        }
      ]
    },
    {
      path: '/notifications',
      redirect: '/notifications/processing'
    },
    {
      path: '/notifications/:folderId',
      component: NotificationsView,
      props: true,
      beforeEnter: (to, from, next) => checkUserRights(to, from, next)
    },
    {
      path: '/online-signature',
      component: SignatureViewResolver,
      props: route => ({
        flowId: route.query.flowId,
        flowStepId: route.query.stepId,
        skipIntro: route.query.skipIntro,
        skipConfirmation: route.query.skipConfirmation,
        redirectUri: route.query.redirectUri
      })
    }
  ]
})
