<template>
  <div
      class="detail-header"
      :style="style"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FlowDetailHeader',
  props: {
    backgroundColor: String
  },
  computed: {
    style() {
      return {
        backgroundColor: this.backgroundColor || 'transparent'
      };
    }
  }
}
</script>

<style scoped>

.detail-header {
  align-items: center;
  display: flex;
  height: 75px;
}

</style>
