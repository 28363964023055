<template>
  <v-row class="audit-row" dense>
    <v-col v-for="column in row.columns" :key="column">
      <div class="text-small">
        {{ column.content }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FlowDetailAuditRow",
  props: {
    row: {
      columns: Array
    }
  }
}
</script>

<style scoped>

.audit-row {
  margin: 0;
}
</style>