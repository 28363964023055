












































import interactionCenterBatchMixin from "@/mixins/interactionCenterBatchMixin";
import InteractionCenterBatchReportRows from "@/component/notifications/list/InteractionCenterBatchReportRows.vue";
import SProgressLinear from "@/component/ui/SProgressLinear.vue";
import SProgressStacked from "@/component/ui/SProgressStacked.vue";
import mixins from "vue-typed-mixins";
import STooltip from "@/component/ui/STooltip.vue";

export default mixins(interactionCenterBatchMixin).extend({
  name: 'InteractionCenterBatchProcessing',
  components: {STooltip, InteractionCenterBatchReportRows, SProgressLinear, SProgressStacked},
  mixins: [interactionCenterBatchMixin],

  props: {
    mobileView: Boolean
  },

  data() {
    return {
      colors: ['mutedText', 'mutedContrast', 'secondaryContrast', 'mainContrast']
    };
  },

  computed: {
    processingDoneColor(): string {
      let index = this.processingStats.findIndex(stateCount => stateCount === 1);
      return this.colors[index];
    },
  }
})
