







































import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import displayMixin from "@/mixins/displayMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {PropType} from "vue";
import {FlowEnclosure, isDataEnclosure} from "@/types";
import {downloadBlob} from "@/utils/utils";
import {AxiosResponse} from "axios";

export default mixins(mutationMixin, displayMixin).extend({
  name: 'FlowEnclosureItem',
  components: {STextButton},
  props: {
    enclosure: {
      type: Object as PropType<FlowEnclosure>,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    downloadEnclosureData(enclosure: FlowEnclosure) {
      if (isDataEnclosure(enclosure) && enclosure._links['sef:download-enclosure-data']) {
        this.axios.get(enclosure._links['sef:download-enclosure-data'].href, {
          responseType: 'blob'
        }).then((response: AxiosResponse) => {
          downloadBlob(new Blob([response.data]), enclosure.filename);
        })
      }
    },
  }
});
