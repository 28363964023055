




































import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";

export default mixins(displayMixin).extend({
  name: 'NotificationsHeader',
  props: {
    title: String,
    color: {
      type: String,
      default: 'transparent'
    },
  },
  methods: {
    navOpen() {
      this.$emit('nav-open');
    }
  }
})
