import { render, staticRenderFns } from "./ProfileBatchItem.vue?vue&type=template&id=75fa0973&scoped=true&"
import script from "./ProfileBatchItem.vue?vue&type=script&lang=ts&"
export * from "./ProfileBatchItem.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileBatchItem.vue?vue&type=style&index=0&id=75fa0973&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75fa0973",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VIcon,VListItem,VListItemAction,VListItemContent})
