import axios from "@/plugin/vueAxios";
import {Batch, BatchCreationMode} from "@/types";

export const BATCHES_PATH = '/batches'

async function apiBatchPost(stepIds: Array<number>, batchName: string | undefined, creationMode: BatchCreationMode,
                            bulkMode = true, signingTextName: string | undefined = undefined,
                            signatureImage: string | undefined = undefined): Promise<Batch> {
  const steps = stepIds.map(stepId => ({ stepId: stepId }));
  const response = await axios.post(BATCHES_PATH, {
    _embedded: {
      steps: steps
    },
    batchName,
    bulkMode,
    creationMode,
    signatureImage,
    signingTextName
  });
  return response.data as Batch;
}

export async function apiBatchCreate(stepIds: Array<number>, batchName: string, signatureImage: string | undefined = undefined): Promise<Batch> {
  return apiBatchPost(stepIds, batchName, BatchCreationMode.IGNORE_ERRORS, true, undefined, signatureImage);
}

export async function apiBatchGet(batchId: number): Promise<Batch> {
  const response = await axios.get(`${BATCHES_PATH}/${batchId}`);
  return response.data as Batch;
}

export async function apiDocumentSign(stepId: number, signingTextName: string | undefined = undefined,
                                      signatureImage: string | undefined = undefined): Promise<Batch> {
  return apiBatchPost([stepId], undefined, BatchCreationMode.WITHOUT_ERRORS_ONLY, false, signingTextName,
      signatureImage);
}