import Keycloak, {KeycloakConfig} from "keycloak-js";
import config from "../config"

const keycloakInitOptions = {
    url: config.authUrl,
    realm: config.authRealm,
    clientId: config.clientId
} as KeycloakConfig

export const keycloak = new Keycloak(keycloakInitOptions);
