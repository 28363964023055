import {Flow, FlowStep} from "@/types";

export function getStepActive(flow: Flow): FlowStep | undefined {
  if (!flow)
    return undefined;

  const myActiveStepId = flow.myActiveStepId;
  return flow._embedded.steps.find(step => step.stepId === myActiveStepId);
}

export function isCurrentUserActiveSigner(flow: Flow): boolean {
  return flow.stepState !== undefined;
}

export function isStepActive(step: FlowStep, flow: Flow): boolean {
  const stepActive = getStepActive(flow);
  return !!stepActive && stepActive.stepId === step.stepId;
}