import {GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";
import {DisplayVariant} from "@/types";

interface DisplayState {
  forcedDisplay: DisplayVariant | undefined
}

const state = (): DisplayState => ({
  forcedDisplay: undefined
})

const mutations: MutationTree<DisplayState> = {
  setForcedDisplay(state, payload: DisplayVariant | undefined) {
    state.forcedDisplay = payload;
  }
}

const getters: GetterTree<DisplayState, RootState> = {
  forcedDisplay: (state): DisplayVariant | undefined => {
    return state.forcedDisplay;
  }
}

export const display:Module<DisplayState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters
};