import Vue from "vue";
import {redirectTo} from "@/utils/utils";
import {RedirectResponse} from "@/types/dto";

function buildOnlineSignatureRedirectUri(): string {
  let url = window.location.href;

  if (url.indexOf('?') > -1) {
    if (url.indexOf('skipIntro=') > -1) {
      if (url.indexOf('skipIntro=false') > -1) {
        url = url.replace('skipIntro=false', 'skipIntro=true');
      }
    }
    else {
      url += '&skipIntro=true'
    }
  }
  else {
    url += '?skipIntro=true'
  }

  return url;
}

export default Vue.extend({
  methods: {
    async abandonAuthorization(abandonAuthorizationLink: string): Promise<void> {
      const batchId = abandonAuthorizationLink.split('/')[2];
      await this.axios.post(abandonAuthorizationLink, {
        batchId,
        profileIds: []  // Empty array canceles all authorizations in batch (enough for single signature)
      });
    },
    async authorizationRedirect(authorizationLink: string, onlineSignature: boolean): Promise<void> {
      const redirectUri = onlineSignature ? buildOnlineSignatureRedirectUri() : window.location.href;
      const response = await this.axios.get(authorizationLink + `&redirectUri=${encodeURIComponent(redirectUri)}`);
      const authorizationRedirect = response.data as RedirectResponse;
      redirectTo(authorizationRedirect.redirectUri, false);
    }
  }
})
