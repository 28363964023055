import {formatDate} from "@/utils/dateUtils";
import {nameToDisplay, nameWithEmailToDisplay} from "@/utils/accountUtils";

export default {
    methods: {
        auditSections(flow) {
            let sections = [
                ['document',
                    ['documentInfo', this.documentInfoTables(flow)],
                    ['documentLog', this.isDocumentLogRecordAvailable(flow)]],
                ['flow',
                    ['flowInfo', this.flowInfoTables(flow)],
                    ['flowLog', this.logReportTables(flow.logRecords)]],
                ['flowUsersInfo',
                    [null, this.usersInfoTables(flow)]],
                ['flowStepsInfo', ...flow._embedded.steps
                    .filter(step => step.stepType === "signature" && step.state !== 'new' && step.state !== 'inactive')
                    .map(step => [{text: nameToDisplay(step._embedded.account)}, this.flowStepInfoTables(step)])],
                ['otherProcessing',
                    [null, this.otherProcessingTables(flow)]]
            ];
            return sections.map(section => {
                let title = section.splice(0, 1)[0];
                let getName = (name, subsection) => {
                    if (!subsection || typeof (subsection) === 'string')
                        return this.$t('docDetail.audit.sections.' + name +
                            (subsection ? ('.subsections.' + subsection) : '.name'));
                    else if (typeof (subsection) === 'object')
                        return subsection.text;
                }
                return {
                    title: getName(title),
                    subsections: section.map(subsection => {
                            return {
                                title: subsection[0] ? getName(title, subsection[0]) : null,
                                tables: subsection[1]
                            }
                        }
                    )
                };
            });
        },
        documentInfoTables(flow) {
            return [{
                header: this.defaultHeader(),
                rows: this.keyValueRows('docDetail.audit.sections.document.infoTable', [
                    ['name', this.isDocumentDataAvailable(flow._embedded?.document?.documentName)],
                    ['format', 'PDF'],
                    ['documentId', this.isDocumentDataAvailable(flow._embedded?.document?.id)],
                    // taken from flow, since document does not carry this
                    ['category', flow._embedded.category.categoryCode]
                ])
            }]
        },
        flowInfoTables(flow) {
            return [{
                header: this.defaultHeader(),
                rows: this.keyValueRows('docDetail.audit.sections.flow.infoTable', [
                    ['flowId', flow.flowId],
                    ['flowCreationDateTime', this.formatDateWithTime(flow.createdAt)],
                    ['flowFinalDueDate', this.formatDateWithTime(flow.dueDate)]
                ])
            }];
        },
        usersInfoTables(flow) {
            if (!flow._embedded.steps)
                return [];
            else
                return flow._embedded.steps
                    .filter(step => step.stepType === "signature")
                    .map(step => {
                        return {
                            subtitle: nameToDisplay(step._embedded.account),
                            header: this.defaultHeader(),
                            rows: this.keyValueRows('docDetail.audit.sections.flowUsersInfo.infoTable', [
                                ['email', step._embedded.account.userEmail],
                                ['requiredLevel', step.requestedSignatureLevel?.description]
                            ])
                        }
                    });
        },
        flowStepInfoTables(step) {
            return [{
                subtitle: this.$t('docDetail.audit.sections.flowStepsInfo.stepInfo'),
                header: this.defaultHeader(),
                rows: this.keyValueRows('docDetail.audit.sections.flowStepsInfo.infoTable', [
                    ['email', step._embedded.account.userEmail],
                    ['userId', step._embedded.account.accountName],
                    ['requiredLevel', step.requestedSignatureLevel?.description],
                    ['signatureDateTime', step.state !== 'active' ? this.formatDateWithTime(step.changedAt) : ''],
                    ['signatureResultState', this.stepResultState(step)],
                    ['signatureInstrument', step.signingMethodName]
                ])
            }].concat(this.logReportTables(step.logRecords));
        },
        otherProcessingTables(flow) {
            return [
                {
                    subtitle: this.$t('docDetail.audit.sections.otherProcessing.seal'),
                    header: this.defaultHeader(),
                    rows: this.keyValueRows('docDetail.audit.sections.otherProcessing', [
                        ['used', this.isDocumentAvailableForMapYesOrNo(flow._embedded?.document?.sealedAt)],
                        // ['dateTime', /*this.formatDateWithTime(flow._embedded.document.sealedAt)*/],
                        // ['certificateSerialNumber',]
                    ])
                },
                {
                    subtitle: this.$t('docDetail.audit.sections.otherProcessing.timestamps'),
                    header: this.defaultHeader(),
                    rows: this.keyValueRows('docDetail.audit.sections.otherProcessing', [
                        ['used', this.isDocumentAvailableForMapYesOrNo(flow._embedded?.document?.timestampedAt)],
                        // ['dateTime', /*this.formatDateWithTime(flow._embedded.document.timestampedAt)*/],
                        // ['qualified',]
                    ])
                },
                {
                    subtitle: this.$t('docDetail.audit.sections.otherProcessing.archiving'),
                    header: this.defaultHeader(),
                    rows: this.keyValueRows('docDetail.audit.sections.otherProcessing', [
                        ['archived', this.isDocumentAvailableForMapYesOrNo(flow._embedded?.document?.archivedAt)],
                        ['dateTime', this.isDocumentArchivedAtAvailable(flow)]
                    ])
                }
            ];
        },

        logReportTables(logRecords) {
            if (!logRecords)
                return [{loading: true}];
            let grouped = new Map();
            logRecords.forEach(record => {
                if (grouped.has(record.eventName))
                    grouped.get(record.eventName).push(record);
                else
                    grouped.set(record.eventName, [record]);
            });
            let tables = [];
            grouped.forEach((logRecord, eventName) => {
                // Z api chodi nejnovejsi jako prvni, my vsak chceme nejnovejsi jako posledni
                let reversedLogRecord = [];
                for (let i = logRecord.length - 1; i >= 0; i--) {
                    reversedLogRecord.push(logRecord[i]);
                }
                tables.push({
                    subtitle: eventName,
                    header: this.localizedRow('docDetail.audit.sections.documentLog',
                        'eventTimestamp', 'eventName', 'userName'),
                    rows: reversedLogRecord.map(record =>
                        this.defaultRow(this.formatDateWithTime(record.eventTimestamp), record.logMessage,
                            nameWithEmailToDisplay(record._embedded.account)))
                });
            })
            return tables;
        },

        logReportTablesDeleteDocument(){
            let tables = [];
            tables.push({
                subtitle: this.$t('docDetail.audit.sections.otherProcessing.deleteDocument')
            })
            return tables;
        },

        defaultColumn(content) {
            return {
                content: content
            }
        },
        defaultRow(...columnValues) {
            return {
                columns: columnValues.map(value => this.defaultColumn(value))
            }
        },
        localizedRow(prefix, ...columnValues) {
            return this.defaultRow(...columnValues.map(value => this.$t(prefix + "." + value)));
        },
        keyValueRow(key, value) {
            return this.defaultRow(this.$t(key), value);
        },
        keyValueRows(prefix, pairs) {
            return pairs.map(pair => this.keyValueRow(prefix + "." + pair[0], pair[1]));
        },
        defaultHeader() {
            return this.localizedRow('docDetail.audit', 'item', 'value');
        },
        formatDateWithTime(datetime) {
            return datetime ? formatDate(datetime, 'd. L. yyyy HH:mm:ss') : '';
        },
        stepResultState(step) {
            let result = null;
            switch (step.state) {
                case 'processing':
                case 'active':
                    result = null;
                    break;
                case 'finished':
                    result = 'signed';
                    break;
                case 'rejected':
                    result = 'rejected';
                    break;
                case 'error':
                    result = 'error';
                    break;
                case 'expired':
                    result = 'expired'
            }
            return result ? this.$t('docDetail.audit.sections.flowStepsInfo.infoTable.signatureResultStates.' + result) : '';
        },
        mapYesNo(obj) {
            return obj ? this.$t('docDetail.audit.sections.otherProcessing.affirmative') : this.$t('docDetail.audit.sections.otherProcessing.negative');
        },
        isDocumentDataAvailable(flow) {
            if(flow != null || flow != undefined){
                return flow;
            }else{
                return this.$t('docDetail.audit.sections.otherProcessing.deleteDocument');
            }
        },
        isDocumentLogRecordAvailable(flow) {
            if(flow._embedded?.document?.logRecords != null || flow._embedded?.document?.logRecords != undefined){
                return this.logReportTables(flow._embedded.document.logRecords)
            }else{
                return this.logReportTablesDeleteDocument();
            }
        },
        isDocumentArchivedAtAvailable(flow) {
            if(flow._embedded?.document?.archivedAt != null || flow._embedded?.document?.archivedAt != undefined){
                return this.formatDateWithTime(flow._embedded.document.archivedAt)
            }else{
                return " ";
            }
        },
        isDocumentAvailableForMapYesOrNo(flow){
            if(flow != null || flow != undefined){
                return this.mapYesNo(true);
            }else{
                return this.mapYesNo(false);
            }
        }
    }
}
