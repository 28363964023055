import {AxiosError} from "axios";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";

interface NotificationState {
  clientSigningInProgress: boolean;
  notificationError: AxiosError | undefined;
}

const state = (): NotificationState => ({
  clientSigningInProgress: false,
  notificationError: undefined
})

const mutations: MutationTree<NotificationState> = {
  setClientSigningInProgress(state, payload: boolean) {
    state.clientSigningInProgress = payload;
  },
  setNotificationError(state, payload: { error: AxiosError | undefined }) {
    state.notificationError = payload.error;
  },
  showMessage () {
    // Empty on purpose, acts as global event
  }
}

const actions: ActionTree<NotificationState, RootState> = {}

const getters: GetterTree<NotificationState, RootState> = {
  notificationError: (state): AxiosError | undefined => {
    return state.notificationError;
  },
  clientSigningInProgress: (state): boolean => {
    return state.clientSigningInProgress;
  }
}

export const notification: Module<NotificationState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
