export const AUTOCOMPLETE_MIN_LENGTH = 3;
export const IMAGE_DATA_URI_PREFIX = "data:image/png;base64,"

export enum ACCOUNT_TYPE {
    STANDARD = 'standard',
    EXTERNAL = 'external',
    TEMPORARY = 'temporary',
    SYSTEM = 'system',
    UNKNOWN = 'unknown'
}

export enum SIGNATURE_PHASE {
    NONE,
    READ,
    SIGNATURE_FIELD,
    SIGN,
    AUTHORIZATION,
    PROCESSING,
    CLIENT_SIGNING,
    ERROR,
    SUCCESS,
    FORBIDDEN,
    BULK_MODE,
    DISABLED,
    REQUIRED_READ
}

export enum HARD_DUE_DATE_MODE {
    CHOOSE = 'CHOOSE',
    SOFT = 'SOFT',
    HARD = 'HARD'
}

// TODO zbavme se mapování kódů reportstatu na straně GUI a berme zprávy přímo z reportů? Duplicitní texty s konfigurací = zmatek, kde se co bere
// TODO v mapě jsou report states, nikoliv step states - pokud se má zachovat, je na místě přejmenování

//Poznámka -> Nakonec jsem tu mapu nechal, kvůli autorizaci, ostaní se předávájí z API
export const FLOW_STEP_STATE_MESSAGE_MAP = {
    "900": "docDetail.messages.stateChanged",
    "910": "docDetail.messages.processStartFailed",
    "920": "docDetail.messages.expired",
    "930": "docDetail.messages.rejected",
    "940": "docDetail.messages.sealError",
    "950": "docDetail.messages.authzAbandoned",
    "960": "docDetail.messages.canceledByAnotherProcess",
    "990": "docDetail.messages.unexpected"
} as {[key: string]: string}

export const UNKNOWN_REPORT_STATE_ERROR_MESSAGE = "docDetail.messages.messageNotFound" as string

export enum OS {
    WINDOWS = 'windows',
    MACOS = 'macos',
    LINUX = 'linux',
    IOS = 'ios',
    ANDROID = 'android'
}

export enum NotificationType {
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success'
}
