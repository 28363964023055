import Vue from 'vue'
import router from './router'
import {i18n} from './plugin/i18n'
import {vuetify} from './plugin/vuetify'
import store from './store/store'
import {keycloak} from "@/plugin/vueKeycloak";
import './plugin/veeValidate'
import './plugin/vueStorage'
import './plugin/vueSignaturePad'
import axios from './plugin/vueAxios'
import {AxiosStatic} from "axios";
import './utils/utils'
import App from './App.vue'
import {ObeliskConfigJs} from "@/types";
import {fileSize, tag, truncateNumber} from "@/utils/utils";

function setVhVariable() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// Set --vh custom variable
setVhVariable();
window.addEventListener('resize', () => {
  setVhVariable();
});

// obeliskConfig is a javascript object inside DOM. We need to declare it as global TS variable to have it available everywhere.
declare global {
  let obeliskConfig: ObeliskConfigJs
}

Vue.config.productionTip = false;
// TODO: Ignorování warningu, který se mnohokrát opakuje v konzoli. Warning vzniká v důsledku bugu ve Vue či Vuetify.
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function (msg, vm, trace) {
  const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <svg>.';
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = "";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    vm = null;
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    trace = "";
  }
}

// Register global filters
Vue.filter('tag', tag);
Vue.filter('truncateNumber', truncateNumber);
Vue.filter('fileSize', fileSize)


// Register Axios in Vue components
Vue.prototype.axios = axios;
declare module 'vue/types/vue' {
  interface Vue {
    axios: AxiosStatic;
  }
}

keycloak.init({
  onLoad: 'login-required'
}).then((auth: boolean) => {
  if (!auth) {
    window.location.reload();
  }

  //Mount Vue Application
  new Vue({
    router,
    i18n,
    vuetify,
    store,
    render: h => h(App)
  }).$mount('#app')

  // Store Credentials
  if (keycloak.token && keycloak.idToken && keycloak.token !== '' && keycloak.idToken !== '') {
    store.commit("auth/login", {
      idToken: keycloak.idToken,
      accessToken: keycloak.token
    });
  }
  else {
    store.commit("auth/logout");
  }

  //Token Refresh
  setInterval(() => {
    store.dispatch("auth/updateToken", 30);
  }, 10000)

}).catch((e: Error) => {
  console.error(e);
  console.error("Failed to initialize application");
});
