import Vue, {PropType} from "vue";
import {
  Flow,
  PartialRecord,
  SignatureOnlineConfig,
  SignatureOnlinePhase,
  SignatureOnlineRejectionPhase,
  ThemeOnlineSignatureCustomization
} from "@/types";

export default Vue.extend({
  props: {
    flow: {
      type: Object as PropType<Flow>,
      required: true
    },
    redirectUri: String,
    customization: Object as PropType<ThemeOnlineSignatureCustomization>
  },

  computed: {
    onlineContentConfig(): PartialRecord<SignatureOnlinePhase, SignatureOnlineConfig> {
      return {
        [SignatureOnlinePhase.DOCUMENT_DETAIL]: {
          componentName: 'SignatureOnlineDocumentDetail',
          props: {
            navCancel: true,
            flow: this.flow,
            customization: this.customization
          },
          listeners: {
            cancel: () => this.reject(),
            'flow-reload': () => this.flowReload(),
            'signature-settings': () => this.signatureSettings(),
            success: () => this.success()
          }
        },
        [SignatureOnlinePhase.DOCUMENT_INVALID]: {
          componentName: 'SignatureOnlineDocumentInvalid',
          props: {
            actionBack: !!this.redirectUri
          },
          listeners: {
            back: (instant?: boolean) => this.redirectToSystem(instant)
          }
        },
        [SignatureOnlinePhase.INTRO]: {
          componentName: 'SignatureOnlineIntro',
          props: {
            navCancel: true,
            flow: this.flow
          },
          listeners: {
            cancel: () => this.reject(),
            next: () => this.changePhase(SignatureOnlinePhase.DOCUMENT_DETAIL)
          }
        },
        [SignatureOnlinePhase.REJECTION]: {
          componentName: 'SignatureOnlineRejection',
          props: {
            flow: this.flow
          },
          listeners: {
            back: () => this.rejectionCanceled(),
            reject: (rejectionReason?: string) => this.rejectionConfirmed(rejectionReason)
          }
        },
        [SignatureOnlinePhase.REJECTION_CONFIRMATION]: {
          componentName: 'SignatureOnlineRejectionConfirmation',
          props: {
            actionBack: false,
            flow: this.flow
          },
          listeners: {
            back: () => this.changePhase(SignatureOnlinePhase.DOCUMENT_DETAIL)
          }
        },
        [SignatureOnlinePhase.SUCCESS]: {
          componentName: 'SignatureOnlineSuccess',
          props: {
            actionBack: false,
            actionNext: !!this.redirectUri,
            flow: this.flow
          },
          listeners: {
            back: () => this.changePhase(SignatureOnlinePhase.DOCUMENT_DETAIL),
            next: (instant?: boolean) => this.redirectToSystem(instant)
          }
        }
      }
    }
  },

  methods: {
    changePhase(phase: SignatureOnlinePhase): void {
      this.$emit('change-phase', phase);
    },
    flowReload(): void {
      this.$emit('flow-reload');
    },
    redirectToSystem(instant?: boolean): void {
      this.$emit('redirect-to-system', instant);
    },
    reject(): void {
      this.$emit('rejection', SignatureOnlineRejectionPhase.CONFIRMATION);
    },
    rejectionCanceled(): void {
      this.$emit('rejection', SignatureOnlineRejectionPhase.CANCEL);
    },
    rejectionConfirmed(rejectionReason?: string): void {
      this.$emit('rejection', SignatureOnlineRejectionPhase.CONFIRMED, rejectionReason);
    },
    signatureSettings(): void {
      this.$emit('signature-settings');
    },
    success(): void {
      this.$emit('success');
    }
  }
})