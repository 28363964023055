var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signer-list-item-wrapper d-flex pb-2",class:{'align-center': !_vm.displayDesktop, 'mobile': !_vm.displayDesktop}},[_vm._t("prepend-icon"),_c('div',{staticClass:"signer-list-item-wrapper-content",class:{'mobile': !_vm.displayDesktop},staticStyle:{"width":"100%","position":"relative"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"d-flex align-start",attrs:{"cols":"12","lg":"3"}},[_c('SignerName',{staticClass:"mb-0 mr-3",attrs:{"account":_vm.account,"data-testid":"signerItem-name","myself":_vm.myself}})],1),_c('v-col',{staticClass:"d-flex align-start",class:_vm.displayDesktop ? 'justify-end' : 'justify-start',attrs:{"cols":"12","lg":"4"}},[_c('SignerEmail',{attrs:{"account":_vm.account,"data-testid":"signerItem-email","email-icon":_vm.displayDesktop,"myself":_vm.myself,"remove-action":_vm.displayDesktop},on:{"remove":_vm.remove}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"5"}},[_c('div',{staticClass:"d-flex align-center"},[_c('SSelect',{staticClass:"business-select",staticStyle:{"width":"100%"},attrs:{"data-testid":"signerItem-signatureLevel","items":_vm.signatureLevels,"item-text":"description","item-value":"businessLevelId","placeholder":_vm.$t('uploadWizard.steps.people.signatureType').toString(),"return-object":"","validations":"required"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('div',[_vm._v(" "+_vm._s(data.item.description)+" ")]),(_vm.businessLevelsMapping && !_vm.businessLevelsMapping[data.item.businessLevelId])?_c('STooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"error","size":"20"},domProps:{"textContent":_vm._s('$errorCircleFull')}},'v-icon',attrs,false),on))]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('uploadWizard.steps.people.inactiveSigningProfile')[0])),_c('br'),_vm._v(" "+_vm._s(_vm.$t('uploadWizard.steps.people.inactiveSigningProfile')[1])+" ")])]):_vm._e()],1)]}}]),model:{value:(_vm.requestedSignatureLevel),callback:function ($$v) {_vm.requestedSignatureLevel=$$v},expression:"requestedSignatureLevel"}}),(_vm.requestedSignatureLevel && _vm.businessLevelsMapping && !_vm.businessLevelsMapping[_vm.requestedSignatureLevel.businessLevelId])?_c('STooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mb-4 no-undershadow",attrs:{"color":"error","size":"20"},domProps:{"textContent":_vm._s('$errorCircleFull')}},'v-icon',attrs,false),on))]}}],null,false,502253984)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('uploadWizard.steps.people.inactiveSigningProfile')[0])),_c('br'),_vm._v(" "+_vm._s(_vm.$t('uploadWizard.steps.people.inactiveSigningProfile')[1])+" ")])]):_vm._e()],1),_c('div',[(_vm.thirdPersonBusinessLevel)?_c('STextField',{staticClass:"third-signer-name",attrs:{"data-testid":"signerItem-thirdPersonName","placeholder":_vm.$t('uploadWizard.steps.people.thirdParty'),"required":"","validations":"required|max:200"},model:{value:(_vm.thirdPersonName),callback:function ($$v) {_vm.thirdPersonName=$$v},expression:"thirdPersonName"}}):_vm._e()],1)])],1),(!_vm.displayDesktop)?_c('div',{staticStyle:{"position":"absolute","top":"10px","right":"10px"}},[_c('v-menu',{attrs:{"rounded":"lg","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"mutedContrast","fab":"","x-small":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s('$menu')}})],1)]}}],null,false,3725696455)},[_c('v-list',{attrs:{"color":"mutedContrast"}},[_c('v-list-item',{on:{"click":_vm.remove}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s('$userMinus')}})],1),_c('v-list-item-content',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.remove'))+" ")])],1)],1)],1)],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }