import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {fal} from "@fortawesome/pro-light-svg-icons";
import {far} from "@fortawesome/pro-regular-svg-icons";
import {fas} from "@fortawesome/pro-solid-svg-icons";
import {sefBankId} from "@/icons/custom-svg-icons";
import {VuetifyIcon, VuetifyIcons} from "vuetify/types/services/icons";

library.add(fab, fal, far, fas)
library.add(sefBankId)

const defaultPrefix = 'fal';

function getIconObject(value: string): VuetifyIcon{
  const split = value.split(';');
  let prefix = defaultPrefix;
  let name = split[0];

  if(split.length > 1){
    prefix = split[0];
    name = split[1];
  }
  return {
    component: FontAwesomeIcon,
    props: {
      icon: [prefix, name]
    }
  }
}

const icons = {
  // Předefinování ikon Vuetify
  // key: "prefix;icon-name"
  cancel: 'times',
  delete: 'times',
  success: 'check-circle',
  info: 'exclamation-circle',
  error: 'exclamation-triangle',
  prev: 'chevron-left',
  next: 'chevron-right',
  checkboxIndeterminate: 'square-minus',
  checkboxOff: 'square',
  checkboxOn: 'square-check',
  expand: 'fas;caret-down',
  menu: 'ellipsis-v',
  dropdown: 'angle-down',
  radioOff: 'circle',
  radioOn: 'far;dot-circle',
  edit: 'edit',
  file: 'file',
  // Ostatní ikony
  addressCard: 'address-card',
  arrowDown: 'chevron-down',
  arrowUp: 'chevron-up',
  attachment: 'paperclip-vertical',
  authorization: 'key',
  back: 'arrow-left',
  ban: 'ban',
  bank: 'university',
  bars: 'bars',
  bell: 'bell',
  bulk: 'mail-bulk',
  bolt: 'bolt',
  calendar: 'calendar-alt',
  cancelThick: 'fas;times',
  card: 'sim-card',
  caretRight: 'fas;caret-right',
  check: 'check',
  circle: 'fas;circle',
  clock: 'clock',
  cog: 'cog',
  collapse: 'fas;caret-up',
  comment: 'comment',
  dots: 'ellipsis-h',
  download: 'arrow-down-to-bracket',
  downloadFile: 'far;file-circle-check',
  draft: 'sticky-note',
  dragHandle: 'grip-dots-vertical',
  dropup: 'angle-up',
  emptyCircle: 'circle',
  envelope: 'envelope',
  errorCircle: 'exclamation-circle',
  errorCircleFull: 'fas;exclamation-circle',
  exchange: 'arrow-right-arrow-left',
  external: 'external-link-alt',
  fileMetadata: 'file-medical-alt',
  filter: 'filter',
  finished: 'arrow-alt-to-right',
  folders: 'folders',
  hourglass: 'hourglass',
  hourglassEnd: 'hourglass-end',
  image: 'file-image',
  inbox: 'inbox',
  infoCircle: 'info-circle',
  list: 'list',
  location: 'map-marker',
  lockKeyhole: 'fas;lock-keyhole',
  logout: 'sign-out',
  logs: 'calendar-exclamation',
  message: 'comment-dots',
  mobile: 'mobile',
  passwordShow: 'fas;eye',
  passwordHide: 'fas;eye-slash',
  pause: 'fas;pause',
  pdf: 'file-pdf',
  pdfFullscreen: 'expand',
  pdfFullscreenExit: 'compress',
  pdfPageFirst: 'arrow-up-to-line',
  pdfPageLast: 'arrow-down-to-line',
  pdfPageNext: 'arrow-down',
  pdfPagePrev: 'arrow-up',
  pdfTools: 'tools',
  pdfValidate: 'file-check',
  pdfZoomIn: 'circle-plus',
  pdfZoomOut: 'circle-minus',
  pen: 'pen-nib',
  pencil: 'pencil',
  peopleGroup: 'fas;people-group',
  plus: 'plus',
  priorityLow: 'angle-down',
  priorityMedium: 'angle-up',
  priorityHigh: 'angle-double-up',
  priorityCritical: 'exclamation-triangle',
  read: 'glasses',
  reasonSign: 'pen-to-square',
  recall: 'window-close',
  redo: 'redo',
  refuse: 'file-slash',
  rejectDoc: 'vote-nay',
  remove:'trash-can',
  reply: 'reply',
  agreementIcon: 'vote-yea',
  search: 'search',
  send: 'paper-plane',
  sig: 'signature',
  signatureField: 'location-pen',
  signIn: 'sign-in',
  settings: 'cog',
  stepCompleted: 'check',
  stepCurrent: 'arrow-up',
  stepRejected: 'times',
  stepSystem: 'cog',
  stepWaiting: 'hourglass',
  support: 'headset',
  table: 'table',
  tableCells:'far;table-cells',
  tag: 'tag',
  trash: 'trash-alt',
  undo: 'undo',
  user: 'user',
  users: 'users',
  userSolid: 'fas;user',
  userAdd: 'fas;user-plus',
  userMinus: 'user-minus',
  verticalLines: 'grip-lines-vertical',
  zoomIn: 'search-plus',
  zoomOut: 'search-minus',
  idBadge: 'id-badge',
  // Ikony obchodních značek
  'bankid-sandbox': 'fab;bankid',
  bitbucket: 'fab;bitbucket',
  defaultProvider: 'user',
  facebook: 'fab;facebook',
  github: 'fab;github',
  gitlab: 'fab;gitlab',
  google: 'fab;google',
  instagram: 'fab;instagram',
  linkedin: 'fab;linkedin',
  microsoft: 'fab;microsoft',
  openid: 'fab;openid',
  paypal: 'fab;paypal',
  stackoverflow: 'fab;stack-overflow',
  twitter: 'fab;twitter'
} as {[key: string]: string}

const FONT_AWESOME_ICONS = {} as Partial<VuetifyIcons>;

for (const item in icons){
  FONT_AWESOME_ICONS[item] = getIconObject(icons[item]);
}

export { FONT_AWESOME_ICONS }
