import Vue from "vue";
import {Locale} from "@/types";

export default Vue.extend({
  data() {
    return {
      csFlag: require('@/assets/cs.svg'),
      enFlag: require('@/assets/en.svg'),
      skFlag: require('@/assets/sk.svg')
    }
  },

  computed: {
    activeLocale(): string {
      return this.$store.getters['locale/locale'];
    },
    locales(): Array<Locale> {
      return this.$store.getters['locale/locales'];
    }
  },

  methods: {
    localeFlag(locale: Locale) {
      switch (locale.languageCode) {
        case 'cs':
          return this.csFlag;
        case 'en':
          return this.enFlag;
        case 'sk':
          return this.skFlag;
      }
    },
    async setLocale(locale: Locale): Promise<void> {
      if (this.activeLocale !== locale.languageCode)
        await this.$store.dispatch('locale/changeLocale', { locale: locale });
    },
    async setLocaleCode(code: string): Promise<void> {
      if (this.activeLocale !== code)
        await this.$store.dispatch('locale/changeLocale', { locale: { languageCode: code } });
    }
  }
})