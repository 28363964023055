


































































import AddVisualisationInfo from "@/component/profile/signatures/component/AddVisualisationInfo.vue";
import PhoneNumberSelector from "@/component/profile/signatures/component/PhoneNumberSelector.vue";
import SignatureProfileItemDetail from "@/component/profile/signatures/SignatureProfileItem.vue";
import SignatureProfileList from "@/component/profile/signatures/SignatureProfileList.vue";
import SList from "@/component/ui/SList.vue";
import DisplayMixin from "@/mixins/displayMixin";
import {SignatureMethod, SignatureProfile, SignatureProfileCardConfig, SignatureProfileConfiguration} from "@/types";
import Vue from "vue";
import ClientSignatureAppWarning from "@/component/profile/signatures/component/ClientSignatureAppWarning.vue";

export default Vue.extend({
  name: 'SignatureProfilesOverview',
  components: {
    DisplayMixin,
    AddVisualisationInfo,
    PhoneNumberSelector,
    ClientSignatureAppWarning,
    SignatureProfileItemDetail,
    SignatureProfileList,
    SList
  },
  props: {
    mobile: Boolean
  },

  data() {
    return {
      detail: false,
      selectedItem: undefined as SignatureProfile|undefined
    }
  },

  computed: {
    method(): SignatureMethod|undefined {
      return this.$store.getters["profile/selectedSignatureMethod"];
    },
    methodCode(): string|undefined {
      if (!this.method) return undefined;
      return this.method.providerTypeCode;
    },
    config(): SignatureProfileConfiguration|undefined {
      if(!this.methodCode) return undefined;
      return this.$store.getters["profile/signatureProfileConfiguration"][this.methodCode];
    },
    title(): string {
      return this.$t(this.config?.title || "").toString();
    },
    cardConfig(): SignatureProfileCardConfig|undefined {
      return this.config?.cardConfig;
    },
    visualEditAvailable(): boolean {
      let visualConfig = this.$store.getters["profile/visualConfigLinks"];
      return visualConfig?.["sef:add-visual"]?.href;
    }
  },

  methods: {
    add(): void {
      this.selectedItem = undefined;
      this.detail = true;
    },
    edit(profile: SignatureProfile) {
      this.selectedItem = profile;
      this.detail = true;
    },
    closeDetail(): void {
      this.selectedItem = undefined;
      this.detail = false;
      this.setTitle(this.title);
    },
    setTitle(title: string){
      this.$emit('set-title', title);
    }
  },

  watch: {
    method(value: SignatureMethod|undefined) {
      this.detail = false;
      this.selectedItem = undefined;
      if(value && value.providerTypeCode === "RS-BANK-ID" && !this.$store.getters["profile/banks"]){
        this.$store.dispatch("profile/loadBanks");
      }
    },
    title: {
      immediate: true,
      handler(value: string) {
        this.setTitle(value);
      }
    },
  },

  beforeDestroy() {
    this.$store.commit('profile/selectSignatureMethod', { method: undefined })
  },
  created() {
    this.$store.dispatch('profile/loadVisualisations');
  }

})
