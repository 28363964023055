import {HalResource, Link} from "@/types/hal";
import {Bank} from "@/types/other";
import {Flow, FlowStep} from "@/types/workflow";

/**********************************
 * BATCHES
 *********************************/

export enum AuthorizationType {
    CLIENT_DEVICE = 1,
    AUTHORIZATION_SERVER = 2,
    MOBILE_APP = 3,
}

export enum ReportStateId {
    UNEXPECTED_STATE_PROCESSING = 123,
    MISSING_READ_FLAG = 130,
    MISSING_VISUAL_FIELD = 170,

    //
    MISSING_PROFILE = 140,
    PROFILE_INACTIVE = 150,
    EXPIRED_CERTIFICATE = 181,

    //Podepsat už nepůjde -> aktualizace hlavičky + globální notifikace (error)
    EXPIRED_FLOW = 118,
    UNEXPECTED_STATE_CANCELLED = 127,
    UNEXPECTED_STATE_REJECTED = 126,

    //Podepsat už nepůjde -> aktualizace hlavičky + globální notifikace (info) -> zde není potřeba uživatele varovat chybovou hláškou
    UNEXPECTED_STATE_FINISHED = 125,
    FLOW_LOCK = 195
}

export enum ReportStateType {
    AUTHORIZATION = 'authorization',
    ERROR = 'error',
    FINISHED = 'finished',
    IN_PROGRESS = 'in_progress',
}

export interface BatchStub extends HalResource<unknown, unknown> {
    batchId: number;
    bulkMode: boolean;
    reportRows: Array<ReportRow>;
}

export interface ReportRow extends HalResource<ReportRowEmbedded, ReportRowLinks> {
    authorizationRedirectCount: number;
    authorizationType: number;
    authorizationTypeDescription: string;
    count: number;
    countCanIncrease: boolean;
    noteCount: number;
    reportStateDescription: string;
    reportStateId: number;
    reportStateName: string;
    reportStateType: ReportStateType;
    signingMethodName: string;
    signingMethodNameShort: string;
    sigProfileIdent: string;
}

interface ReportRowEmbedded {
    flows: Array<ReportRowFlow>;
    signingProfile?: SignatureProfile;
    steps?: Array<FlowStep>;
    flow?: Flow;
}

export interface NotificationTreeItem {
    id: number | string;
    subject: string;
    count: number;
    reportStateType: ReportStateType;
    authorizationType: number;
    reportStateId: number;
    signatureProfile?: SignatureProfile;
    _links?: ReportRowLinks;
    children: Array<ReportRowFlow>;
}

export interface ReportRowFlow {
    idSpFlow: number,
    subject: string,
    links: ReportRowFlowLinks
}

export interface ReportRowFlowLinks {
    self: Link;
    "sef:document-data-download"?: Link;
    "sef:enclosures-list": Link;
}

export interface ReportRowLinks {
    "sef:abandon-authorization"?: Link;
    "sef:external-authorization"?: Link;
    "sef:get-client-signing-uri"?: Link;
}

// TODO - old client, REMOVE
/**********************************
 * CARD CLIENT SIGNING
 *********************************/
export interface CardClientConfig {
    clientDownloadUrl: string;
}

export interface CardClientSession {
    sessionId: string;
    sessionSignature: string;
}

export interface SmartCardInfo {
    ATR: string;
    modelName: string;
    description: string;
    downloadUrl: string;
    drivers: Array<string>;
}

export interface DataToSign extends HalResource<undefined, DataToSignLinks> {
    batchId: number;
    certificate: string;
    dataToSign: string;
    digestAlgorithm: string;
    reportStateId: number;
    stepId: number;
    useRsaPss?: boolean;
}

interface DataToSignLinks {
    "sef:report-client-signature-error": Link;
    "sef:upload-client-signature-value": Link;
}

/**********************************
 * SIGNATURE METHOD
 *********************************/

export interface SignatureMethod extends HalResource<SignatureMethodEmbedded, SignatureMethodLinks> {
    description?: string;
    legalLevelNames: Array<string>;
    providerTypeCode: string;
    providerTypeId: number;
    title: string;
}

export interface SignatureMethodEmbedded {
    providers: Array<SignatureProvider>;
}

export interface SignatureMethodLinks {
    self: Link;
}

/**********************************
 * SIGNATURE PROFILE
 *********************************/
export enum CertificateStateValues {
    INIT_TIMEOUT = -1,
    SELECT_TIMEOUT = -2,
    INIT = 1,
    SELECT = 2,
    FINISHED = 3,
    FAILED = 4,
    CLIENT_CANCEL = 5
}

export type CertificateState = CertificateStateValues | null
export const isTerminalState = (certificateState: CertificateState) => certificateState !==
    CertificateStateValues.INIT && certificateState !== CertificateStateValues.SELECT
export const isCancelled = (certificateState: CertificateState) => certificateState ===
    CertificateStateValues.CLIENT_CANCEL
// !!certificateState works because certificateState cannot be 0
export const isTimeout = (certificateState: CertificateState) => !!certificateState && certificateState < 0
export const isFailed = (certificateState: CertificateState) => certificateState === CertificateStateValues.FAILED

export interface SignatureProfile extends HalResource<SignatureProfileEmbedded, SignatureProfileLinks> {
    bankId?: string;
    externalId?: string;
    fieldRestrictionsNewField: SigningProfileFieldRestrictions;
    fieldRestrictionsPdfField: SigningProfileFieldRestrictions;
    isEnabled: boolean;
    itemOrder: number;
    legalLevel: string;
    profileId: number;
    profileName: string;
    signingCertificate?: SigningCertificate;
    signingMethodName: string;
    signingMethodNameShort: string;
    signingMethodSuffix: string;
    certificateState?: CertificateState;
    isCaProfile: boolean;
    caSubject?: string;
    inputs: SignatureProfileInputs;
}

export interface SignatureProfileEmbedded {
    signatureProvider?: SignatureProvider;
    visual?: Visualisation;
    bank?: Bank;
}

export interface SignatureProfileInputs {
    required: Array<string>;
    notSupported: Array<string>;
    optional: Array<string>;
    redundant: Array<string>;
}

export interface SignatureProfileLinks {
    self: Link;
    "sef:edit-signing-profile"?: Link;
    "sef:remove-signing-profile"?: Link;
    "sef:activate-signing-profile"?: Link;
    "sef:deactivate-signing-profile"?: Link;
    "sef:get-certificate-select-uri"?: Link;
    "sef:cancel-certificate-select"?: Link;
    "sef:delete-certificate-select-state"?: Link;
}

export interface SigningCertificate {
    algorithm: string;
    certificateChain: Array<CertificateChain>;
    issuer: string;
    serialNumber: string;
    subject: string;
    validFrom: string;
    validTo: string;
}

export interface CertificateChain {
    x509: string;
}

export interface SigningProfileFieldRestrictions {
    maxWidth: Array<string>;
    minWidth: Array<string>;
    maxHeight: Array<string>;
    minHeight: Array<string>;
}

export interface SignatureProfileConfiguration {
    title: string;
    prependComponents?: Array<SignatureProfileComponent>;
    appendComponent?: SignatureProfileComponent;
    addNewProfile?: boolean,
    cardConfig: SignatureProfileCardConfig;
    detail?: SignatureProfileDetailConfig;
}

export interface SignatureProfileComponent {
    name: string;
    props?: { [key: string]: string };
}

type ValueOrDerivedFromProfile<T> = T | ((profile: SignatureProfile) => T)

export type SignatureProfileCardConfigActions = Array<"remove" | "edit">;

export interface SignatureProfileCardConfig {
    actions?: ValueOrDerivedFromProfile<SignatureProfileCardConfigActions>;
    rows: Array<SignatureProfileCardRow>;
    caActionComponent?:CaActionComponent;
    busyState?: {
        isBusy: (profile: SignatureProfile) => boolean;
        // BEWARE: the component must be imported
        component: string,
        refresh?: {
            interval: number,
            callback: (profile: SignatureProfile, providerTypeCode: string) => void
        }
    }
}

export type SignatureProfileCardRow = {
    showIf: (profile?: SignatureProfile) => boolean
    rowKey: SignatureProfileCardRowValue
    rowValue : SignatureProfileCardRowValue
    visualization?:(profile: SignatureProfile) =>Visualisation|undefined,
}

export type CaActionComponent= {
    showIf: (profile: SignatureProfile) => boolean
}

export type SignatureProfileCardRowValueType = "function" | "text" | "i18nText";
export type SignatureProfileCardRowValueContent =
    string
    | ((profile: SignatureProfile) => string | undefined)
    | ((profile: Required<SignatureProfile>) => string | undefined);

export interface SignatureProfileCardRowValue {
    type: SignatureProfileCardRowValueType;
    value: SignatureProfileCardRowValueContent;
    showIf?: (profile: SignatureProfile) => unknown;
    fallback?: SignatureProfileCardRowValue;
    bold?: boolean;
}

export interface SignatureProfileDetailConfig {
    allowNameEdit?: ValueOrDerivedFromProfile<boolean>;
    multipleProviders: boolean;
    visualisation: boolean;
    features?: Array<SignatureProfileComponent>;
    // enables provider type specific modification before the profile is created
    beforeCreateHook?: (profile: SignatureProfile) => void;
    postCreateHook?: (profile: SignatureProfile) => void;
}

export enum SignatureProfileFormMode {
    NONE, CREATE, UPDATE
}

/**********************************
 * SIGNATURE PROVIDER
 *********************************/

export interface SignatureProvider extends HalResource<unknown, SignatureProviderLinks> {
    providerId: number;
}

export interface SignatureProviderLinks {
    self: Link
}

/**********************************
 * VISUALISATION
 *********************************/

export interface Visualisation extends HalResource<unknown, VisualisationLinks> {
    changedAt?: string;
    company?: string;
    contactInfo?: string;
    createdAt?: string;
    location?: string;
    logo?: string;
    name?: string;
    reason?: string;
    signatureImage?: string;
    signingText?: string;
    title?: string;
    visualId?: number;
    visualName?: string;
}

export interface VisualisationLinks {
    self?: Link;
    "sef:delete-visual"?: Link;
    "sef:update-visual"?: Link;
}

export interface VisualisationListLinks {
    self: Link;
    "sef:add-visual"?: Link;
}

export interface VisualisationListEmbedded {
    visuals?: Array<Visualisation>
}

export type VisualisationList = HalResource<VisualisationListEmbedded, VisualisationListLinks>
