<template>
  <div class="flow-detail-actions__panel"
       :class="{'d-flex': displayDesktop}">
    <div
        class="flow-detail-actions flow-detail-actions__secondary"
        :class="{ 'flow-detail-actions__secondary--desktop': displayDesktop, 'mb-3': !displayDesktop, 'mt-6': lockedByOtherUser }"
    >

      <!-- Informative actions -->
      <div class="d-flex ml-2">
        <!-- Flow Comment -->
        <FlowDetailActionDialog
            v-if="note"
            :flow="flowObject"
            :icon="'$comment'"
            :text="$t('docDetail.comment').toString()"
        >
          <template #icon>
            <v-badge
                class="notification-badge"
                color="secondaryContrast"
                offset-x="0"
                offset-y="10"
                :content="1"
                :value="1"
            >
              <v-icon color="mainText" size="20" v-text="'$comment'"/>
            </v-badge>
          </template>
          <template #title>
            <UserInfo
                :text="$t('docDetail.metadata.messageRequestText').toString()"
                :user="user"
            />
          </template>
          <template #content>
            <div>{{ note }}</div>
          </template>
        </FlowDetailActionDialog>

        <!-- Flow Attachments -->
        <FlowDetailActionDialog
            v-if="hasFlowAttachments"
            :flow="flowObject"
            :text="$t('docDetail.attachments').toString()"
            :title="$t('docDetail.attachments').toString()"
        >
          <template #icon>
            <v-badge
                class="notification-badge"
                color="secondaryContrast"
                offset-x="0"
                offset-y="10"
                :content="flowAttachmentCount"
                :value="flowAttachmentCount"
            >
              <v-icon color="mainText" size="20" v-text="'$attachment'"/>
            </v-badge>
          </template>
          <template #content>
            <FlowAttachments
                :flow="flowObject"
                :pdfAttachments="pdfAttachments"
            />
          </template>
        </FlowDetailActionDialog>
      </div>

      <!-- Interactive actions -->
      <div class="d-flex">
        <!-- Flow Reason To Singing -->
        <FlowReasonToSign
            v-if="(reasonSingAvailable && showActionButton && !draft && readyToBeSigned && !bulkMode)"
            :flow="flowObject"
            :not-supported-sign-detail="notSupportedSignatureDetails"
            :redundant-sign-detail="redundantSignatureDetails"
            :valid-profile="isValidProfileSelected(selectedProfile, profiles)"
            :icon="'$reasonSign'"
            :text="$t('docDetail.reasonToSign.detail').toString()"
            :title="$t('docDetail.reasonToSign.header').toString()"
            @reason-set-reload="reloadFlow"
        />

        <!-- Flow Reject/Recall-->
        <div v-if="!draft && showActionButton"
             class="flow-detail-actions__button"
             @click="reject"
        >
          <v-icon class="flow-detail-actions__icon" color="mainContrast" size="24" v-text="'$refuse'"/>
          <div class="flow-detail-actions__text">{{ cancelText }}</div>
        </div>

        <!-- Cancel authorization -->
        <div
            v-if="authorizationPhase || authorizationPhase && mobileSigning"
            class="flow-detail-actions__button"
            @click="cancelAuthorization"
        >
          <v-icon v-text="'$cancel'" class="flow-detail-actions__icon" color="mainContrast" size="24"/>
          <div class="flow-detail-actions__text">{{ $t('common.actions.cancel') }}</div>
        </div>
      </div>
    </div>

    <div class="flow-detail-actions flow-detail-actions__primary">
      <!-- Delete draft -->
      <SIconButton
          v-if="draft && showActionButton"
          class="flow-detail-actions__draft-delete"
          icon="$trash"
          size="50"
          variant="secondary-background-tinted"
          background
          :tooltip="$t('docDetail.actions.delete').toString()"
          @click="deleteDraft"
      />

      <!-- Flow detail sign actions -->
      <div>
        <!-- Signing lock -->
        <div v-if="lockedByOtherUser"
             class="locked-flow__text"
             :class="{'locked-flow__text-mobile': !displayDesktop}">
          <v-icon
              class="mutedText--text"
              size="15"
              style="margin-top: -4px"
              v-text="'$lockKeyhole'"
          />
          {{ $t('docDetail.signatoryInfo') }}
        </div>

        <!-- Main sign action -->
        <div class="flow-detail-actions__main-action">
          <div v-if="visibleContainer" class="flow-detail-actions__container">
            <!-- Signing profile selection -->
            <FlowDetailProfileMenu
                v-if="showSignatureProfiles"
                :menuOpened="menuOpened"
                :profiles="profiles"
                :selected-profile="selectedProfile"
                :authorization-phase="authorizationPhase"
                class="flow-detail-actions__select"
                @profile-menu-opened="setProfileMenuOpened"
                @profile-select="selectProfile"
            />
            <!-- Simple text -->
            <div v-else>
              <span class="flow-detail-actions__visualisation flow-detail-actions__auth-text">
                {{ containerText }}
              </span>
            </div>
          </div>

          <!-- Main Action Button -->
          <v-btn v-if="visibleActionButton"
                 :class="[mainActionContentColor, {'disable-events' : noBtnAction || mainActionCooldown ||
                  this.authorizationPhase && this.mobileSigning}]"
                 :color="mainActionBtnColor"
                 :disabled="mainActionDisabled || documentForbidden || disabled"
                 :loading="!documentLoaded"
                 :style="mainActionButtonStyle"
                 class="flow-detail-actions__main"
                 depressed
                 height="50"
                 rounded
                 @click="mainActionClick"
          >
            <span v-if="authorizationPhase && mobileSigning">
              <v-icon color="secondaryContrastText" v-text="'$mobile'"></v-icon>
            </span>
            <span v-else-if="clientSigning">
              <v-icon color="secondaryContrastText" v-text="'$card'"></v-icon>
            </span>
            <span v-else-if="successfullySigned">
              <v-icon color="successText" v-text="'$success'"></v-icon>
            </span>
            <div v-else-if="signatureFieldPhase && signatureIcons">
              <v-icon color="white" size="25" v-text="'$signatureField'"></v-icon>
            </div>
            <span v-else-if="signPhase && signatureIcons">
              <v-icon class="ml-1" color="white" v-text="'$sig'"></v-icon>
            </span>
            <span v-else>{{ mainAction }}</span>
          </v-btn>
        </div>
      </div>
    </div>

    <ConfirmDialog v-model="confirmDialog"
                   :confirm-text="$t('common.actions.delete').toString()"
                   :subtitle="dialogSubtitle"
                   :title="dialogTitle"
                   @cancel="confirmDialog = !confirmDialog" @confirm="dialogConfirmMethod"/>
  </div>
</template>

<script>
import ConfirmDialog from "@/component/dialog/ConfirmDialog";
import FlowDetailProfileMenu from "@/component/workflow/detail/FlowDetailProfileMenu";
import mutationMixin from "@/mixins/mutationMixin";
import {SIGNATURE_PHASE} from "@/utils/constants";
import authorizationMixin from "@/mixins/authorizationMixin";
import SIconButton from "@/component/ui/buttons/SIconButton";
import FlowReasonToSign from "@/component/workflow/detail/FlowReasonToSign";
import displayMixin from "@/mixins/displayMixin";
import FlowAttachments from "@/component/workflow/detail/FlowAttachments.vue";
import FlowDetailActionDialog from "@/component/workflow/detail/FlowDetailActionDialog.vue";
import UserInfo from "@/component/UserInfo.vue";
import signingProfilesMixin from "@/mixins/signingProfilesMixin";

export default {
  name: 'FlowDetailActions',
  components: {
    UserInfo,
    FlowAttachments,
    FlowReasonToSign,
    SIconButton,
    FlowDetailProfileMenu,
    ConfirmDialog,
    FlowDetailActionDialog
  },
  mixins: [mutationMixin, authorizationMixin, displayMixin, signingProfilesMixin],
  props: {
    draft: {
      type: Boolean,
      default: false
    },
    profiles: Array,
    selectedProfile: {
      type: Object,
      default: undefined,
    },
    providerTypes: Array,
    flowObject: Object,
    onlineSignature: Boolean,
    pdfAttachments: Array,
    documentLoaded: Boolean,
    documentForbidden: Boolean,
    dense: Boolean,
    signatureIcons: Boolean,
    readyToBeSigned: {
      required: true,
      type: Boolean
    },
    lockedByOtherUser: {
      type: Boolean,
      default: false
    },
    noSuitableProfile: Boolean
  },

  data() {
    return {
      fullHeight: 50,
      denseHeight: 30,
      mainActionDisabled: false,
      mainActionCooldown: false,

      // Confirmation dialog
      confirmDialog: false,
      dialogTitle: undefined,
      dialogSubtitle: undefined,
      dialogConfirmMethod: undefined,

      // In progress
      batch: undefined,

      // Authorization
      authorizationLink: undefined,
      abandonAuthorizationLink: undefined,
      authorizationType: undefined,
      // - client authorization
      lockAuthzLink: Boolean,
      report: undefined,
      flow: undefined,

      notSupportedSignatureDetails: false,
      redundantSignatureDetails: false,
      //Profiles menu open
      menuOpened: false
    }
  },

  computed: {
    hasFlowAttachments() {
      return this.flowObject?.enclosuresCount > 0 || this.pdfAttachments?.length;
    },
    flowAttachmentCount() {
      if (!this.pdfAttachments == null && this.flowObject?.enclosuresCount > 0) return 0;
      return this.flowObject.enclosuresCount + this.pdfAttachments?.length;
    },
    user() {
      return this.flowObject?._embedded?.owner;
    },
    note() {
      return this.flowObject?.note;
    },
    visibleContainer() {
      return !this.draft && this.readyToBeSigned;
    },
    visibleActionButton() {
      return this.draft || this.readyToBeSigned;
    },
    showActionButton() {
      return (this.readyToBeSigned || this.recallAvailable || this.draft) &&
          (!this.signingInProgress && !this.successfullySigned && !this.clientSigning && !this.authorizationPhase);
    },
    showSignatureProfiles() {
      return (this.initialSignaturePhase || this.signatureFieldPhase || this.signPhase || this.inError || this.disabled
          || (this.authorizationPhase && !this.mobileSigning)) && !this.requiredRead;
    },
    noProfileSelected() {
      return !(this.selectedProfile && this.isValidProfileSelected(this.selectedProfile, this.profiles));
    },
    containerText() {
      if (this.requiredRead) return this.$t('docDetail.actions.requiredRead');
      if (this.signingInProgress) return this.$t('docDetail.actions.inProgress');
      if (this.successfullySigned) return this.$t('docDetail.actions.signSuccess');
      if (this.authorizationPhase && this.mobileSigning) return this.$t('docDetail.actions.mobileSigning');
      if (this.clientSigning) return this.$t('docDetail.actions.clientSigning');
      if (this.bulkMode) return this.$t('docDetail.actions.bulkMode');
      return '';
    },
    mainAction() {
      if (this.draft) return this.$t('docDetail.actions.openDraft');
      if (this.requiredRead) return this.$t('docDetail.actions.confirmRead');
      if (this.bulkMode || this.signingInProgress) return this.$t('docDetail.actions.signingDetail');
      if (this.authorizationPhase && this.mobileSigning) return '';
      if (this.authorizationPhase) return this.$t('workflow.actions.authorize');
      if (this.noSuitableProfile) return this.$t('docDetail.actions.setupProfile');
      if (this.noProfileSelected) return this.$t('docDetail.actions.chooseProfile');
      if (this.signatureFieldPhase) return this.$t('docDetail.actions.addSignatureField');
      if (this.inError) return this.$t('common.actions.tryAgain');
      return this.$t('docDetail.actions.sign');
    },
    mainActionBtnColor() {
      if (this.authorizationPhase && !this.mobileSigning || this.requiredRead)
        return 'mainContrast';
      else if (this.authorizationPhase && this.mobileSigning || this.draft || this.signingInProgress ||
          this.clientSigning || this.bulkMode || this.noProfileSelected)
        return 'secondaryContrast';
      else {
        if (this.noSuitableProfile)
          return 'secondaryContrast';

        return 'success';
      }
    },
    mainActionContentColor() {
      if (this.draft || this.signingInProgress || this.clientSigning || this.bulkMode || this.noProfileSelected)
        return 'secondaryContrastText--text';
      if (this.authorizationPhase)
        return 'mainContrastText--text';

      return 'successText--text';
    },
    mainActionButtonStyle() {
      return {
        marginLeft: this.draft ? undefined : (this.requiredRead ? '-64px' : '-30px'),
        textTransform: 'var(--btn-text-transform)'
      }
    },
    noBtnAction() {
      return this.signaturePhase === SIGNATURE_PHASE.CLIENT_SIGNING || this.signaturePhase === SIGNATURE_PHASE.SUCCESS;
    },
    signaturePhase() {
      return this.$store.getters["signature/signaturePhase"];
    },
    initialSignaturePhase() {
      return this.signaturePhase === SIGNATURE_PHASE.NONE;
    },
    signPhase() {
      return this.signaturePhase === SIGNATURE_PHASE.SIGN;
    },
    signatureFieldPhase() {
      return this.signaturePhase === SIGNATURE_PHASE.SIGNATURE_FIELD;
    },
    signingInProgress() {
      return this.signaturePhase === SIGNATURE_PHASE.PROCESSING;
    },
    clientSigning() {
      return this.signaturePhase === SIGNATURE_PHASE.CLIENT_SIGNING;
    },
    mobileSigning() {
      return this.authorizationType === 3;
    },
    authorizationPhase() {
      return this.signaturePhase === SIGNATURE_PHASE.AUTHORIZATION;
    },
    inError() {
      return this.signaturePhase === SIGNATURE_PHASE.ERROR;
    },
    successfullySigned() {
      return this.signaturePhase === SIGNATURE_PHASE.SUCCESS;
    },
    bulkMode() {
      return this.signaturePhase === SIGNATURE_PHASE.BULK_MODE;
    },
    disabled() {
      return this.signaturePhase === SIGNATURE_PHASE.DISABLED;
    },
    requiredRead() {
      return this.signaturePhase === SIGNATURE_PHASE.REQUIRED_READ;
    },
    authToken() {
      return this.$store.getters["auth/token"];
    },
    flowOwner() {
      return this.$store.getters["profile/accountName"] === this.flowObject._embedded.owner.accountName;
    },
    recallAvailable() {
      return this.flowObject.state === 'active' && this.flowOwner;
    },
    cancelType() {
      return this.recallAvailable ? 'recall' : 'reject';
    },
    cancelText() {
      return this.$t(`common.actions.${this.cancelType}`).toString();
    },
    reasonSingAvailable() {
      let reasons = this.$store.getters['config/reasonOptions'];
      let location = this.$store.getters['config/locationOptions'];
      let contact = this.$store.getters['config/contactInfoOptions'];
      return reasons.length > 0 || location.length > 0 || contact.length > 0;
    },
  },
  methods: {
    setAuthorizationLink() {
      this.$emit('auth-link-set');
    },
    mainActionClick() {
      if (this.mainActionCooldown) return;
      this.mainActionCooldown = true;
      setTimeout(() => this.mainActionCooldown = false, 500);

      if (this.draft) {
        this.openDraft();
      }
      else if (this.requiredRead) {
        this.$emit('confirm-read');
      }
      else if (this.authorizationPhase) {
        this.authorize();
      }
      else if (this.signingInProgress || this.bulkMode) {
        if (!this.onlineSignature) this.$store.commit('interactionCenter/showBatchProcessing', {batch: this.batch});
      }
      else if (this.signatureFieldPhase || this.noSuitableProfile) {
        this.addSignatureField();
      }
      else if (this.noProfileSelected) {
        this.setProfileMenuOpened(true);
      }
      else if (this.signPhase || this.initialSignaturePhase || this.inError) {
        this.sign();
      }
    },
    addSignatureField() {
      this.$store.commit('signature/addSignatureField');
    },
    sign() {
      this.$store.commit('signature/validateProfileAndSign');
    },
    reject() {
      this.$emit('reject');
    },
    openDraft() {
      this.$emit('openDraft');
    },
    deleteDraft() {
      this.dialogTitle = this.$t('docDetail.actions.draftDeleteDialog')
      this.dialogSubtitle = this.$t('docDetail.actions.draftDeleteDialogSub')
      this.dialogConfirmMethod = this.deleteDraftConfirm;
      this.confirmDialog = true;
    },
    deleteDraftConfirm() {
      this.confirmDialog = false;
      this.$emit('deleteDraft');
    },
    clear() {
      this.mainActionDisabled = false;
    },
    setProfileMenuOpened(value) {
      this.menuOpened = value;
    },
    selectProfile(profile) {
      this.$emit("profile-select", profile)
    },
    async authorize() {
      // client authorization
      if (this.authorizationType === 1) {
        if (this.lockAuthzLink)
          return;
        this.lockAuthzLink = true;
        this.$store.commit('signature/cardAuthorization', {report: this.report, flow: this.flow});
      }
      // external authorization and ICA
      else if (this.authorizationType === 2) {
        try {
          await this.authorizationRedirect(this.authorizationLink, this.onlineSignature);
        }
        catch (ignore) {
          // eslint-disable-next-line no-empty
        }
      }
    },
    cancelAuthorization() {
      this.$store.commit("signature/cancelAuthorization", {link: this.abandonAuthorizationLink});
    },
    reloadFlow() {
      this.$emit('reason-set-reload');
    },
    checkSigningProfileProvider() {
      if (this.selectedProfile) {
        const notSupportedInputs = this.selectedProfile.inputs?.notSupported;
        const redundantInputs = this.selectedProfile.inputs?.redundant
        if (notSupportedInputs && notSupportedInputs.includes("SIGNATURE_DETAILS")) {
          this.notSupportedSignatureDetails = true;
        }
        if (redundantInputs && redundantInputs.includes("SIGNATURE_DETAILS")) {
          this.redundantSignatureDetails = true;
        }
      }
    }
  },
  created() {
    this.setAuthorizationLink();
    this.checkSigningProfileProvider();
    this.subscribe((mutation) => {
      if (mutation.type === 'signature/setSignaturePhase' && mutation.payload.phase === SIGNATURE_PHASE.AUTHORIZATION) {
        this.authorizationLink = mutation.payload.link;
        this.abandonAuthorizationLink = mutation.payload.abandonAuthorizationLink;
        this.authorizationType = mutation.payload.authorizationType;
        this.flow = mutation.payload.flow;
        this.report = mutation.payload.report;
        this.lockAuthzLink = false;
      }
      if (mutation.type === 'signature/setSignaturePhase' && mutation.payload.phase === SIGNATURE_PHASE.PROCESSING) {
        this.batch = mutation.payload.batch;
      }
    })
  },
  watch: {
    selectedProfile(){
      this.notSupportedSignatureDetails = false;
      this.redundantSignatureDetails = false;
      this.checkSigningProfileProvider();
    }
  }
}
</script>

<style scoped>

.flow-detail-actions {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 8px;
}

.flow-detail-actions__secondary {
  height: 50px;
}

.flow-detail-actions__secondary--desktop {
  flex-grow: 1;
  justify-content: space-between;
}

.flow-detail-actions__draft-delete {
  margin-right: 12px;
}

.flow-detail-actions__container {
  display: flex;
  align-items: center;
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--v-secondaryBackground-base);
  width: 250px;
  border-radius: 28px 0 0 28px;
  padding: 0 32px 0 16px;
}

.flow-detail-actions__container * {
  max-width: 100%;
  max-height: 100%;
}

.flow-detail-actions__visualisation {
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--v-secondaryText-base);
  font-size: 12px;
  font-weight: bold;
}

/*noinspection CssUnusedSymbol*/
.active-btn::before {
  background-color: white;
}

.flow-detail-actions__panel {
  justify-content: space-between;
}

/*noinspection CssUnusedSymbol*/
.flow-detail-actions__panel .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  /*noinspection CssUnresolvedCustomProperty*/
  border-color: var(--v-mutedText-base) !important;
}

/*noinspection CssUnusedSymbol*/
.flow-detail-actions__panel .flow-detail-actions__main.theme--light.v-btn.v-btn--disabled {
  background-color: #d9dee3 !important;
}

/*noinspection CssUnusedSymbol*/
.disable-events {
  pointer-events: none;
}

/*noinspection CssUnusedSymbol*/
.v-tooltip__content {
  opacity: 1 !important;
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--v-secondaryBackground-base);
  padding: 10px 20px;
}

.flow-detail-actions__button {
  padding: 4px 16px 0 16px;
  margin-right: 8px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--v-secondaryBackground-base);
  cursor: pointer;
}

.flow-detail-actions__icon {
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--v-mainText-base);
}

.flow-detail-actions__text {
  font-size: 12px;
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--v-secondaryText-base);
  margin-bottom: 4px;
}

.flow-detail-actions__main {
  display: flex;
}

.locked-flow__text {
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--v-mutedText-base);
  margin: 0 0 4px 12px;
}

.flow-detail-actions__main-action {
  display: flex;
  min-height: 50px;
}
</style>
