
























import {ValidationProvider} from 'vee-validate';
import formMixin from '@/mixins/formMixin';
import mixins from "vue-typed-mixins";

export default mixins(formMixin).extend({
  name: 'SSwitch',
  components: {
    ValidationProvider
  },
  props: {
    disabled: Boolean,
    falseValue: {
      type: [Boolean, Number, String],
      default: false
    },
    hint: String,
    id: String,
    label: String,
    multiple: Boolean,
    readonly: Boolean,
    trueValue: {
      type: [Boolean, Number, String],
      default: true
    },
    value: [Boolean, Number, String]
  },
  computed: {
    localValue: {
      get(): boolean|number|string {
        return this.value;
      },
      set(value: boolean|number|string) {
        this.$emit('input', value);
      }
    }
  }
})
