import {DateTime} from "luxon";
import {extension} from "mime-types";
import {extend} from "vee-validate";
import * as importedRules from "vee-validate/dist/rules";
import {ValidationRuleSchema} from "vee-validate/dist/types/types";
import {i18n} from "@/plugin/i18n";

const INTEGER_MAX_VALUE = 2147483647;

const rules: {[key: string]: ValidationRuleSchema} = importedRules;

// Instalace všech validačních pravidel s předefinovanou chybovou zprávou
Object.keys(rules).forEach((rule: string) => {
  extend(rule, {
    ...rules[rule],
    message: (_, values) => {
      return i18n.t('common.validation.' + rule, values).toString()
    }
  })
})

// Custom validation message for mimes validation
extend('mimes', {
  message: (_, values) => {
    const validMimeTypes: string[] = Object.keys(values).filter(key => !key.startsWith("_")).map(key => values[key]);
    return i18n.t(
        'common.validation.mimes',
        {validExtensions :validMimeTypes.map(mime => (extension(mime) as string).toUpperCase()).join(', ')}
    ).toString()
  }
})

// Definice vlastních validačních pravidel
extend('date', {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  validate(value, { format }: Record<string, any>) {
    return value && DateTime.fromFormat(value, format).isValid;
  },
  message: (_, values) => i18n.t('common.validation.date', values).toString(),
  params: ['format']
})

extend('notBefore', {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  validate(value, { format }: Record<string, any>) {
    const current = DateTime.local();
    const date = DateTime.fromFormat(value, format);
    return current.startOf('day') <= date.startOf('day');
  },
  message: (_, values) => i18n.t('common.validation.notBefore', values).toString(),
  params: ['format']
})

extend('notToday', {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  validate(value, { format }: Record<string, any>) {
    const current = DateTime.local();
    const date = DateTime.fromFormat(value, format);
    return !current.startOf('day').equals(date.startOf('day'));
  },
  message: (_, values) => i18n.t('common.validation.notToday', values).toString(),
  params: ['format']
})

extend('notAfter', {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  validate(value, { format}: Record<string, any> ) {
    const current = DateTime.local();
    const date = DateTime.fromFormat(value, format);
    return current.startOf('day') >= date.startOf('day');
  },
  message: (_, values) => i18n.t('common.validation.notAfter', values).toString(),
  params: ['format']
})

extend('phone', {
  validate(value) {
    const phoneFormat = /^[+]*[(]?\d{1,4}[)]?[-\s./0-9]*$/
    return phoneFormat.test(value);
  },
  message: (_, values) => i18n.t('common.validation.phone', values).toString(),
})

extend('sefEmail', {
  validate(value) {
    const re = /[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{1,63}/;
    return re.test(value);
  },
  message: (_, values) => i18n.t('common.validation.email', values).toString(),
})

extend('sefIntegerPositive', {
  validate(value) {
    const re = /^(?:[1-9]\d*|0)$/;
    const valueInt = parseInt(value)
    return re.test(value) && valueInt <= INTEGER_MAX_VALUE;
  },
  message: (_, values) => i18n.t('common.validation.sefIntegerPositive', values).toString(),
})

extend('tagNonEmpty', {
  validate(value) {
    if (!value) return false;
    if (value instanceof Array) {
      for (const val of value) {
        if (typeof val === 'object') continue;
        if (val.trim().length === 0) return false;
      }
    }
    return true;
  },
  message: (_, values) => i18n.t('common.validation.tagNonEmpty', values).toString(),
})

extend('tagLength', {
  validate(value, args: Record<string, any>) {
    for (const val of value) {
      if (typeof val === 'object') continue;
      const trimValue = val.trim().replace(/\s+/g, ' ');
      if (trimValue.length >= args[0] && trimValue.length <= args[1]) continue
      else return false;
    }
    return true;
  },
  message: (_, args: Record<string, any>) => i18n.t('common.validation.tagLength',
      {min: args[0], max: args[1]}).toString(),
})

extend('sefUrl', {
  validate(value) {
    try {
      new URL(value);
      return true;
    }
    catch (error) {
      return false
    }
  },
  message: (_, values) => i18n.t('common.validation.url', values).toString(),
})

// Definice vlastních validačních pravidel - délka souboru v nějakém rozmezí
extend('fileNameLength', {
  validate(value: Array<File>, args: Record<string, any>) {
    let validateResult = true;
    value.forEach((file: File) => {
      validateResult = validateResult && file.name.length >= args[0] && file.name.length <= args[1];
    });
    return validateResult;
  },
  message: (_, args: Record<string, any>) => i18n.t(
      'common.validation.fileNameLength',
      {min: args[0], max: args[1]}).toString(),
})
