<template>
  <v-menu v-model="opened" :disabled="profiles.length === 0 || authorizationPhase" offset-y>
    <template #activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs"
           :class="{'signing-profile--authorizing' : authorizationPhase}" class="signing-profile--selected"
      >
        <div v-if="profiles.length > 0 || authorizationPhase">
          <div class="signing-profile__name">
            {{
              validProfileSelected || authorizationPhase ? selectedProfile.profileName :
                  $t('workflow.signingProfile.noSigSelected')
            }}
          </div>
          <div class="signing-profile__type">
            {{ validProfileSelected || authorizationPhase ? selectedProfile.signingMethodNameShort : '' }}
          </div>
        </div>
        <div v-else class="signing-profile__name">
          {{ $t('workflow.signingProfile.noSigAvailable') }}
        </div>
        <v-icon v-if="!authorizationPhase"
                v-text="'$arrowDown'"
                color="mutedText"
                :class="{ 'd-none': !areAnyProfilesAvailable(profiles) }"/>
      </div>
    </template>

    <v-list v-if="profiles && !authorizationPhase" class="signing-profile__list">
      <template v-if="dropdownList.length > 0">
        <template v-for="profile in dropdownList">
          <v-list-item
              :key="profile.profileId"
              :class="{'signing-profile__item--selected': isSelected(profile)}"
              @click="selectProfile(profile)">
            <div class="signing-profile__detail">
              <div class="signing-profile__name">{{ profile.profileName }}</div>
              <div class="signing-profile__type">{{ profile.signingMethodNameShort }}</div>
            </div>
          </v-list-item>
        </template>
      </template>
      <template v-else>
        <v-list-item>
          <div class="signing-profile__detail">
            <div class="signing-profile__name">{{ $t('workflow.signingProfile.noSigAvailableList') }}</div>
          </div>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import signingProfilesMixin from "@/mixins/signingProfilesMixin";

export default {
  name: 'FlowDetailProfileMenu',

  props: {
    authorizationPhase: Boolean,
    menuOpened: Boolean,
    profiles: {
      type: Array,
      default: undefined
    },
    selectedProfile: {
      type: Object,
      default: undefined
    }
  },

  mixins: [signingProfilesMixin],

  computed: {
    dropdownList() {
      return this.profiles;
    },
    opened: {
      get() {
        return this.menuOpened;
      },
      set(value) {
        this.$emit('profile-menu-opened', value);
      }
    },
    validProfileSelected() {
      return this.isValidProfileSelected(this.selectedProfile, this.profiles);
    }
  },

  methods: {
    selectProfile(profile) {
      if (this.isSelected(profile)) return;
      this.selectedProfile = profile;
      this.$emit("profile-select", profile)
    },
    isSelected(profile) {
      if (!this.selectedProfile) return false;
      return this.selectedProfile.profileId === profile.profileId;
    }
  }
}
</script>

<style scoped>
.signing-profile__list {
  max-height: 60vh;
  overflow-y: auto;
}

.signing-profile--selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.signing-profile__item--selected {
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--v-secondaryBackground-base);
}

.signing-profile__name {
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--v-secondaryText-base) !important;
  font-size: 0.75em;
  font-weight: 700;
}

.signing-profile__type {
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--v-secondaryText-base) !important;
  font-size: 0.75em;
}

.signing-profile--authorizing {
  cursor: default !important;
}
</style>
