




































import Vue, {PropType} from "vue";

export default Vue.extend({
  name: 'SOptionPanel',
  props: {
    description: String,
    disabled: Boolean,
    emptyText: String,
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    items: {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      required: true
    },
    label: String,
    value: {
      type: String,
      required: true
    },
  },
  computed: {
    localValue: {
      get(): number {
        let index: number =  this.items.findIndex(i => i[this.itemValue] === this.value);
        return index !== -1 ? index : 0;
      },
      set(value: number) {
        this.$emit('input', this.items[value][this.itemValue]);
      }
    },
  }
})
