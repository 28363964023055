

























import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import NotificationsContent from "@/component/notifications/NotificationsContent.vue";
import NotificationsHeader from "@/component/notifications/NotificationsHeader.vue";
import FlowDetail from "@/component/workflow/detail/FlowDetail.vue";
import FlowDetailDesktop from "@/component/workflow/detail/FlowDetailDesktop.vue";
import FlowDetailMobile from "@/component/workflow/detail/FlowDetailMobile.vue";
import OverviewLayout from "@/component/workflow/overview/OverviewLayout.vue";
import {DisplayVariant, Flow} from "@/types";
import {NotificationFolderType} from "@/types/notification";

export default mixins(displayMixin).extend({
  name: 'NotificationsView',
  components: {FlowDetail, NotificationsHeader, NotificationsContent, OverviewLayout},
  props: {
    folderId: String as PropType<NotificationFolderType>,
  },
  data() {
    return {
      detail: false
    }
  },
  computed: {
    flowSelected(): Flow|undefined {
      return this.$store.getters['workflow/flowActive'];
    },
    title(): string {
      switch (this.folderId) {
        case "processing":
          return "nav.notifications.items.inProgress";
        case "authorization":
          return "nav.notifications.items.auth";
        case "error":
          return "nav.notifications.items.error";
        default:
          return "Unknown"
      }
    },
    detailVariant() {
      switch (this.displayActive) {
        case DisplayVariant.MOBILE:
        case DisplayVariant.TABLET:
          return FlowDetailMobile;
        case DisplayVariant.DESKTOP:
        default:
          return FlowDetailDesktop;
      }
    },
  },
  methods: {
    closeDetail(): void {
      this.detail = false;
    },
    navOpen(): void {
      this.$emit('nav-open');
    },
  },
  watch: {
    flowSelected(value): void {
      if(value){
        this.detail = true;
      } else {
        this.detail = false;
      }
    }
  },
  created() {
    this.$store.commit('workflow/setFlowActive', {flowActive: undefined});
  }
})
