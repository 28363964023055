import {SignatureOnlineConfigProps, SignatureOnlinePhase} from "@/types/signatureOnline";

export enum SignatureExternalPhase {
  USER_NAME = 'user_name',
  USER_VISUALISATION = 'user_visualisation'
}

export type SignatureExternalPhaseExt = SignatureExternalPhase | SignatureOnlinePhase;

export type SignatureExternalConfig = {
  componentName: string,
  props: SignatureExternalConfigProps & SignatureOnlineConfigProps,
  listeners: object
}

export type SignatureExternalConfigProps = {
  firstName?: string,
  lastName?: string,
  name?: string,
  visualisation?: string
}