
































import {MutationPayload} from "vuex";
import {Visualisation} from "@/types";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import mutationMixin from "@/mixins/mutationMixin";
import SList from "@/component/ui/SList.vue";
import VisualisationListItem from "@/component/profile/visualisation/VisualisationListItem.vue";

export default mixins(displayMixin, mutationMixin).extend({
  name: 'VisualisationList',
  components: {SList, VisualisationListItem},
  props: {
    routingToNew: { // this prop is set from routing
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: undefined,
      isAddButtonAvailable: undefined as boolean|undefined,
    }
  },
  computed: {
    visuals(): Array<Visualisation> {
      let visuals = this.$store.getters['profile/visualisations'];
      return visuals ? visuals : [];
    }
  },
  created() {
    this.subscribe((mutation: MutationPayload) => {
      this.isVisualLinkAvailable();
      if (mutation.type === 'profile/removeVisualisation') {
        this.selected = undefined;
      }

      if (mutation.type === 'profile/editVisualisation') {
        this.$forceUpdate();
      }

      if (mutation.type === 'profile/selectVisualisation') {
        this.selected = mutation.payload.visualisation;
      }
    });

    this.$store.dispatch('profile/loadVisualisations');
    if (this.routingToNew) {
      setTimeout(this.add, 0);
    }
  },
  methods: {
    onVisualisationSelect(): void {
      if (this.$router.currentRoute.name === 'visualisation-new') {
        this.$router.push({name:'visualisation', query: {...this.$router.currentRoute.query}});
      }
      this.$store.commit('profile/selectVisualisation', {visualisation: this.selected});
      if (this.selected !== undefined) {
        this.$emit('show-detail')
      }
    },
    add(): void {
      this.selected = undefined;
      this.$store.commit('profile/createNewVisualisation');
      this.$emit('show-detail');
    },
    unselect(): void {
      this.selected = undefined;
    },

    isVisualLinkAvailable() {
      //Kontrola zda zobrazit tlačítko pro přidávání nové vizualizace
      let visualConfig = this.$store.getters["profile/visualConfigLinks"];
      if (visualConfig?.["sef:add-visual"]?.href) {
        this.isAddButtonAvailable = true;
      }
      else {
        this.isAddButtonAvailable = false;
      }
    },
  }
})
