











































import {AxiosError} from "axios";
import VueI18n from "vue-i18n";
import {MutationPayload} from "vuex";
import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import {VuetifyThemeItem} from "vuetify/types/services/theme";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SDialog from "@/component/ui/SDialog.vue";
import {NotificationType} from "@/utils/constants";

type LocalNotificationType = NotificationType | 'default';

interface MessageVariant {
  backgroundColor: string;
  textColor: string;
  header: VueI18n.TranslateResult,
  icon: string
}

export default mixins(mutationMixin).extend({
  name: 'GlobalNotification',
  components: {STextButton, SDialog},

  data() {
    return {
      show: false,
      type: 'default' as LocalNotificationType,
      message: undefined as string | undefined,
      header: undefined as string | undefined,
      dialog: false,
      reportError: false,
      error: undefined as AxiosError | undefined
    }
  },

  computed: {

    variants(): { [key in LocalNotificationType]: MessageVariant } {
      return {
        default: {
          backgroundColor: 'black',
          textColor: 'white--text',
          header: this.$t('alerts.default'),
          icon: 'info'
        },
        error: {
          backgroundColor: 'mainContrast',
          textColor: 'mainContrastText--text',
          header: this.$t('alerts.error'),
          icon: 'error'
        },
        info: {
          backgroundColor: 'secondaryContrast',
          textColor: 'secondaryContrastText--text',
          header: this.$t('alerts.info'),
          icon: 'info'
        },
        success: {
          backgroundColor: 'success',
          textColor: 'successText--text',
          header: this.$t('alerts.success'),
          icon: 'success'
        }
      }
    },

    backgroundColor(): string | VuetifyThemeItem {
      return this.variants[this.type].backgroundColor;
    },

    textColor(): string | VuetifyThemeItem {
      return this.variants[this.type].textColor;
    },

    icon(): string {
      return this.variants[this.type].icon;
    },

    headerDialog(): VueI18n.TranslateResult {
      return this.header ? this.header : this.variants[this.type].header;
    },
    isSupportEnabled(): boolean {
      return this.$store.getters["config/supportEnabled"];
    }

  },

  methods: {
    report(): void {
      this.show = false;
      this.dialog = false; //změna
      this.$store.commit("notification/setNotificationError", {error: this.error});
      this.$router.replace({name: 'profile-support'}).catch(() => {/**/});
    }
  },

  created(): void {
    this.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'notification/showMessage') {
        this.message = mutation.payload.content;
        this.header = mutation.payload.header;
        this.type = mutation.payload.type ? mutation.payload.type : "default";
        this.reportError = mutation.payload.type === NotificationType.ERROR && mutation.payload.report;
        this.error = mutation.payload.error;
        this.show = true
      }
    })
  }

})
