import {vuetify} from "@/plugin/vuetify";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {apiThemeGet} from "@/api/themeApi";
import {RootState} from "@/store/store";
import {CssCustomization, ThemeConfig, ThemeFullGuiCustomization, ThemeOnlineSignatureCustomization} from "@/types";

interface ThemeState {
  cssCustomization?: CssCustomization;
  mainContrastColor?: string;
  mainContrastTextColor?: string;
  secondaryContrastColor?: string;
  secondaryContrastTextColor?: string;
  successColor?: string;
  successTextColor?: string;
  mode: string;
  pageTitle?: string;
  onlineSignatureCustomization: ThemeOnlineSignatureCustomization;
  fullGuiCustomization: ThemeFullGuiCustomization;
}

const state = (): ThemeState => ({
  // Custom Css
  cssCustomization: undefined,
  // Kontrastní barvy
  mainContrastColor: undefined,
  mainContrastTextColor: undefined,
  secondaryContrastColor: undefined,
  secondaryContrastTextColor: undefined,
  successColor: undefined,
  successTextColor: undefined,
  // Světlý/tmavý motiv aplikace
  mode: 'auto', // auto, dark, light
  // Název stránky
  pageTitle: undefined,

  onlineSignatureCustomization: {
    showAccountInfo: true,
    showEditSigningProfile: true,
    showLogo: true,
    documentDetail: {
      showDueDate: true,
      showOwnerInfo: true,
      showPageSwitch: true
    }
  },
  fullGuiCustomization: {
    documentDetail: {
      showDueDate: true,
      showOwnerInfo: true,
      showPageSwitch: true
    }
  },
})

const mutations: MutationTree<ThemeState> = {
  setThemeConfig(state, payload: ThemeConfig) {
    state.cssCustomization = payload.cssCustomization;
    state.mainContrastColor = payload.mainContrastColor;
    state.mainContrastTextColor = payload.mainContrastTextColor;
    state.secondaryContrastColor = payload.secondaryContrastColor;
    state.secondaryContrastTextColor = payload.secondaryContrastTextColor;
    state.successColor = payload.successColor;
    state.successTextColor = payload.successTextColor;
    state.mode = payload.mode ?? 'auto';
    state.pageTitle = payload.pageTitle;

    if (payload.themeFullGuiCustomization)
      state.fullGuiCustomization = payload.themeFullGuiCustomization;

    if (payload.themeOnlineSignatureCustomization)
      state.onlineSignatureCustomization = payload.themeOnlineSignatureCustomization;
  },
}

const actions: ActionTree<ThemeState, RootState> = {
  async loadTheme({dispatch}): Promise<void> {
    try {
      const response = await apiThemeGet();
      dispatch('setTheme', response);
    }
    catch (e) {
      console.error('An error occurred while loading theme config', e);
    }
  },
  setTheme({commit, state}, payload: ThemeConfig) {
    commit('setThemeConfig', payload);
    // Nastavení motivu světlý/tmavý
    switch (state.mode) {
      case 'light':
        vuetify.framework.theme.dark = false;
        break;
      case 'dark':
        vuetify.framework.theme.dark = true;
        break;
      case 'auto':
      default:
        // detekce, zda operační systém uživatele preferuje tmavý motiv
        vuetify.framework.theme.dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    // Nastavení kontrastních barev pro tmavý motiv
    const dark = vuetify.framework.theme.themes.dark;

    dark.mainContrast = state.mainContrastColor ?? dark.mainContrast;
    dark.mainContrastText = state.mainContrastTextColor ?? dark.mainContrastText;

    dark.secondaryContrast = state.secondaryContrastColor ?? dark.secondaryContrast;
    dark.secondaryContrastText = state.secondaryContrastTextColor ?? dark.secondaryContrastText;

    dark.success = state.successColor ?? dark.success;
    dark.successText = state.successTextColor ?? dark.successText;

    // Nastavení kontrastních barev pro světlý motiv
    const light = vuetify.framework.theme.themes.light;

    light.mainContrast = state.mainContrastColor ?? light.mainContrast;
    light.mainContrastText = state.mainContrastTextColor ?? light.mainContrastText;

    light.secondaryContrast = state.secondaryContrastColor ?? light.secondaryContrast;
    light.secondaryContrastText = state.secondaryContrastTextColor ?? light.secondaryContrastText;

    light.success = state.successColor ?? light.success;
    if (state.successColor)
      light.successText = state.successTextColor ?? light.successText;

  }
}

const getters: GetterTree<ThemeState, RootState> = {
  cssCustomization: (state): CssCustomization | undefined => {
    return state.cssCustomization;
  },
  fullGuiCustomization: (state): ThemeFullGuiCustomization => {
    return state.fullGuiCustomization;
  },
  onlineGuiCustomization: (state): ThemeOnlineSignatureCustomization => {
    return state.onlineSignatureCustomization;
  },
  pageTitle: (state): string | undefined => {
    return state.pageTitle;
  }
}

export const theme: Module<ThemeState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};