



































































import {AccountSearchKeyword, CategorySearchKeyword, SearchKeyword, SearchKeywordsDTO, Tag, UserAccount} from "@/types";
import {SearchAutocompleteResponse} from "@/types/dto";
import debounce from "lodash/debounce";
import {nameToDisplay} from "@/utils/accountUtils";
import {AUTOCOMPLETE_MIN_LENGTH} from "@/utils/constants";
import {AUTOCOMPLETE} from "@/utils/urls";
import {toArray} from "@/utils/utils";
import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import displayMixin from "@/mixins/displayMixin";

export default mixins(mutationMixin, displayMixin).extend({
  name: 'OverviewSearch',
  components: {SIconButton},
  data(){
    return {
      search: '' as string,
      items: [] as Array<SearchKeyword|AccountSearchKeyword|CategorySearchKeyword>,
      selectedItems: [] as Array<SearchKeyword|AccountSearchKeyword|CategorySearchKeyword|string>,
      loading: false,
      loadItemsDebouncedFunc: () => {/**/}
    }
  },
  methods: {
    async loadItems(): Promise<void>{
      if (!this.search) this.items = [];
      if (this.search.trim().length < AUTOCOMPLETE_MIN_LENGTH) return;
      if(this.loading) return;
      this.loading = true;

      try {
        let url = AUTOCOMPLETE + '?text=' + encodeURIComponent(this.search);
        let response = (await this.axios.get(url)).data as SearchAutocompleteResponse;
        this.items = this.parseDto(response);
        this.loading = false;
      } catch (ignore) {
        /**/
      } finally {
        this.loading = false;
      }
    },
    parseDto(dto: SearchAutocompleteResponse): Array<SearchKeyword|AccountSearchKeyword|CategorySearchKeyword> {
      const result: Array<SearchKeyword|AccountSearchKeyword|CategorySearchKeyword> = [];
      // Subjects
      for (const subject of dto.subjects){
        result.push({
          text: subject,
          value: 'subject_' + subject,
          subText: 'Dokument',
          type: 'subject',
          icon: 'file'
        })
      }
      // Accounts
      for (const account of dto._embedded.accounts){
        result.push({
          text: nameToDisplay(account as UserAccount),
          value: 'account_' + nameToDisplay(account as UserAccount),
          subText: account.userEmail,
          accountName: account.accountName,
          type: 'account',
          icon: 'user',
        })
      }
      // Tags
      for (const tag of dto._embedded.tags){
        result.push({
          text: tag.text,
          value: 'tag_' + tag.text,
          subText: 'Tag',
          type: 'tag',
          icon: 'tag',
        })
      }
      return result;
    },
    onChange(): void {
      this.items = [];
      this.commitSearch();
    },
    commitSearch(): void {
      if(this.selectedItems.length === 0){
        this.$router.replace({path: '/overview'}).catch(() => {/**/})
      } else {
        const params = this.createUriParams();
        this.$router.replace({
          path: '/overview',
          query: {
            search: "true",
            account: params.account,
            subject: params.subject,
            tag: params.tag
          } });
      }
    },
    createUriParams(): SearchKeywordsDTO{
      const result = {
        category: null as string|null,
        account: [] as Array<string>,
        subject: [] as Array<string>,
        tag: [] as Array<string>
      };

      for (const data of this.selectedItems){
        if(typeof data !== "object"){
          result.subject.push(data);
          continue;
        }
        switch (data.type){
          case 'subject':
            result.subject.push(data.text);
            break;
          case 'account':
            result.account.push((data as AccountSearchKeyword).accountName)
            break;
          case 'tag':
            result.tag.push(data.text);
            break;
          case 'category':
            result.category = (data as CategorySearchKeyword).categoryCode;
            break;
        }
      }
      return result;
    },
    addSubjectItem(item: string){
      if(!item) return;
      this.selectedItems.push({
        text: item,
        value: 'subject_' + item,
        subText: 'Dokument',
        type: 'subject',
        icon: 'file'
      });
      this.items = [];
      this.search = '';
      this.commitSearch();
    },
    remove (item: SearchKeyword): void {
      const index = this.selectedItems.indexOf(item);
      if (index >= 0) this.selectedItems.splice(index, 1)
      this.onChange();
    },
    clear(): void {
      this.search = '';
      this.items = [];
      this.selectedItems = [];
    },
    init(query: SearchKeywordsDTO): void {
      let subjects = toArray(query.subject);
      let accounts = toArray(query.account).map(account => {return {accountName: account}});
      let tags: Array<Tag> = toArray(query.tag).map(tag => {return {text: tag}});
      const dto: SearchAutocompleteResponse = {
        _links: {},
        _embedded: {
          tags: tags,
          accounts: accounts
        },
        subjects: subjects
      }
      this.selectedItems = this.parseDto(dto);
    }
  },
  watch: {
    search(value) {
      if (!value || value.length < AUTOCOMPLETE_MIN_LENGTH) {
        return
      }
      this.loadItemsDebouncedFunc();
    }
  },
  created(){
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let vm = this;
    this.loadItemsDebouncedFunc = debounce(function () {
      vm.loadItems();
    }, 200)

    if(this.$route.query.search){
      this.init(this.$route.query);
    }

    this.subscribe((mutation) => {
      if (mutation.type === 'navigation/clearSearch') {
        this.clear();
      }
    });
  }
})
