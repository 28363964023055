import axios from "axios";
import {IMAGE_DATA_URI_PREFIX} from "@/utils/constants";
import Vue from "vue";

interface AvatarStorageValue {
    key: string|undefined;
}

export default Vue.extend({
    methods: {
        async getUserAvatar(avatarLink: string): Promise<string|undefined> {
            if(!avatarLink) return undefined;
            if(this.$storage.has(avatarLink)){
                // Load avatar from local storage
                return (this.$storage.get(avatarLink) as AvatarStorageValue)?.key
            } else {
                // Fetch avatar from API and store it in local storage
                let avatar: string|undefined;
                let requestFail = false;
                try {
                    const response = await axios.get(avatarLink, {responseType: 'arraybuffer'});
                    if(response.data.byteLength > 0){
                        avatar = IMAGE_DATA_URI_PREFIX + Buffer.from(response.data, 'binary').toString('base64')
                    } else {
                        avatar = undefined;
                    }
                } catch (e) {
                    avatar = undefined;
                    requestFail = true;
                }
                // If request fails, then reload after 1 minute, 1 hour otherwise
                const ttl = requestFail ? 60 * 1000 : 60 * 60 * 1000;
                this.$storage.set(avatarLink, {key: avatar}, { ttl: ttl})

                return avatar;
            }
        }
    }
})
