








































import mixins from "vue-typed-mixins";
import clientDownloadMixin from "@/client/clientDownloadMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SDialog from "@/component/ui/SDialog.vue";

export default mixins(clientDownloadMixin).extend({
  name: 'ClientSigningDialog',

  components: {SDialog, STextButton},

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  }
})
