import {HalResource, Link} from "@/types/hal";
import {ReportRow} from "@/types/signature";
import {UserAccount} from "@/types/user";
import {FlowStep} from "@/types/workflow";

export enum BatchCreationMode {
  DO_NOT_CREATE = 'do_not_create',
  IGNORE_ERRORS = 'ignore_errors',
  WITHOUT_ERRORS_ONLY = 'without_errors_only',
}

export enum BatchProcessedAction {
  REJECT = 'reject',
  SIGN = 'sign',
  SIGNATURE_CHANGE = 'signature_change',
}

export enum BatchState {
  ACTION_REQUIRED = 'action_required',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  WITH_ERRORS = 'with_errors',
}

export interface Batch extends HalResource<BatchEmbedded, BatchLinks> {
  batchId: number;
  bulkMode: boolean;
  reportRows: Array<ReportRow>;
  batchName: string;
  batchState: BatchState;
  flowSubject: string;
  changedAt: string;
  count: number;
  createdAt: string;
  creationMode: BatchCreationMode;
  signatureImage?: string;
}

export interface BatchCreatePayload {
  batchName: string;
  flowIds: Array<number>;
  flowsPath?: string;
  stepIds: Array<number>;
  signatureImage?: string;
}

interface BatchEmbedded {
  account: UserAccount;
  steps: Array<FlowStep>;
}

interface BatchLinks {
  self: Link;
}

export interface BatchInProgress {
  batch: Batch;
  checkTimeoutId: number;
  flowIds: Array<number>;
  flowsPath?: string;
}

export interface BatchProcessed {
  action: BatchProcessedAction;
  batch?: Batch;
  flowIds: Array<number>;
  flowsPath?: string;
}

// TODO: Přidat sem ReportRow z signature.ts
// TODO: Přidat sem i všechny ostatní věci, které se týkají dávek