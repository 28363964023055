

















































import {MutationPayload} from "vuex";
import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import {apiFoldersGet} from "@/api/foldersApi";
import {Category, Folder, FolderMode, FolderSpacing, itemCountDisplayModes} from "@/types";
import {flatFolders} from "@/utils/folderUtils";
import {flattenHierarchy} from "@/utils/utils";

interface TreeItem {
  id: number | string;
  name: string;
  folder: Folder;
  level: number;
  url: string;
  showContent: boolean;
  spacing: FolderSpacing;
  children?: Array<TreeItem>;
}

const processFolder = function (folder: Folder, rootUrl: string, level = 0): TreeItem {
  const url = `${rootUrl}/${folder.folderId}`;
  const treeItem: TreeItem = {
    id: folder.folderId,
    name: folder.folderName,
    folder,
    level,
    url,
    showContent: folder.showContent,
    spacing: folder.spacing
  };
  if (folder._embedded?.nestedFolders) {
    treeItem.children = [];
    folder._embedded?.nestedFolders.forEach(nestedFolder => {
      treeItem.children?.push(processFolder(nestedFolder, rootUrl, level + 1));
    });
  }
  return treeItem;
}

const getOpen = (item: TreeItem, needle: number): Array<TreeItem> => {
  if (item.id === needle)
    return [item];

  if (item.children) {
    const openedChildren = item.children.flatMap(child => getOpen(child, needle));
    if (openedChildren.length > 0)
      return [item].concat(openedChildren);
  }
  return [];
}

export default mixins(mutationMixin).extend({
  name: 'FoldersMenu',

  props: {
    dense: Boolean,
    iconLevels: {
      type: Array,
      default: () => [1]
    },
    rootUrl: {
      type: String,
      default: '/overview'
    },
  },

  data() {
    return {
      treeModel: [] as Array<TreeItem>,
      openFolders: [] as Array<TreeItem>,
      active: [] as Array<TreeItem>,
      itemCounts: {} as Record<number, number | undefined>,
      highlightedItemCounts: {} as Record<number, number | undefined>,
      errorCounts: {} as Record<number, number | undefined>,
      programmaticBatchModeChange: false
    }
  },

  computed: {
    batchMode: {
      get(): boolean {
        return this.$store.getters['workflow/batchMode'];
      },
      set(value: boolean) {
        this.$store.commit('workflow/setBatchMode', {batchMode: value});
      }
    },
    selectedFolderId: {
      get(): number | undefined {
        return this.$store.getters['navigation/selectedFolderId'];
      },
      set(value: number | undefined) {
        this.$store.commit('navigation/setSelectedFolderId', {folderId: value});
      }
    },
    folderMode(): FolderMode {
      return this.batchMode ? FolderMode.BATCH : FolderMode.STANDARD;
    },
    folders(): Array<Folder> {
      return this.$store.getters['navigation/folders'];
    },
    foldersFlat(): Array<Folder> {
      return this.$store.getters['navigation/foldersFlat'];
    },
    search(): boolean {
      return this.$route.query.search === "true";
    }
  },

  watch: {
    active: function (newValue: Array<TreeItem>, oldValue: Array<TreeItem>): void {
      if (newValue.length > 0 && newValue[0]) {
        this.navigateTo(newValue[0].url);
        this.$store.commit('navigation/clearSearch');
      }
      if (!oldValue) {
        this.$emit('select');
      }
    },
    async folderMode(value: FolderMode): Promise<void> {
      if (!this.programmaticBatchModeChange)
        this.selectedFolderId = undefined;

      this.programmaticBatchModeChange = false;
      await this.$store.dispatch('navigation/loadFolders', {folderMode: value});
    },
    async folders(value: Array<Folder>): Promise<void> {
      await this.initTreeview(value);

      const selectedFolderExists = await this.folderExists(this.selectedFolderId);

      if (this.search)
        return;
      else if (this.selectedFolderId && !!selectedFolderExists) {
        if (selectedFolderExists === this.folderMode) {
          this.setActiveFolder(this.selectedFolderId);
        }
        else {
          /* Pokud složka existuje v jiném režimu, přepne se do ní */
          this.programmaticBatchModeChange = true;
          this.batchMode = selectedFolderExists === FolderMode.BATCH;
        }
      }
      else {
        this.selectDefaultActiveFolder();
      }
    },
    async $route() {
      // Refresh only after router change. Not after clicking on one of the folders

      // Uložení identifikátoru aktuálně vybrané složky
      this.selectedFolderId = this.getSelectedFolderId();

      const selectedFolderExists = await this.folderExists(this.selectedFolderId);

      if (this.selectedFolderId && !!selectedFolderExists) {
        if (selectedFolderExists === this.folderMode) {
          this.setActiveFolder(this.selectedFolderId);
        }
        else {
          /* Pokud složka existuje v jiném režimu, přepne se do ní */
          this.programmaticBatchModeChange = true;
          this.batchMode = selectedFolderExists === FolderMode.BATCH;
        }
      }
      else {
        this.active = [];
      }
    }
  },

  async created() {
    this.subscribe(async (mutation: MutationPayload) => {
      if (mutation.type === 'navigation/foldersRefresh') {
        await this.$store.dispatch('navigation/loadFolders', {folderMode: this.folderMode});
      }
      if (mutation.type === 'navigation/refreshCounters') {
        await this.refreshCounters();
      }
    });

    // Uložení identifikátoru aktuálně vybrané složky
    this.selectedFolderId = this.getSelectedFolderId();

    await this.$store.dispatch('navigation/loadFolders', {folderMode: this.folderMode});
  },

  updated() {
    this.createSpacing();
  },

  beforeDestroy() {
    this.selectedFolderId = undefined;
  },

  methods: {
    async initTreeview(folders: Array<Folder>): Promise<void> {
      this.treeModel = folders.map(folder => processFolder(folder, this.rootUrl));

      const errorCounts = {} as Record<number | string, number | undefined>
      const highlightedItemCounts = {} as Record<number | string, number | undefined>
      const itemCounts = {} as Record<number | string, number | undefined>

      const saveCounts = (item: TreeItem) => {
        errorCounts[item.id] = item.folder.errorCount;
        highlightedItemCounts[item.id] = item.folder.highlightedItemCount;
        itemCounts[item.id] = item.folder.itemCount;
        if (item.children && item.children.length > 0) {
          item.children.forEach(saveCounts);
        }
      }

      this.treeModel.forEach(saveCounts);
      this.errorCounts = errorCounts;
      this.highlightedItemCounts = highlightedItemCounts;
      this.itemCounts = itemCounts;
    },
    navCategoryIcon(folder: Folder): string | undefined {
      const navCategories = this.$store.getters['navigation/navCategories'];
      return navCategories[folder.state].icon;
    },
    navigateTo(path: string): void {
      if (this.$router.currentRoute.path !== path) {
        // We need to keep query parameter "online-signature-redirect-uri" across all router views
        let queryParams = {'online-signature-redirect-uri': this.$router.currentRoute.query['online-signature-redirect-uri']}
        this.$router.push({path: path, query: queryParams});
      }
    },
    updateActive(value: Array<TreeItem>): void {
      let preventChangeToEmpty = false;
      if (this.active.length > 0 && value.length === 0) {
        const selectedFolderId = this.selectedFolderId;
        if (selectedFolderId === undefined) {
          this.active = [];
          return;
        }

        const selectedFolderPath = this.treeModel.flatMap(item => getOpen(item, selectedFolderId));
        if (selectedFolderPath.length === 0) {
          // in case when the folder we were in doesn't exists anymore, we allow active to be empty
          // absence of this condition can lead to infinity loop and freeze the GUI
          // todo this situation should be handled in a better way than just saying that nothing is active
          this.active = [];
          return;
        }
        preventChangeToEmpty = true;
      }

      // we don't allow user to set no active folders and to open a folder with showContent set to false
      if (preventChangeToEmpty || !value[0]?.showContent) {

        // The next line is a workaround for the v-treeview. The v-treeview has prop active, that is syncable
        // and we use this function to prevent certain folders from being activated. However the v-treeview has
        // an inner state that holds active items as well, so even if we stop this here, the inner state of the
        // component records the folder as activated. By copying the array, we change the reference and
        // it's handled as an prop change and the v-treeview syncs its inner state with the prop.
        // TL;DR the v-treeview activates the folder even if we don't want to, so we force it to change
        // its active folders back by changing the prop reference.
        this.active = [...this.active];

        const targetFolder = preventChangeToEmpty ? this.active[0] : value[0]
        if (targetFolder.children && targetFolder.children.length > 0) {
          const isOpen = this.openFolders.some(openFolder => openFolder.id === targetFolder.id);
          if (isOpen) {
            this.closeFolder(targetFolder)
          }
          else {
            this.openFolders.push(targetFolder);
          }
        }
        return;
      }
      this.active = value;
    },
    handleItemClick(event: Event, item: TreeItem, active: boolean): void {
      if (active) {
        this.$emit('select')
        // event.stopPropagation used to be here, but it is not necessary since we handle an empty active items array in
        // the updateActive handler. Also the open/close mechanism lives there and by stopping the event here, we would
        // prevent the mechanism from working.
      }

    },
    closeFolder(folder: TreeItem) {
      // in case of huge number of folders and/or deep hierarchy, we might need to find a more performant solution
      const result = flattenHierarchy(folder, 'children').map(it => it.id);
      this.openFolders = this.openFolders.filter(folder => !result.includes(folder.id))
    },
    async refreshCounters(): Promise<void> {
      const folders = await this.$store.dispatch('navigation/loadFolders', {folderMode: FolderMode.COUNTERS});

      const errorCounts = {} as Record<number | string, number | undefined>
      const highlightedItemCounts = {} as Record<number | string, number | undefined>
      const itemCounts = {} as Record<number | string, number | undefined>

      folders.forEach((folder: Folder) => {
        errorCounts[folder.folderId] = folder.errorCount;
        highlightedItemCounts[folder.folderId] = folder.highlightedItemCount;
        itemCounts[folder.folderId] = folder.itemCount;
      })

      this.errorCounts = errorCounts;
      this.highlightedItemCounts = highlightedItemCounts;
      this.itemCounts = itemCounts;
    },
    showCountToDisplay(folderId: number, folderCountToDisplay: string): number | undefined {
      /*
      Změna v provedení zobrazení správných čítačů - funkce init a refreshCounters fungují stejně jako na začátku
      a tady se rozhoduje podle kódu jaký čítač zobrazit.
       */
      if (folderCountToDisplay == itemCountDisplayModes.TOTAL) {
        return this.itemCounts[folderId];
      }
      else if (folderCountToDisplay == itemCountDisplayModes.HIGHLIGHTED) {
        return this.highlightedItemCounts[folderId];
      }
      else if (folderCountToDisplay == itemCountDisplayModes.NONE) {
        return undefined
      }
      else {
        return undefined
      }
    },
    itemCount(folderId: number): number | undefined {
      return this.itemCounts[folderId];
    },
    highlightedItemCount(folderId: number): number | undefined {
      return this.highlightedItemCounts[folderId];
    },
    errorCount(folderId: number): number | undefined {
      return this.errorCounts[folderId];
    },
    createSpacing(): void {
      let separatorLabels = this.$el.querySelectorAll('.spacing')
      separatorLabels.forEach(s => {
        let node = s.closest('.v-treeview-node');
        if (!node) return;
        if (s.classList.contains("spacing-top")) {
          if (!node.classList.contains('spacing-top')) node.classList.add('spacing-top')
        }
        else if (s.classList.contains("spacing-bottom")) {
          if (!node.classList.contains('spacing-bottom')) node.classList.add('spacing-bottom')
        }
        else if (s.classList.contains("spacing-both")) {
          if (!node.classList.contains('spacing-both')) node.classList.add('spacing-both')
        }
      });
    },
    itemLabelClass(item: TreeItem, active: boolean) {
      return {
        'selected-bold': active,
        'category-style': !item.showContent,
        'folder-style': item.showContent,
        'spacing': item.spacing !== "none",
        'spacing-top': item.spacing === "margin_top",
        'spacing-bottom': item.spacing === "margin_bottom",
        'spacing-both': item.spacing === "margin_both"
      }
    },
    async folderExists(folderId: number | undefined): Promise<FolderMode | undefined> {
      if (folderId === undefined)
        return undefined;

      const activeFolderMode = this.folderMode;
      const activeFoldersContainId = this.foldersFlat.some(folder => folder.folderId === folderId);
      if (activeFoldersContainId) {
        return activeFolderMode;
      }
      else {
        /* Pokud uvedená složka v daném režimu neexistuje, ověříme, zda existuje v jiném režimu */
        const otherFolderMode = activeFolderMode === FolderMode.STANDARD ? FolderMode.BATCH : FolderMode.STANDARD;
        const otherFolders = await apiFoldersGet(otherFolderMode);
        const otherFoldersFlat = flatFolders(otherFolders) ?? [];
        const otherFoldersContainId = otherFoldersFlat.some(folder => folder.folderId === folderId);
        return otherFoldersContainId ? otherFolderMode : undefined;
      }
    },
    getSelectedFolderId(): number | undefined {
      const currentPath = this.$route.path;
      const folderPathRegex = /\/overview\/(\d+)/;
      const selectedFolderIdText = currentPath.match(folderPathRegex)?.[1];
      const selectedFolderId = Number(selectedFolderIdText);
      return Number.isNaN(selectedFolderId) ? undefined : selectedFolderId;
    },
    selectDefaultActiveFolder(): void {
      const folders = this.$store.getters['navigation/foldersFlat'];
      for (const folder of folders) {
        if (folder.showContent) {
          const currentPath = this.$route.path;
          const newPath = `/overview/${folder.folderId}`;
          if (newPath !== currentPath) {
            this.$router.replace(`/overview/${folder.folderId}`).catch(e => {
              console.error(`Error occurred while navigating to the folder ${folder.folderId}`, e);
            });
          }
          return;
        }
      }
    },
    setActiveFolder(folderId: number): void {
      let folderPath = this.treeModel.flatMap((item: TreeItem) => getOpen(item, folderId));

      if (folderPath.length > 0) {
        // Merge open and active folders
        for (const folder of folderPath) {
          if (this.openFolders.findIndex(openFolder => openFolder.id === folder.id) === -1)
            this.openFolders.push(folder);
        }

        if (this.openFolders.length > 0) {
          this.active = [folderPath[folderPath.length - 1]];
          this.setVisitedCategory(folderId);
        }
      }
    },
    setVisitedCategory(folderId: number): void {
      const folders = this.$store.getters['navigation/foldersFlat'];
      const folderCategory = folders.find(
          (folder: Folder) => folder._embedded?.category && folder.folderId === folderId);
      if (folderCategory) {
        const categories = this.$store.getters['config/categories'];
        const category = categories.find(
            (category: Category) => folderCategory._embedded?.category?.categoryCode === category.categoryCode);
        if (category)
          this.$store.commit('navigation/setLastVisitedCategory', {category: category});
      }
    }
  }
})
