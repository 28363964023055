// Navigation
export const NAVIGATION = "/folders";

// Business Config
export const BUSINESS_CONFIG = "/business-config"
export const CATEGORIES = "/business-config/categories";

// Workflows
export const WORKFLOW_HIGHLIGHT = "internal/workflows/highlighted/"

// Search
export const AUTOCOMPLETE = "/autocomplete-tips"
export const SEARCH = "internal/workflows"

// Signature
export const SIGNATURE_VISUAL = "/visualSignature"
//export const SIGNATURE_BUSINESS_LEVEL = "/business-levels"

// Profile
export const USER_LOCALE = "/user-profile/locale"
export const PERSONAL_INFO = "/user-profile/personal-info"
export const PHONE_NUMBER = "/user-profile/phone-number"
export const VISUALISATION = "/user-profile/visuals"
export const SIGNATURE_METHODS = "/signature-provider-types"
export const SIGNING_PROFILES = "/user-profile/signing-profiles"
export const CREATE_USER = "/accounts"
export const BANK_LIST = "/providers/rs-bankid/banks"

// Batches
export const BATCHES = "/batches"
export const FLOW_STEPS_BULK = "/flow-steps/bulk"

// Interaction center
export const BATCHES_AUTHORIZATION = "/batches/authorization-required"
export const BATCHES_FAILED = "/batches/failed"
export const BATCHES_IN_PROGRESS = "/batches/in-progress"

// Client signing
export const CLIENT_SESSION = "/client-signing/session"
export const CLIENT_SMARTCARDS = "/client-signing/smartcard-infos?platform="

// Identity providers
export const IDENTITY_PROVIDERS = "/user-profile/identity-providers"

// Support
export const SUPPORT_TICKETS = "/support/tickets"

// Quick actions
export const QUICK_ACTIONS = "/quick-actions"
