import {DateTime} from "luxon";
import {i18n} from "@/plugin/i18n";

export function formatDate(date: Date|string, format: string){
    if(date instanceof Date){
        // Date object
        return DateTime.fromJSDate(date).toFormat(format);
    } else {
        // ISO String
        return DateTime.fromISO(date).toFormat(format);
    }
}

export function isSameDay(date1: Date, date2: Date){
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
}

export function isNextDay(date1: Date, date2: Date){
    const tomorrow = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + 1);
    return isSameDay(tomorrow, date2)
}

export function isDayBefore(date1: Date, date2: Date){
    const yesterday = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() - 1);
    return isSameDay(yesterday, date2)
}

export function isBeforeNotSame(date1: Date, date2: Date){
    return date1 < date2 && !isSameDay(date1, date2);
}

export function isLaterNotSame(date1: Date, date2: Date){
    return date1 > date2 && !isSameDay(date1, date2);
}

export function isBeforeNotSameNotYesterday(date1: Date, date2: Date){
    return date1 < date2 && !isSameDay(date1, date2)  && !isDayBefore(date1, date2);
}

export function isLaterNotSameNotNextDay(date1: Date, date2: Date){
    return date1 > date2 && !isSameDay(date1, date2)  && !isNextDay(date1, date2);
}

export function formatDateShort(value: string){
    const now = new Date();
    const date = new Date(value);

    let formattedDate;
    if(now.getFullYear() === date.getFullYear()){
        // Current year
        const temp = formatDate(date, "ccc d. L.")
        let day = temp.split(" ")[0];
        const key = "docList.days." + day;
        day = i18n.t(key).toString();
        formattedDate = day + " " + temp.substring(temp.search(" "));
    } else {
        // Previous year
        formattedDate = formatDate(date, "d. L. yy");
    }

    return formattedDate;
}
