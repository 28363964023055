








































import Vue from "vue";
import SignatureContentLayout from "@/component/signature/SignatureContentLayout.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SSignatureSelector from "@/component/ui/form/SSignatureSelector.vue";
import {convertTextToImage} from "@/utils/utils";

export default Vue.extend({
  name: 'SignatureExternalUserVisualisation',

  components: {SSignatureSelector, SignatureContentLayout, STextButton},

  props: {
    name: {
      type: String,
      required: true
    },
    visualisation: {
      type: String,
      required: true
    }
  },

  computed: {
    localVisualisation: {
      get(): string | undefined {
        return this.visualisation;
      },
      set(value: string | undefined) {
        this.$emit('update:visualisation', value);
      }
    }
  },
  data(){
    return{
      predefinedVisualization:this.visualisation,
    }
  },

  created() {
    if (!this.localVisualisation){
      this.localVisualisation = convertTextToImage(this.name, 300, 150).split(',')[1];
    }
  },

  methods: {
    back(): void {
      this.$emit('back');
    },
    async next(): Promise<void> {
      let signatureValid = await (this.$refs.visualisationSelector as InstanceType<typeof SSignatureSelector>).validate();
      if(!signatureValid) return;
      this.$emit('next');
    }
  }
})
