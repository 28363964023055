






import {PropType} from "vue";

export default {
  name: "STextList",
  props: {
    text: {
      type: Array as PropType<string[]>,
      required: true,
    },
    endSeparator: String,
    middleSeparator: String
  },
  methods: {
    formatText(text: string[], endSeparator: string, middleSeparator: string): string {
      let finalText = " ";
      for (let index = 0; index < text.length; index++) {
        finalText += text[index];
        if (index < text.length - 1) {
          if (text.length <= 2) {
            if (index === text.length - 2 && text.length > 2) {
              finalText += middleSeparator;
            }
            else {
              finalText += endSeparator;
            }
          }
          else if (text.length >= 3) {
            if (index === text.length - 2 && text.length > 2) {
              finalText += endSeparator;
            }
            else {
              finalText += middleSeparator;
            }
          }
        }
      }
      return finalText
    }
  }
}
