import {VuetifyThemeVariant} from "vuetify/types/services/theme";

export const light = {
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#47CA86',
  warning: '#FFC107',
  // Text
  mainText: '#374355',
  mutedText: '#ADB9C9',
  secondaryText: '#53657D',
  successText: '#FFFFFF',
  // Contrast background
  mainContrast: '#D86985',
  mutedContrast: '#6B7380',
  secondaryContrast: '#4CA7E2',
  // Contrast text
  mainContrastText: '#FFFFFF',
  secondaryContrastText: '#FFFFFF',
  // Common background colors
  defaultBackground: '#FFFFFF', // výchozí pozadí Vuetify pro světlý motiv
  mainBackground: '#E8E9EA',
  secondaryBackground: '#F5F5F5',
  mutedBackground: '#FAFAFA',
  navigationBackground: {
    base: '#EDEDED',
    lighten1: '#F5F5F5'
  },
  // Other
  scrollbar: '#727272',
} as VuetifyThemeVariant

export const dark = {
  primary: '#21CFF3',
  secondary: '#FFE18D',
  accent: '#FF4081',
  error: '#FF5252',
  info: '#2196F3',
  success: '#47CA86',
  warning: '#FB8C00',
  //Text
  mainText: '#EAEAEA',
  mutedText: '#8A8A8A',
  secondaryText: '#CACACA',
  successText: '#FFFFFF',
  // Contrast background
  mainContrast: '#D86985',
  mutedContrast: '#979da6',
  secondaryContrast: '#4CA7E2',
  // Contrast text
  mainContrastText: '#FFFFFF',
  secondaryContrastText: '#FFFFFF',
  // Common background colors
  defaultBackground: '#121212', // výchozí pozadí Vuetify pro tmavý motiv
  mainBackground: '#373635',
  secondaryBackground: '#2A2A2A',
  mutedBackground: '#252525',
  navigationBackground: {
    base: '#323232',
    lighten1: '#2A2A2A'
  },
  // Other
  scrollbar: '#727272',
} as VuetifyThemeVariant
