import {GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";

interface InteractionCenterState {
  authorizationCount: number;
  errorCount: number;
  processingCount: number;
}

const state = (): InteractionCenterState => ({
  authorizationCount: 0,
  errorCount: 0,
  processingCount: 0
})

const mutations: MutationTree<InteractionCenterState> = {

  setAuthorizationCount(state, payload: {authorizationCount: number}) {
    state.authorizationCount = payload.authorizationCount;
  },

  setErrorCount(state, payload: {errorCount: number}) {
    state.errorCount = payload.errorCount;
  },

  setProcessingCount(state, payload: {processingCount: number}) {
    state.processingCount = payload.processingCount;
  },

  setCounts(state, payload: {authorizationCount: number, errorCount: number, processingCount: number}) {
    state.authorizationCount = payload.authorizationCount;
    state.errorCount = payload.errorCount;
    state.processingCount = payload.processingCount;
  },

  showBatchProcessing() {
    // Empty on purpose
    // Acts as global event
  }
}

const actions = {

}

const getters: GetterTree<InteractionCenterState, RootState> = {
  authorizationCount: (state): number => {
    return state.authorizationCount;
  },

  errorCount: (state): number => {
    return state.errorCount;
  },

  processingCount: (state): number => {
    return state.processingCount;
  }
}

export const interactionCenter: Module<InteractionCenterState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
