

































import STextField from "@/component/ui/form/STextField.vue";
import PhoneNumberDialog from "@/component/dialog/PhoneNumberDialog.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import Vue from "vue";

export default Vue.extend({
  name: 'PhoneNumberSelector',
  components: {STextButton, PhoneNumberDialog, STextField},
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    phoneNumber(): string|undefined {
      return this.$store.getters["profile/number"];
    }
  }
})
