



































import Vue from "vue";
import {ValidationObserver} from "vee-validate";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import STextField from "@/component/ui/form/STextField.vue";
import SDialog from "@/component/ui/SDialog.vue";
import {PHONE_NUMBER} from "@/utils/urls";
import {redirectTo} from "@/utils/utils";
import {RedirectResponse} from "@/types/dto";

export default Vue.extend({
  name: 'PhoneNumberDialog',
  components: {STextButton, STextField, SDialog, ValidationObserver},

  props: {
    value: Boolean
  },

  data() {
    return {
      inputPhoneNumber: undefined,
      loading: false
    }
  },

  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },

    phoneNumber(): string | undefined {
      return this.$store.getters["profile/number"];
    }
  },

  methods: {
    async submit(): Promise<void> {
      if (!this.inputPhoneNumber) return;
      try {
        this.loading = true;
        let response = (await this.axios.put(PHONE_NUMBER, {
          phoneNumber: this.inputPhoneNumber,
          redirectUri: window.location.href
        })).data as RedirectResponse;
        redirectTo(response.redirectUri, false);
      }
      catch (ignore) {
        /**/
      }
      finally {
        this.loading = false;
      }
    }
  }

})
