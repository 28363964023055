

























import Vue from "vue";

export default Vue.extend({
  name: 'STooltip',
  props: {
    disabled: Boolean,
    bottom: Boolean,
    top: Boolean,
    right: Boolean,
    left: Boolean,
    touchscreen: Boolean,
    openDelay: [Number, String],
    openOnHover: Boolean,
    value: Boolean,
    zIndex: Number,
  },
  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    disabledLocal(): boolean {
      return this.$store.getters["config/touchScreen"] || this.touchscreen;
    }
  }
})
