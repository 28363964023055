import {MutationPayload} from "vuex";
import {RootState} from "@/store/store";
import Vue from "vue";

export default Vue.extend({
    data(){
        return {
            unsubscribe: undefined as (()=>void) | undefined
        }
    },
    methods: {
      subscribe(func: (mutation: MutationPayload, state: RootState) => unknown) {
            this.unsubscribe = this.$store.subscribe(func);
      }
    },
    beforeDestroy(){
        if(this.unsubscribe){
            this.unsubscribe();
        }
    }
})
