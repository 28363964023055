









































import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import pdfPageInfoMixin from "@/mixins/pdfPageInfoMixin";
import PDFWidget from "@/component/pdf/widgets/PDFWidget.vue";
import {PdfRectDims, PdfWidgetConfig, PdfWidgetRect, PdfWidgetSignatureParams} from "@/types";
import {computeWidgetStyle, PDF_WIDGET_HEIGHT_MIN, PDF_WIDGET_WIDTH_MIN} from "@/utils/pdfViewerUtils";

export default mixins(pdfPageInfoMixin).extend({
  name: 'PDFWidgetSignHere',

  components: {PDFWidget},

  props: {
    focused: Boolean,
    readonly: Boolean,
    usable: Boolean,
    value: {
      type: Object as PropType<PdfWidgetConfig>,
      required: true
    }
  },

  data() {
    return {
      fontSize: 10
    }
  },

  computed: {
    calcFontSize(): number {
      return this.fontSize * this.pageScale;
    },
    flagEditable(): boolean {
      return !this.readonly && this.value.editable;
    },
    flagUsable(): boolean {
      return !this.readonly && !this.value.editable && this.usable && !this.value.params.transformed;
    },
    minHeight(): number {
      return PDF_WIDGET_HEIGHT_MIN;
    },
    minWidth(): number {
      return PDF_WIDGET_WIDTH_MIN;
    },
    params(): PdfWidgetSignatureParams {
      return this.value.params;
    },
    rect: {
      get(): PdfWidgetRect {
        return this.value.rect;
      },
      set(rect: PdfWidgetRect) {
        this.$emit('input', { ...this.value, rect });
      }
    },
    widgetClass(): Record<string, boolean> {
      return {
        'pdf-widget-sign-here--edit': this.flagEditable,
        'pdf-widget-sign-here--readonly': this.readonly,
        'pdf-widget-sign-here--use': this.flagUsable
      };
    },
    zIndex(): number {
      return this.focused ? 2 : 1;
    },
    dataTestIdSuffix(): string {
      return this.value.editable ? "created" : "exist";
    }
  },

  methods: {
    focus(): void {
      this.$emit('focus', this.value.id);
    },
    remove(): void {
      this.$emit('remove', this.value.id);
    },
    use(): void {
      if (this.flagUsable) {
        this.$emit('use', this.value.id);
      }
    },
    widgetStyle(size: PdfRectDims): Record<string, string> {
      return {
        ...computeWidgetStyle(size, this.pageInfo),
        fontSize: `${this.calcFontSize}px`
      };
    }
  }
})
