import Vue from 'vue';
import {formatDateShort} from "@/utils/dateUtils";
import {nameToDisplay} from "@/utils/accountUtils";

export const ROW_TYPE = Object.freeze({
  PEOPLE: 'people',
  TIME: 'time',
  SIGNATURE_LEVEL: 'signatureLevel'
});

const metadataMixin = Vue.util.mergeOptions([], {

  methods: {
    // funkce na vypsani data u skutecneho kroku
    // - u kroku, kde jiz byla provedena akce (finished, rejected) vypiseme datum posledni akce kroku,
    // - u kroku, kde akce provedena nebyla vypiseme: predlozka + mezera + termin_kroku
    //   - pokud krok nema termin, napiseme text "Bez terminu"
    actionDateToDisplay(step) {
      if (step.state.toLowerCase() === 'finished' || step.state.toLowerCase() === 'rejected')
        return formatDateShort(step.changedAt);
      else if (step.dueDate)
        return this.$t('docDetail.metadata.until') + ' ' + formatDateShort(step.dueDate);
      else
        return this.$t('docDetail.metadata.noDueDate');
    },

    // funkce na vytvoreni radku s jmenem a akci - primary cerny text, secondary sedy text
    userNameWithActionForDisplay(userAccount, action) {
      return {
        "primary": nameToDisplay(userAccount),
        "secondary": action
      };
    },

    ownerFirstActionRow(flow) {
      return {
        "avatar": flow._embedded.owner._links?.userAvatar.href,
        // Datum vytvoreni ("vytvoreni konceptu" nebo "odeslani k podpisu") flow zadavatelem
        "upper": {
          "info": formatDateShort(flow.createdAt),
          // text "Pocet prijemcu"
          "key": this.$t('docDetail.metadata.recipientCount'),
          // pocet prijemcu
          "value": flow._embedded.steps.length,
          "type": ROW_TYPE.PEOPLE
        },
        "middle": {
          // jmeno + text "vyvoril koncept" nebo "odeslal k podpisu"
          "info": this.userNameWithActionForDisplay(
              flow._embedded.owner,
              flow.state === 'draft'
                  ? this.$t('docDetail.metadata.ownerDrafted')
                  : this.$t('docDetail.metadata.ownerSent')),
          // text "Konecny termin"
          "key": this.$t('docDetail.metadata.flowDueDate'),
          // konecny termin flow
          "value": flow.dueDate ? formatDateShort(flow.dueDate) : undefined,
          "type": ROW_TYPE.TIME
        },
        "bottomText": null,
        // koncept - ikona je tmava s obrazkem papirku na svetlem pozadi
        // flow k podpisu - ikona je bila s obrazkem papirove vlastovky na tmavem pozadi
        // TODO jmena bareve
        "icon": flow.state.toLowerCase() === 'draft'
            ?
            {'text': '$draft', 'background': 'mainBackground', 'color': 'mutedContrast'}
            : {
              'text': '$send',
              'background': 'secondaryContrast',
              'color': 'secondaryContrastText'
            }
      }
    },

    // funkce pro ikonu pres typ skutecneho podepsano, odmitnuto, ceka se na podpis (i aktivni krok)
    // nebo finalni zpracovani zahajeno/dokonceno pro systemovy krok
    iconForDisplay(stepType, stepState) {
      let iconData = {
        'text': '$hourglass',
        'background': 'mainBackground',
        'color': 'mutedContrast',
        'tooltipText': this.$t('docDetail.metadata.metadataRowStates.waiting')
      };
      switch (stepType.toLowerCase()) {
        case 'signature':
          switch (stepState.toLowerCase()) {
            case 'finished':
              iconData = {
                'text': '$sig',
                'background': 'secondaryContrast',
                'color': 'secondaryContrastText',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.finished')
              };
              break;
            case 'rejected':
              iconData = {
                'text': '$rejectDoc',
                'background': 'mainContrast',
                'color': 'mainContrastText',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.rejected')
              };
              break;
            case 'expired':
              iconData = {
                'text': '$hourglassEnd',
                'background': 'mainContrast',
                'color': 'mainContrastText',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.expired')
              };
              break;
            case 'active':
            case 'processing':
            case 'error':
              iconData = {
                'text': '$hourglass',
                'background': 'mainBackground',
                'color': 'mutedContrast',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.toSign')
              };
              break;
            default:
              iconData = {
                'text': '$hourglass',
                'background': 'mainBackground',
                'color': 'mutedContrast',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.waiting')
              };
              break;
          }
          break;
        case 'final_processing':
          switch (stepState.toLowerCase()) {
            case 'finished':
              iconData = {
                'text': '$check',
                'background': 'secondaryContrast',
                'color': 'secondaryContrastText',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.finishedSysStep')
              };
              break;
            default:
              iconData = {
                'text': '$hourglass',
                'background': 'mainBackground',
                'color': 'mutedContrast',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.waiting')
              };
              break;
          }
          break;
        case 'flow_restamp':
          switch (stepState.toLowerCase()) {
            case 'finished':
              iconData = {
                'text': '$check',
                'background': 'secondaryContrast',
                'color': 'secondaryContrastText',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.finishedSysStep')
              };
              break;
            case 'processing':
              iconData = {
                'text': '$cog',
                'background': 'mainBackground',
                'color': 'mutedContrast',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.processing')
              };
              break;
            case 'error':
            default:
              iconData = {
                'text': '$error',
                'background': 'mainContrast',
                'color': 'mutedContrast',
                'tooltipText': this.$t('docDetail.metadata.metadataRowStates.inError')
              };
              break;
          }
          break;
      }
      return iconData;
    },

    // vraci text pro zobrazeni podle stavu a typu kroku
    actionTextForDisplay(stepType, stepState) {
      let action = '';
      switch (stepType.toLowerCase()) {
        case 'signature':
          switch (stepState.toLowerCase()) {
            case 'new':
              action = this.flowState === 'draft' ? '' : this.$t('docDetail.metadata.userWaiting');
              break;
            case 'active':
            case 'processing':
            case 'error':
              action = this.$t('docDetail.metadata.usersTurn');
              break;
            case 'finished':
              action = this.$t('docDetail.metadata.userSigned');
              break;
            case 'rejected':
              action = this.$t('docDetail.metadata.userRejected');
              break;
            case 'inactive':
              action = '';
              break;
          }
          break;
        case 'final_processing':
          switch (stepState.toLowerCase()) {
            case 'new':
              action = this.$t('docDetail.metadata.userWaiting');
              break;
            case 'processing':
              action = this.$t('docDetail.metadata.systemProcessingStarted');
              break;
            case 'finished':
              action = this.$t('docDetail.metadata.systemProcessingFinished');
              break;
          }
      }
      return action;
    },

    getLastActionableStepIndex(flow) {
      let index = 0;
      flow._embedded.steps
          .filter(step => step.state === 'finished')
          .forEach(step => index = Math.max(index, step.stepOrder))
      return index;
    },

    showActiveStep(flow) {
      return flow.state === 'active';
    },

    activeStepIndex(flow) {
      return flow.currentPhase;
    },
    methodUsedToDisplay(step) {
      if (step) {
        switch (step.state.toLowerCase()) {
          case 'finished' :
            return step.signingMethodName;
          case 'rejected' :
            return step.rejectionNote;
          case 'expired' :
            return this.$t('docDetail.metadata.expiredStep');
        }
      }
      return '';
    },
    // prevedeni flows._embedded.steps[i] do informaci v bubline (v MetadataRow)
    stepToRowContent(step, showActiveStep, activeStepIndex, flowStepCount) {
      if (!step.stepType)
        return null;
      let rowContent = null;
      switch (step.stepType.toLowerCase()) {
        case "signature":
          // podpisovy krok
          rowContent = {
            "isActive": showActiveStep && step.stepOrder === activeStepIndex,
            "avatar": step._embedded.account._links?.userAvatar.href,
            "stepOrder": step.stepOrder, //StepOrder -> pro další práci (vytvoření pole polí podle stepOrder)
            "upper": {
              "info": this.actionDateToDisplay(step),
              // text "Pozadovana uroven"
              "key": this.$t('docDetail.metadata.requiredLevel'),
              // pozadovana  uroven
              "value": step?.requestedSignatureLevel?.description,
              "type": ROW_TYPE.SIGNATURE_LEVEL
            },
            "middle": {
              "info": this.userNameWithActionForDisplay(
                  step._embedded.account,
                  this.actionTextForDisplay(step.stepType, step.state)),
              // text "Termin"
              "key": this.$t('docDetail.metadata.dueDate'),
              // termin
              "value": step.dueDate ? formatDateShort(step.dueDate) : this.$t('docDetail.metadata.noDueDate'),
              "type": ROW_TYPE.TIME
            },
            "lower": this.methodUsedToDisplay(step),
            "icon": this.iconForDisplay(step.stepType, step.state)
          };
          break;
        case "final_processing":
          // archivacni krok
          rowContent = {
            "avatarIcon": '$cog',
            "upper": {
              // "Po dokonceni podpisu/u"
              "info": flowStepCount > 1
                  ? this.$t('docDetail.metadata.afterSignatureFinish')
                  : this.$t('docDetail.metadata.afterSignatureFinishPlural')
            },
            "middle": {
              "info": {
                primary: this.$t('docDetail.metadata.systemUserName'),
                secondary: this.actionTextForDisplay(step.stepType, step.state)
              }
            },
            // "Casove razitko a pecet"
            "lower": this.$t('docDetail.metadata.systemProcessingDescription'),
            "lowerSecondaryText": true,
            "icon": this.iconForDisplay(step.stepType, step.state)
          };
          break;
        case "flow_restamp":
          // prerazitkovani dokumentu
          rowContent = {
            "avatarIcon": '$cog',
            "stepOrder": step.stepOrder,
            "upper": {
              // "Přerazítkování dokumentu"
              "info":
                  this.$t('docDetail.metadata.flowRestamp')
            },
            "middle": {
              "info": {
                primary: this.$t('docDetail.metadata.systemUserName'),
                secondary: this.actionTextForDisplay(step.stepType, step.state)
              }
            },
            // "Vložení časového razítka před vypršením platnosti certifikátu."
            "lower": this.$t('docDetail.metadata.flowRestampDescritpion'),
            "lowerSecondaryText": true,
            "icon": this.iconForDisplay(step.stepType, step.state)
          };
          break;
      }
      // nezname kroky nebudeme zobrazovat
      return rowContent;
    },

    ownerRecallRow(flow) {
      // odvolavaci krok
      return {
        // TODO avatar pro archivacni krok?
        "avatar": flow._embedded.owner._links?.userAvatar.href,
        "upper": {
          // datum posledny zmeny flow?
          "info": formatDateShort(flow.changedAt)
        },
        "middle": {
          "info": this.userNameWithActionForDisplay(
              flow._embedded.owner,
              this.$t('docDetail.metadata.ownerRecalled'))
        },
        "lower": flow.recallNote,
        "icon": {'text': '$recall', 'color': 'mainContrastText', 'background': 'mainContrast', 'tooltipText': this.$t('docDetail.metadata.metadataRowStates.recalled')}
      };
    }
  }

});

export default metadataMixin;
