




























import FlowListItem from "@/component/workflow/list/FlowListItem.vue";
import Vue, {PropType} from "vue";
import {Flow, FlowListCategoryName} from "@/types";

export default Vue.extend({
  name: 'FlowListGroup',
  components: {FlowListItem},

  props: {
    batchMode: {
      type: Boolean,
      default: false
    },
    navCategory: {
      type: String as PropType<FlowListCategoryName>
    },
    color: {
      type: String
    },
    textColor: {
      type: String
    },
    flows: {
      type: Array as PropType<Flow[]>,
      required: true
    },
    disabled: {
      type: Object as PropType<{ [key: number]: boolean }>,
      required: true,
    },
    title: {
      type: String,
      default: ''
    },
    everythingLoaded: Boolean
  },

  data() {
    return {
      //disabledFlows: {},
      flowMap: {} as { [key: number]: Flow }
    }
  },

  computed: {
    disabledFlows(): { [key: number]: boolean } {
      const intersection = {} as { [key: number]: boolean };
      Object.keys(this.disabled).forEach(disabled => {
        if (this.flowMap[parseInt(disabled)]) {
          intersection[parseInt(disabled)] = true;
        }
      })

      return intersection;
    }
  },
  methods: {

    isFlowDisabled(flow: Flow) {
      return this.disabledFlows[flow.flowId];
    },
    updateFlowMap(flows: Array<Flow>) {
      const map = {} as { [key: number]: Flow };
      flows?.forEach(flow => map[flow.flowId] = flow);
      this.flowMap = map;
    }
  },
  watch: {
    flows(newValue) {
      this.updateFlowMap(newValue);
    }
  },

  created() {
    this.updateFlowMap(this.flows);
  }

})
