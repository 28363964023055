





































































































































































import mixins from "vue-typed-mixins";
import batchMixin from "@/mixins/batchMixin";
import displayMixin from "@/mixins/displayMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SSelect from "@/component/ui/form/SSelect.vue";
import SDialog from "@/component/ui/SDialog.vue";
import FlowBatchItem from "@/component/workflow/batch/FlowBatchItem.vue";
import FlowListItem from "@/component/workflow/list/FlowListItem.vue";
import {
  BatchProcessed,
  BatchProcessedAction,
  Flow,
  SignatureProfile,
  VisualReasonBulkPayload,
  VisualReasonTypes
} from "@/types";
import {BatchSigningProfilesInfo, BatchSigningProfilesInfoReportRow, BulkChangeResult} from "@/types/dto";
import STextField from "@/component/ui/form/STextField.vue";
import SCheckbox from "@/component/ui/form/SCheckbox.vue";
import STextList from "@/component/ui/STextList.vue";
import SForm from "@/component/ui/form/SForm.vue";

interface ProfileSelectItem {
  value: BatchSigningProfilesInfoReportRow,
  text: string,
  type: string,
  count: number,
}

interface FlowProfileItem {
  flow: Flow;
  profile?: SignatureProfile;
  collapsed: boolean;
}

export default mixins(batchMixin, displayMixin).extend({
  name: 'BatchSignatureChangeDialog',

  components: {SForm, STextList, STextField, FlowBatchItem, FlowListItem, STextButton, SSelect, SDialog, SCheckbox},

  props: {
    value: Boolean
  },

  data() {
    return {
      items: [] as Array<FlowProfileItem>,
      availableProfiles: [] as Array<BatchSigningProfilesInfoReportRow>,
      selectedProfileRecord: null as BatchSigningProfilesInfoReportRow | null,
      finished: false,
      changeResult: null as BulkChangeResult|null,
      processing: false,

      //v-model selects
      selectedPlace: undefined as VisualReasonTypes | undefined,
      selectedContact:  undefined as VisualReasonTypes | undefined,
      selectedReason: undefined as VisualReasonTypes | undefined,

      //checkbox
      checkPlace: false,
      checkContact: false,
      checkReason: false,

      //v-model text fields
      customReason: undefined as string | undefined,
      customPlace: undefined as string | undefined,
      customContact: undefined as string | undefined,

      //show textFields
      showCustomReason: false,
      showCustomPlace: false,
      showCustomContact: false,
    };
  },

  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    numberOfAvailableProfiles(): number {
      return this.availableProfiles.length;
    },
    availableProfilesSelectItems(): Array<ProfileSelectItem> {
      return this.availableProfiles.map(profileRecord => {
        return {
          value: profileRecord,
          text: profileRecord._embedded.signingProfile.profileName,
          type: profileRecord._embedded.signingProfile.signingMethodNameShort,
          count: profileRecord.count,
        }
      });
    },
    affectedDocumentsCount(): number|null {
      return this.selectedProfileRecord && this.selectedProfileRecord.count;
    },
    selectedFlowCount(): number {
      return this.$store.getters['workflow/flowSelectedCount'];
    },
    selectedFlowIds(): Array<number> {
      return this.selectedFlows.map((flow: Flow): number => flow.flowId);
    },
    selectedFlows(): Array<Flow> {
      return this.$store.getters['workflow/flowSelected'];

    //GETs, který vracejí pole pro SSelect v podrobnostech podpisu
    },
    getReasonOptions(): Array<VisualReasonTypes> {
      return this.$store.getters['config/reasonOptions'];
    },
    getContactInfoOptions(): Array<VisualReasonTypes> {
      return this.$store.getters['config/contactInfoOptions'];
    },
    getLocationOptions(): Array<VisualReasonTypes> {
      return this.$store.getters['config/locationOptions'];
    },
    reasonArrayText(): Array<string> {
      const text = [] as string[];
      if (this.checkReason) {
        text.push(this.$t('docDetail.reasonToSign.reasonLabel').toString());
      }
      if (this.checkPlace) {
        text.push(this.$t('docDetail.reasonToSign.place').toString());
      }
      if (this.checkContact) {
        text.push(this.$t('docDetail.reasonToSign.contact').toString());
      }
      return text;
    }
  },

  watch: {
    localValue(value: boolean) {
      if (value)
        this.prepareItems();
      else
        this.reset();
    },
    selectedReason(value) {
      this.showCustomReason = value.usageMethod === 'USER_INPUT';
    },
    selectedPlace(value) {
      this.showCustomPlace = value.usageMethod === 'USER_INPUT';
    },
    selectedContact(value) {
      this.showCustomContact = value.usageMethod === 'USER_INPUT';
    }
  },

  methods: {
    async change(): Promise<void> {
      this.processing = true;
      const changeList = this.items
          .filter(item => (this.selectedProfileRecord as BatchSigningProfilesInfoReportRow)._embedded.steps.find(
              step => step.stepId === item.flow.myActiveStepId))
          .map(item => ({
            stepId: item.flow.myActiveStepId,
            profileId: (this.selectedProfileRecord as BatchSigningProfilesInfoReportRow)._embedded.signingProfile.profileId
          }));

      try {
        this.changeResult = await this.apiBulkChangeSigningProfile(changeList);
        this.$store.commit('batches/batchProcessed', {
          action: BatchProcessedAction.SIGNATURE_CHANGE,
          flowIds: this.selectedFlowIds,
          flowsPath: this.$route.path
        } as BatchProcessed);
        if(this.checkReason || this.checkPlace || this.checkContact){
          if(this.selectedReason || this.selectedPlace || this.selectedContact){
            await this.setSigningDetails();
          }
        }
      }
      catch (e) {
        console.error('Error occurred while changing signing profiles in bulk', e);
      }
      finally {
        this.processing = true;
        this.finished = true;
      }
    },
    async setSigningDetails(): Promise<void> {
      let visualReasonPayload = {
        "stepIds": this.selectedFlows.map(flow => flow.myActiveStepId)
      } as VisualReasonBulkPayload

      if (this.checkReason) {
        visualReasonPayload.reason = {usageMethod: this.selectedReason?.usageMethod, textValue: this.setReasonValue()};
      }
      if (this.checkContact) {
        visualReasonPayload.contactInfo = {
          usageMethod: this.selectedContact?.usageMethod,
          textValue: this.setContactValue()
        };
      }
      if (this.checkPlace) {
        visualReasonPayload.location = {usageMethod: this.selectedPlace?.usageMethod, textValue: this.setPlaceValue()};
      }
      try {
        await this.axios.post('/flow-steps/bulk/signature-details', visualReasonPayload);
        this.$store.commit('notification/showMessage',
            {content: this.$t('docDetail.reasonToSign.successNotification'), type: 'success'});
      }catch (e) {
        console.log(e);
      }
    },
    displaySigningProfileInfo(item: FlowProfileItem) {
      if (item.profile)
        return this.formatSigningProfileInfo(item.profile);
      else if (!item.profile && !item.flow._embedded.signingProfile)
          // Workflow nemá přiřazený podpisový profil
        return this.$t('workflow.signingProfile.unassigned');
      else
          // Workflow obsahuje neznámý podpisový profil
        return this.$t('workflow.signingProfile.unknown');
    },

    formatSigningProfileInfo(profile: SignatureProfile) {
      return `${profile.profileName} (${profile.legalLevel})`;
    },

    getSigningProfile(profileId: number): SignatureProfile {
      return this.$store.getters['profile/signingProfileById'](profileId);
    },

    async prepareItems(): Promise<void> {
      await this.reloadSigningProfiles();
      this.selectedFlows.forEach(flow => {
        let item: FlowProfileItem = {
          flow: flow,
          collapsed: this.selectedFlowCount >= 10
        };
        const flowProfile = flow._embedded.signingProfile;
        if (flowProfile && flowProfile.profileId)
          item.profile = this.getSigningProfile(flowProfile.profileId);
        this.items.push(item);
      });
      const report: BatchSigningProfilesInfo = await this.apiGetBulkSigningProfilesInfo(this.items.map(item => item.flow.myActiveStepId));
      this.availableProfiles = report.reportRows;
    },

    async reloadSigningProfiles(): Promise<void> {
      await this.$store.dispatch('profile/loadSigningProfiles');
    },

    hasChangeSucceeded(stepId: number): boolean {
      return !!this.changeResult && this.changeResult.succeeded.includes(stepId);
    },

    hasChangeFailed(stepId: number): boolean {
      return !!this.changeResult && this.changeResult.failed.includes(stepId);
    },

    isAffectedByChange(stepId: number): boolean {
      return !!this.selectedProfileRecord &&
          this.selectedProfileRecord._embedded.steps.find(step => step.stepId === stepId) !== undefined;
    },

    reset(): void {
      this.items = [];
      this.availableProfiles = [];
      this.finished = false;
      this.changeResult = null;
      this.processing = false;
      this.selectedProfileRecord = null;

      //v-model selects
      this.selectedPlace = undefined;
      this.selectedContact = undefined;
      this.selectedReason = undefined;

      //checkbox
      this.checkPlace = false;
      this.checkContact = false;
      this.checkReason = false;

      //v-model text fields
      this.customReason = undefined;
      this.customPlace = undefined;
      this.customContact = undefined;

      //show textFields
      this.showCustomReason = false
      this.showCustomPlace = false;
      this.showCustomContact = false;
    },

    close(): void {
      this.localValue = false;
    },

    itemError(item: FlowProfileItem): string|undefined {
      return !this.finished &&
      !!this.selectedProfileRecord &&
      !this.isAffectedByChange(item.flow.myActiveStepId)
          ? this.$t('workflow.batch.dialogs.signatureChange.unsuitableProfile').toString()
          : undefined;
    },
    itemIcon(item: FlowProfileItem): string|undefined {
      if(this.hasChangeSucceeded(item.flow.myActiveStepId)){
        return '$success';
      } else if(this.hasChangeFailed(item.flow.myActiveStepId)){
        return '$errorCircle';
      } else {
        return undefined;
      }
    },
    itemIconColor(item: FlowProfileItem): string|undefined {
      if(this.hasChangeSucceeded(item.flow.myActiveStepId)){
        return 'success';
      } else if(this.hasChangeFailed(item.flow.myActiveStepId)){
        return 'mainContrast';
      } else {
        return undefined;
      }
    },
    collapseAll(): void {
      this.items.forEach(i => i.collapsed = true);
    },
    expandAll(): void {
      this.items.forEach(i => i.collapsed = false);
    },
    setContactValue(): string | undefined {
      return this.selectedContact?.usageMethod === 'USER_INPUT' ? this.customContact : this.selectedContact?.textValue;
    },
    setReasonValue(): string | undefined {
      return this.selectedReason?.usageMethod === 'USER_INPUT' ? this.customReason : this.selectedReason?.textValue;
    },
    setPlaceValue(): string | undefined {
      return this.selectedPlace?.usageMethod === 'USER_INPUT' ? this.customPlace : this.selectedPlace?.textValue;
    },
    formatText(texts: Array<string>): Array<string> {
      return texts.map((text, i) => {
        const firstLetter = i === 0 ? text[0].toUpperCase() : text[0].toLowerCase();
        const restOfText = text.slice(1).toLowerCase();
        return firstLetter + restOfText;
      });
    }
  },
})
