import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {apiFoldersGet} from "@/api/foldersApi";
import {RootState} from "@/store/store";
import {Category, Folder, FolderMode, NavCategory, NavigationMode} from "@/types/navigation";
import {FlowListCategoryName} from "@/types/workflow";
import {flatFolders} from "@/utils/folderUtils";

interface NavigationState {
  draftFlowId: number | undefined;
  folders: Array<Folder> | undefined;
  foldersFlat: Array<Folder> | undefined;
  // Pre-defined navigation categories
  navCategories: {[key: string]: NavCategory};
  defaultFlowListCategories: Array<FlowListCategoryName>;
  defaultOrderingDateAttribute: string;
  lastVisitedCategory: Category | undefined;
  mode: NavigationMode;
  selectedFolderId: number | undefined;
}

const state = (): NavigationState => ({
  draftFlowId: undefined,
  folders: undefined,
  foldersFlat: undefined,
  navCategories: {
    draft: {
      title: 'nav.navCategory.drafts',
      icon: 'file',
      flowListCategories: ['today', 'yesterday', 'earlier'],
      defaultOrderingDateAttribute: 'createdAt'
    },
    'active-my': {
      title: 'nav.navCategory.toSign',
      icon: 'sig',
      flowListCategories: ['missed', 'today', 'tomorrow', 'later'],
      defaultOrderingDateAttribute: 'stepDueDate'
    },
    'active-others': {
      title: 'nav.navCategory.waiting',
      icon: 'clock',
      flowListCategories: ['missed', 'today', 'tomorrow', 'later'],
      defaultOrderingDateAttribute: 'stepDueDate'
    },
    finished: {
      title: 'nav.navCategory.finished',
      icon: 'finished',
      flowListCategories: ['today', 'yesterday', 'earlier'],
      defaultOrderingDateAttribute: 'changedAt'
    },
    canceled: {
      title: 'nav.navCategory.canceled',
      icon: 'ban',
      flowListCategories: ['today', 'yesterday', 'earlier'],
      defaultOrderingDateAttribute: 'changedAt'
    },
    events: {
      title: 'nav.navCategory.events',
      icon: 'bell',
      flowListCategories: ['earlier', 'yesterday', 'today', 'tomorrow', 'later'],
      defaultOrderingDateAttribute: 'changedAt'
    }
  },
  defaultFlowListCategories: ['missed', 'today', 'tomorrow', 'later'],
  defaultOrderingDateAttribute: 'dueDate',
  lastVisitedCategory: undefined,
  mode: NavigationMode.NAV,
  selectedFolderId: undefined
})

const mutations: MutationTree<NavigationState> = {
  foldersRefresh() {
    // Empty on purpose, acts as global notification
  },
  openDraft(state, payload: { flowId: number }) {
    state.draftFlowId = payload.flowId;
  },
  refreshCounters() {
    // Empty on purpose, acts as global notification
  },
  setNavMode(state, payload: { mode: NavigationMode }) {
    state.mode = payload.mode;
  },
  clearSearch() {
    // Empty on purpose, acts as global notification
  },
  setFolders(state, payload: { folders: Array<Folder> }) {
    state.folders = payload.folders;
    state.foldersFlat = flatFolders(payload.folders);
  },
  setLastVisitedCategory(state, payload: { category: Category }) {
    state.lastVisitedCategory = payload.category;
  },
  setSelectedFolderId(state, payload: { folderId: number | undefined }) {
    state.selectedFolderId = payload.folderId;
  }
}

const actions: ActionTree<NavigationState, RootState> = {
  async loadFolders({ commit }, payload: { folderMode: FolderMode }): Promise<Array<Folder>> {
    try {
      const folders = await apiFoldersGet(payload.folderMode);
      /*
       * Režim "counters" vrací plochý seznam složek, kde pro každou složku je uvdeno pouze folderId a počty.
       * Tento seznam se neukládá.
       */
      if (payload.folderMode !== FolderMode.COUNTERS)
        commit('setFolders', { folders });
      return folders ?? [];
    }
    catch (e) {
      console.error(`Error occurred while getting folders in mode "${payload.folderMode}"`, e);
      return [];
    }
  }
}

const getters: GetterTree<NavigationState, RootState> = {
  flowListCategories: (state) => (navCategoryName: string) => {
    if (state.navCategories[navCategoryName]) {
      return state.navCategories[navCategoryName].flowListCategories;
    }
    else {
      return state.defaultFlowListCategories;
    }
  },
  folders: (state): Array<Folder> => {
    return state.folders ?? [];
  },
  foldersFlat: (state): Array<Folder> => {
    return state.foldersFlat ?? [];
  },
  navCategories: (state) => {
    return state.navCategories;
  },
  lastVisitedCategory: (state): Category | undefined => {
    return state.lastVisitedCategory;
  },
  mode: (state): NavigationMode => {
    return state.mode;
  },
  defaultOrderingDateAttribute: (state) => (navCategoryName: string) => {
    if (state.navCategories[navCategoryName]) {
      return state.navCategories[navCategoryName].defaultOrderingDateAttribute;
    }
    else {
      return state.defaultOrderingDateAttribute;
    }
  },
  selectedFolderId: (state): number | undefined => {
    return state.selectedFolderId;
  },
  state: (state) => (folderId: number): string | undefined => {
    return state.foldersFlat?.find(folder => folder.folderId == folderId)?.state;
  },
  url: (state) => (folderId: number): string | undefined => {
    return state.foldersFlat?.find(folder => folder.folderId == folderId)?._links?.['sef:folder-content']?.href;
  }
}

export const navigation: Module<NavigationState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
