




































































































































import SDateField from "@/component/ui/form/SDateField.vue";
import STextField from "@/component/ui/form/STextField.vue";
import SForm from "@/component/ui/form/SForm.vue";
import displayMixin from "@/mixins/displayMixin";
import mixins from "vue-typed-mixins";
import {PropType} from "vue";
import {Category, InputFlow, Tag, UserAccount} from "@/types";
import {DateTime} from "luxon";
import STextarea from "@/component/ui/form/STextarea.vue";
import SignerSearch from "@/component/workflow/uploadWizard/SignerSearch.vue";
import SignerName from "@/component/workflow/uploadWizard/SignerName.vue";
import SignerEmail from "@/component/workflow/uploadWizard/SignerEmail.vue";
import SCombobox from "@/component/ui/form/SCombobox.vue";
import {TagsAutocompleteResponse} from "@/types/dto";
import {tag} from "@/utils/utils";
import {ACCOUNT_TYPE, AUTOCOMPLETE_MIN_LENGTH, HARD_DUE_DATE_MODE} from "@/utils/constants";
import {AUTOCOMPLETE} from "@/utils/urls";
import debounce from "lodash/debounce";
import StepDetailSignerSearch from "@/component/workflow/uploadWizard/steps/StepDetailSignerSearch.vue";
import {ValidationObserver} from "vee-validate";
import {nameToDisplay} from "@/utils/accountUtils";
import SSwitch from "@/component/ui/form/SSwitch.vue";
import {formatDate} from "@/utils/dateUtils";

export default mixins(displayMixin).extend({
  name: "StepDetail",
  components: {
    SSwitch,
    StepDetailSignerSearch,
    SCombobox, SignerEmail, SignerName, SignerSearch, STextarea, SDateField, STextField, SForm
  },
  data() {
    return {
      subjectMinLength: 3,
      subjectMaxLength: 120,
      recipientSearchActive: false,
      items: [] as Array<Tag>,
      search: '' as string,
      loadTagsDebouncedFunc: () => {/**/},
      loading: false
    }
  },

  props: {
    value: {
      type: Object as PropType<InputFlow>,
      required: true,
    },
    selectedCategory: {
      type: Object as PropType<Category>,
    }
  },

  computed: {
    localValue: {
      get(): InputFlow {
        return this.value;
      },
      set(value: InputFlow) {
        this.$emit('input', value);
      }
    },
    filename(): string {
      return this.localValue._embedded.document.documentName;
    },
    dateFormat(): string {
      return this.$store.getters['locale/dateFormat'];
    },
    locale(): string {
      return this.$store.getters['locale/locale'];
    },
    today(): string {
      return DateTime.local().toISO();
    },
    valid(): boolean {
      return !!this.localValue.subject &&
          this.localValue.subject.length >= this.subjectMinLength &&
          this.localValue.subject.length <= this.subjectMaxLength;
    },
    enableHardDueDate(): boolean {
      return this.selectedCategory?.dueDateModeDef === HARD_DUE_DATE_MODE.CHOOSE
    }
  },

  methods: {
    addRecipient(recipient: UserAccount) {
      this.localValue._embedded.finalRecipients.unshift(recipient);
    },
    async validate(): Promise<boolean> {
      return (this.$refs.stepDetail as InstanceType<typeof ValidationObserver>).validate();
    },
    onRecipientSearchFocus() {
      // Timeout is necessary, otherwise the component will be blurred immediately
      setTimeout(() => this.recipientSearchActive = true, 200)
    },
    removeRecipient(index: number) {
      this.localValue._embedded.finalRecipients.splice(index, 1)
    },
    async loadTags(): Promise<void> {
      if (this.search) this.search = this.search.replace(/\s+/g, ' ');
      if (!this.search || this.search.length < AUTOCOMPLETE_MIN_LENGTH) {
        this.items = [];
        return;
      }
      if (this.loading) return;
      this.loading = true;

      try {
        let url = AUTOCOMPLETE + '?text=' + encodeURIComponent(this.search) +
            '&include-accounts=false&include-tags=true&include-subjects=false&include-external-accounts=false';
        let response = (await this.axios.get(url)).data as TagsAutocompleteResponse;
        this.items = response._embedded.tags;
      }
      catch (ignore) {
        /**/
      }
      finally {
        this.loading = false;
      }
    },
    onChange(): void {
      this.search = '';
      this.items = [];
    },
    tagAppearance(text: string): string {
      return tag(text);
    },
    external(recipient: UserAccount): boolean {
      return recipient.accountType === ACCOUNT_TYPE.EXTERNAL;
    },
    name(recipient: UserAccount): string {
      return nameToDisplay(recipient);
    },
    icon(recipient: UserAccount): string {
      if (recipient.accountType === ACCOUNT_TYPE.EXTERNAL) {
        return 'external';
      }
      else {
        return 'envelope';
      }
    },
    formatDate(value: string): string {
      return formatDate(value, this.dateFormat);
    }
  },

  watch: {
    valid: {
      handler(value) {
        this.$emit('valid', value);
      },
      immediate: true
    },
    search(value: string) {
      if (!value || value.length < AUTOCOMPLETE_MIN_LENGTH) {
        return
      }
      this.loadTagsDebouncedFunc();
    },
  },

  created() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let vm = this;
    this.loadTagsDebouncedFunc = debounce(function () {
      vm.loadTags();
    }, 200)
  }
})
