






















import {isNumber} from "lodash";
import mixins from "vue-typed-mixins";
import buttonMixin from "@/mixins/buttonMixin";
import mobileMixin from "@/mixins/mobileMixin";

export default mixins(buttonMixin, mobileMixin).extend({
  name: 'STextButton',
  props: {
    disabled: Boolean,
    loading: Boolean,
    small: Boolean,
    text: String,
    textOnly: Boolean,
    elevation: Boolean,
    width: {
      type: [Number, String],
      default: '200px'
    }
  },
  computed: {
    btnStyle(): string | number | undefined {
      return this.mobile ? '100%' : this.convertToUnits(this.width)
    },
  },
  methods: {
    convertToUnits(value: number|string|null): string|number|undefined {
      if (value == null || value === '') {
        return undefined
      } else if (!isNumber(value)) {
        return String(value)
      } else {
        return Number(value) + 'px';
      }
    }
  }
})
