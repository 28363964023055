















































import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import mobileMixin from "@/mixins/mobileMixin";
import SFloatingButton from "@/component/ui/buttons/SFloatingButton.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {ButtonVariant} from "@/types";

export default mixins(mobileMixin).extend({
  name: 'SList',
  components: {SFloatingButton, STextButton},
  props: {
    addBtn: Boolean,
    addBtnVariant: {
      type: String as PropType<ButtonVariant>,
      default: function () {
        return 'primary-success';
      }
    },
    addBtnText: String,
  },

  computed: {
    addBtnDefaultText(): string {
      return this.addBtnText ?? this.$t('common.actions.add').toString();
    }
  },

  methods: {
    onAddBtnClick(): void {
      this.$emit('add');
    }
  }
})
