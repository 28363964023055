import {Folder} from "@/types";

export function flatFolders(folders: Array<Folder> | undefined): Array<Folder> | undefined {
  if (!folders)
    return undefined;

  const flatFolder = (folder: Folder): Array<Folder> => [folder,
    ...(folder._embedded?.nestedFolders?.flatMap(flatFolder) || [])]

  return folders.flatMap(flatFolder);
}