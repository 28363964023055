import axios from "@/plugin/vueAxios";
import {BUSINESS_CONFIG, CATEGORIES, QUICK_ACTIONS} from "@/utils/urls";
import {
  BusinessConfig,
  CardClientConfig,
  Category, GuiVariant,
  QuickActions, QuickActionsTypeAction, SignatureDetailsSettings, VariantMode,
  VisualReasonTypes
} from "@/types";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";
import {OS} from "@/utils/constants";

interface ConfigState {
  autoredirect?: boolean;
  autoredirectDelay?: number;
  categories?: Array<Category>;
  clientConfig: { [key in OS]?: CardClientConfig };
  contactInfoOptions: Array<VisualReasonTypes>;
  documentValidation?: boolean;
  externalUsers?: boolean;
  guiVariantsSettings: { [key in GuiVariant]: VariantMode };
  homeUrl?: string;
  locationOptions: Array<VisualReasonTypes>;
  manualFlowCreation?: boolean;
  presetLoggedUserInCreatedFlow?: boolean;
  reasonOptions: Array<VisualReasonTypes>;
  supportEmail?: string;
  supportEnabled: boolean;

  // Local config
  touchScreen: boolean;

  // Quick action
  checkLoadQuickAction?: boolean;
  showQuickActionBtn: boolean;
  actions: Array<QuickActionsTypeAction>;

  //AdHoc
  imageInputMethods: Array<string>;
}

const state = (): ConfigState => ({
  autoredirect: undefined,
  autoredirectDelay: undefined,
  categories: undefined,
  clientConfig: {},
  contactInfoOptions: [],
  documentValidation: undefined,
  externalUsers: undefined,
  guiVariantsSettings: {} as { [key in GuiVariant]: VariantMode },
  homeUrl: undefined,
  locationOptions: [],
  manualFlowCreation: undefined,
  presetLoggedUserInCreatedFlow: undefined,
  reasonOptions: [],
  supportEmail: undefined,
  supportEnabled: false,

  //Local config
  touchScreen: false,

  //Quick Actions
  showQuickActionBtn: false,
  actions: [],
  checkLoadQuickAction: undefined,

  //AdHoc
  imageInputMethods: [],
})

const mutations: MutationTree<ConfigState> = {
  setCategories(state, payload: { categories: Array<Category> }) {
    state.categories = payload.categories;
  },
  setConfig(state, payload: BusinessConfig) {
    state.autoredirect = payload.autoredirect;
    state.autoredirectDelay = payload.autoredirectDelay;
    state.checkLoadQuickAction = payload.quickActionsEnabled;
    state.clientConfig = payload.clientConfig;
    state.documentValidation = payload.documentValidation;
    state.externalUsers = payload.externalUsers;
    state.guiVariantsSettings = payload.guiVariantsSettings;
    state.homeUrl = payload.homeUrl;
    state.manualFlowCreation = payload.manualFlowCreation;
    state.presetLoggedUserInCreatedFlow = payload.presetLoggedUserInCreatedFlow;
    state.supportEmail = payload.supportEmail;
    state.supportEnabled = payload.supportEnabled;
  },
  setTouchScreen(state, payload: boolean) {
    state.touchScreen = payload;
  },
  setShowQuickAction(state, payload: QuickActions) {
    if(payload._embedded?.actions){
      state.showQuickActionBtn = payload._embedded.actions.length > 0;
    }
  },
  setActions(state, payload: { actions: Array<QuickActionsTypeAction> }) {
    state.actions = payload.actions;
  },
  setSignatureDetailSetting(state, payload: SignatureDetailsSettings) {
    if (payload !== null && payload !== undefined) {
      if (payload.contactInfoOptions !== null && payload.contactInfoOptions !== undefined) {
        state.contactInfoOptions = payload.contactInfoOptions;
      }
      if (payload.locationOptions !== null && payload.locationOptions !== undefined) {
        state.locationOptions = payload.locationOptions;
      }
      if (payload.reasonOptions !== null && payload.reasonOptions !== undefined) {
        state.reasonOptions = payload.reasonOptions;
      }
    }
  },
  setImageInputMethods(state, payload: Array<string>) {
    state.imageInputMethods = payload;
  }
}

const actions: ActionTree<ConfigState, RootState> = {
  loadCategories({commit}): Promise<void> {
    return new Promise((resolve, reject) => {
      axios.get(CATEGORIES)
          .then((response) => {
            commit('setCategories', {categories: response.data._embedded.categories})
            resolve();
          })
          .catch(() => {
            reject();
          })
    })
  },
  loadConfig({commit}): Promise<void> {
    return new Promise((resolve, reject) => {
      axios.get(BUSINESS_CONFIG)
          .then((response) => {
            commit('setConfig', response.data);
            commit('setCategories', {categories: response.data._embedded?.categories});
            commit('setSignatureDetailSetting', response.data.signatureDetailsSettings);
            commit('setImageInputMethods', response.data.imageInputMethods);
            resolve();
          })
          .catch(() => {
            reject();
          })
    })
  },
  loadQuickAction({commit}): Promise<void> {
    return new Promise((resolve, reject) => {
      axios.get(QUICK_ACTIONS)
          .then((response) => {
            commit('setShowQuickAction', response.data);
            commit('setActions', {actions: response.data._embedded.actions});
            resolve();
          })
          .catch(() => {
            reject();
          })
    })
  },
}

const getters: GetterTree<ConfigState, RootState> = {
  autoredirect: (state): boolean | undefined => {
    return state.autoredirect;
  },
  autoredirectDelay: (state): number | undefined => {
    return state.autoredirectDelay;
  },
  categories: (state): Array<Category> | undefined => {
    return state.categories;
  },
  documentValidationEnabled: (state): boolean | undefined => {
    return state.documentValidation;
  },
  externalUsersEnabled: (state): boolean | undefined => {
    return state.externalUsers;
  },
  manualFlowCreationEnabled: (state): boolean | undefined => {
    return state.manualFlowCreation;
  },
  homeUrl: (state): string | undefined => {
    return state.homeUrl;
  },
  clientConfig: (state): { [key in OS]?: CardClientConfig } => {
    return state.clientConfig;
  },
  fullVariant: (state): VariantMode => {
    return state.guiVariantsSettings[GuiVariant.FULL_VARIANT];
  },
  onlineSignatureVariant: (state): VariantMode => {
    return state.guiVariantsSettings[GuiVariant.ONLINE_VARIANT];
  },
  externalUserVariant: (state): VariantMode => {
    return state.guiVariantsSettings[GuiVariant.EXTERNAL_VARIANT];
  },
  supportEnabled: (state): boolean => {
    return state.supportEnabled;
  },
  supportEmail: (state): string | undefined => {
    return state.supportEmail;
  },
  presetLoggedUserInCreatedFlow: (state): boolean | undefined => {
    return state.presetLoggedUserInCreatedFlow;
  },
  touchScreen: (state): boolean => {
    return state.touchScreen;
  },
  showQuickActionBtn: (state): boolean => {
    return state.showQuickActionBtn;
  },
  actions: (state): Array<QuickActionsTypeAction> => {
    return state.actions;
  },
  checkLoadQuickAction: (state): boolean | undefined => {
    return state.checkLoadQuickAction;
  },
  reasonOptions: (state): Array<VisualReasonTypes> => {
    return state.reasonOptions;
  },
  contactInfoOptions: (state): Array<VisualReasonTypes> => {
    return state.contactInfoOptions;
  },
  locationOptions: (state): Array<VisualReasonTypes> => {
    return state.locationOptions;
  },
  imageInputMethods: (state): Array<string> => {
    return state.imageInputMethods;
  }
}

export const config: Module<ConfigState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
