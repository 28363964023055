import { render, staticRenderFns } from "./ProfileHeaderDetail.vue?vue&type=template&id=0eb913b8&scoped=true&"
import script from "./ProfileHeaderDetail.vue?vue&type=script&lang=ts&"
export * from "./ProfileHeaderDetail.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileHeaderDetail.vue?vue&type=style&index=0&id=0eb913b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eb913b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VToolbar,VToolbarTitle})
