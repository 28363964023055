






import Vue, {PropType} from "vue";
import {CssCustomization} from "@/types";

// Defaultní hodnoty
const defaultCustomization: CssCustomization = Object.freeze({
  '--btn-text-transform': 'uppercase'
});

export default Vue.extend( {
  name: 'ThemeProvider',

  props: {
    cssCustomization: {
      type: Object as PropType<CssCustomization>,
      default: () => (defaultCustomization)
    }
  },

  watch: {
    cssCustomization: {
      handler(newValue: CssCustomization): void {
        Object.entries(newValue).forEach(([key, value]) => document.documentElement.style.setProperty(key, value));
      },
      immediate: true
    }
  }
})
