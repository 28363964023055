





































































import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import AccountInfo from "@/component/AccountInfo.vue";
import SignatureContentAlert from "@/component/signature/SignatureContentAlert.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import FlowDetail from "@/component/workflow/detail/FlowDetail.vue";
import {Flow, FlowStep, ThemeOnlineSignatureCustomization} from "@/types";

export default mixins(displayMixin).extend({
  name: 'SignatureOnlineDocumentDetail',

  components: {AccountInfo, FlowDetail, SIconButton, SignatureContentAlert},

  props: {
    flow: {
      type: Object as PropType<Flow>,
      required: true
    },
    navCancel: Boolean,
    customization:  Object as PropType<ThemeOnlineSignatureCustomization>
  },

  computed: {
    accountName(): string | undefined {
      return this.$store.getters['profile/accountName'];
    }
  },

  methods: {
    applicableSigningProfiles(data: {flow: Flow | undefined, step: FlowStep | undefined}): boolean {
      return !!data?.flow && !!data?.step && !!data?.step._embedded?.applicableSigningProfiles?.length;
    },
    cancel(): void {
      this.$emit('cancel');
    },
    flowActive(data: {flow: Flow | undefined, step: FlowStep | undefined}): boolean {
      return !!data.step && data.step._embedded?.account?.accountName === this.accountName && data.step.state ===
          'active';
    },
    flowProcessing(data: {flow: Flow | undefined, step: FlowStep | undefined}): boolean {
      return data?.step?.state === 'processing';
    },
    flowReload(): void {
      this.$emit('flow-reload');
    },
    signatureSettings(): void {
      this.$emit('signature-settings');
    },
    success(): void {
      this.$emit('success');
    }
  }
})
