import axios from "@/plugin/vueAxios";
import {AppAbout} from "@/types";

export const ABOUT_PATH = '/about'

export const ORGANISATION_LOGO_PATH = '/resources/logo'

export async function apiAboutGet(): Promise<AppAbout> {
  const response = await axios.get(ABOUT_PATH);
  return response.data as AppAbout;
}

export function organisationLogoURL(): string {
  return obeliskConfig.guiUrl + ORGANISATION_LOGO_PATH;
}