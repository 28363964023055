import axios from "@/plugin/vueAxios";
import {Folder, FolderMode} from "@/types";

export const FOLDERS_PATH = '/folders'

export async function apiFoldersGet(folderMode: FolderMode): Promise<Array<Folder> | undefined> {
  const response = await axios.get(FOLDERS_PATH, {
    params: {
      folderMode
    }
  });
  return response.data?._embedded?.folders as Array<Folder> | undefined;
}