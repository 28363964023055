















import Vue, {PropType} from "vue";
import InteractionCenter from "@/component/notifications/list/InteractionCenter.vue";
import {NotificationFolderType} from "@/types/notification";

export default Vue.extend({
  name: 'NotificationsContent',
  components: {InteractionCenter},
  props: {
    folderId: {
      type: String as PropType<NotificationFolderType>,
      required: true
    }
  }
})
