






















import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {UploadWizardButtonConfig, UploadWizardConfig, WorkflowUploadWizardAction} from "@/types";
import {PropType} from "vue";

export default mixins(displayMixin).extend({
  name: "UploadWizardButtons",
  components: {STextButton},
  props: {
    config: {
      type: Object as PropType<UploadWizardConfig>
    }
  },
  data(){
    return {
      buttonConfig: [
        {
          actions: [{
            type: "back",
            title: 'uploadWizard.actions.back',
            variant: 'secondary-muted',
            dataTestid: 'uploadWizardActions-back',
            onClick: () => this.$emit('prev')
          }]
        },
        {
          actions: [
            {type: "next-muted", title: 'uploadWizard.actions.continue', variant: 'secondary-muted', dataTestid: 'uploadWizardActions-next', onClick: ()=> this.$emit('next')},
            {type: "next", title: 'uploadWizard.actions.continue', variant: 'primary-success', dataTestid: 'uploadWizardActions-next', onClick: ()=> this.$emit('next')},
            {type: "submit", title: 'uploadWizard.actions.submit', variant: 'primary-success', dataTestid: 'uploadWizardActions-submit', onClick: ()=> this.$emit('submit')}
          ]
        }
      ] as Array<UploadWizardButtonConfig>
    }
  },
  methods: {
    isActionVisible(type: WorkflowUploadWizardAction): boolean{
      return this.config?.actions.includes(type);
    }
  }
})
