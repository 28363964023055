<template>
  <div class="mb-3 mt-1">
    <h4 v-if="subsection.title">{{ subsection.title }}</h4>
    <FlowDetailAuditTable v-for="table in subsection.tables" :table="table" :key="table"></FlowDetailAuditTable>
  </div>
</template>

<script>
import FlowDetailAuditTable from "@/component/workflow/detail/audit/FlowDetailAuditTable";

export default {
  name: "FlowDetailAuditSubsection",
  components: {FlowDetailAuditTable},
  props: {
    subsection: {
      title: String,
      tables: Array
    }
  }
}
</script>

<style scoped>

</style>