var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-view"},[_c('v-treeview',{staticClass:"nav-menu",class:{ 'dense': _vm.dense },style:({ 'color': _vm.$vuetify.theme.currentTheme.secondaryText }),attrs:{"active":_vm.active,"expand-icon":'$expand',"items":_vm.treeModel,"open":_vm.openFolders,"return-object":"","activatable":"","color":"mainText","dense":"","hoverable":"","item-key":"id"},on:{"update:open":function($event){_vm.openFolders=$event},"update:active":_vm.updateActive},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var active = ref.active;
return [_c('div',{on:{"click":function($event){return _vm.handleItemClick($event, item, active)}}},[(item.folder && item.folder.state && _vm.iconLevels.some(function (e) { return e === item.level; }))?_c('v-icon',{staticStyle:{"width":"unset"},attrs:{"color":active?_vm.$vuetify.theme.currentTheme.mainText:_vm.$vuetify.theme.currentTheme.mutedText,"size":"23"},domProps:{"textContent":_vm._s('$' + _vm.navCategoryIcon(item.folder))}}):_vm._e()],1)]}},{key:"label",fn:function(ref){
var item = ref.item;
var active = ref.active;
return [_c('div',{staticClass:"text-truncate",class:_vm.itemLabelClass(item, active),on:{"click":function($event){return _vm.handleItemClick($event, item, active)}}},[(item.level === 2)?_c('div',[_vm._v(_vm._s(_vm._f("tag")(item.name)))]):_c('div',[_vm._v(_vm._s(item.name))])])]}},{key:"append",fn:function(ref){
var item = ref.item;
var active = ref.active;
return [(item.folder)?_c('div',{class:{'selected-bold': active, 'align-vertical': !_vm.errorCount(item.folder.folderId), 'category-style': !item.showContent},on:{"click":function($event){return _vm.handleItemClick($event, item, active)}}},[_c('div',{class:{'round-center-count py-1': _vm.errorCount(item.folder.folderId) > 0}},[_vm._v(" "+_vm._s(_vm._f("truncateNumber")(_vm.showCountToDisplay(item.folder.folderId, item.folder.itemCountDisplayMode)))+" ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }