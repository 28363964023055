import { render, staticRenderFns } from "./FoldersMenu.vue?vue&type=template&id=89fb5584&scoped=true&"
import script from "./FoldersMenu.vue?vue&type=script&lang=ts&"
export * from "./FoldersMenu.vue?vue&type=script&lang=ts&"
import style0 from "./FoldersMenu.vue?vue&type=style&index=0&id=89fb5584&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89fb5584",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VIcon,VTreeview})
