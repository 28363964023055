import math from "mathjs";
import PDFPageLayerSignature from "@/component/pdf/layers/PDFPageLayerSignature.vue";
import {DocumentField, Flow, FlowStep} from "@/types/workflow";

export enum PdfPlaceWidgetState {
  CANCEL = 'cancel',
  DONE = 'done',
  START = 'start',
}

export enum PdfSignatureAnnotations {
  ALL = 'all',
  NONE = 'none',
  SIGN_HERE = 'sign_here',
}

export enum PdfWidgetType {
  SIGNATURE = 'signature',
  SIGN_HERE = 'sign_here',
}

export interface PdfAttachment {
  content: Uint8Array;
  filename: string;
}

export interface PdfFieldPlaceEvent {
  widgetId: number;
  state: PdfPlaceWidgetState;
}

export interface PdfFieldUpdateEvent {
  widgetId: number;
  documentField: DocumentField;
}

export interface PdfPageInfo {
  rotation: number;
  scale: number;
  viewport: PdfPageViewport;
}

export interface PdfPageLayerSignatureEntry {
  pageNumber: number;
  instance: PdfPageLayerSignatureInstance;
}

interface PdfPageRawDims {
  pageHeight: number;
  pageWidth: number;
  pageX: number;
  pageY: number;
}

export interface PdfPageSource {
  div: HTMLElement;
  info: PdfPageInfo;
  pageNumber: number;
}

interface PdfPageViewport {
  height: number;
  rawDims: PdfPageRawDims;
  rotation: number;
  scale: number;
  width: number;
}

export interface PdfPlaceWidgetData {
  widget: PdfWidgetConfig;
  state: PdfPlaceWidgetState;
}

export interface PdfRectDims {
  height: number;
  width: number;
}

export interface PdfRectDimsOptional {
  height?: number;
  width?: number;
}

export interface PdfScaleOption {
  custom?: boolean;
  textKey?: string;
  value: string;
}

export interface PdfScroll {
  left: number;
  top: number;
}

export interface PdfToolCustom {
  callback: () => void,
  icon: string;
  textKey: string;
  visible: boolean;
}

export interface PdfViewerInterface {
  documentDataReload(): Promise<void>
  pageNext(): void;
  pagePrev(): void;
  pagesRefresh(): void;
  pagesRotate(delta: number): void;
  setCurrentPageNumber(value: number): void;
  setCurrentScaleValue(value: string): void;
  zoomIn(): void;
  zoomOut(): void;
}

export interface PdfViewerPropsPrimary {
  currentStep: FlowStep | undefined;
  flow: Flow;
}

export interface PdfWidgetConfig {
  editable: boolean;
  id: number;
  pageNumber: number;
  params: PdfWidgetSignatureParams;
  rect: PdfWidgetRect;
  removable: boolean;
  type: PdfWidgetType;
}

export interface PdfWidgetRect extends PdfRectDims {
  left: number;
  bottom: number;
}

export interface PdfWidgetRestrictionExpressions {
  maxHeight: Array<math.EvalFunction>,
  maxWidth: Array<math.EvalFunction>,
  minHeight: Array<math.EvalFunction>,
  minWidth: Array<math.EvalFunction>
}

export interface PdfWidgetRestrictionScope {
  FIELD_HEIGHT: number;
  FIELD_WIDTH: number;
  PAGE_HEIGHT: number;
  PAGE_WIDTH: number;
}

export interface PdfWidgetRestrictions {
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
}

export interface PdfWidgetSignatureParams {
  field: DocumentField;
  fieldOrig?: DocumentField;
  title?: string;
  transformed?: boolean;
}

export interface PdfWidgetsAdjacent {
  next: number | undefined;
  prev: number | undefined;
}

export type PdfPageLayerSignatureInstance = InstanceType<typeof PDFPageLayerSignature>;