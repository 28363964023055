


















































import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import {PropType} from "vue";
import {FlowEnclosure, isDataEnclosure, PdfAttachment} from "@/types";
import {downloadBlob} from "@/utils/utils";
import {AxiosResponse} from "axios";

export default mixins(mutationMixin).extend({
  name: 'FlowAttachmentItem',
  components: {},
  props: {
    attachment: {
      type: Object as PropType<PdfAttachment | FlowEnclosure>,
    },
    fileOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    downloadEnclosureData(enclosure: FlowEnclosure) {
      if (isDataEnclosure(enclosure) && enclosure._links['sef:download-enclosure-data']) {
        this.axios.get(enclosure._links['sef:download-enclosure-data'].href, {
          responseType: 'blob'
        }).then((response: AxiosResponse) => {
          downloadBlob(new Blob([response.data]), enclosure.filename);
        })
      }
    },
    downloadAttachment(attachment: PdfAttachment): void {
      downloadBlob(new Blob([attachment.content]), attachment.filename);
    }
  }
});
