






















import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";

export type MenuItem = {
  name: string,
  text: string,
  icon: string,
  url: string,
  visible: boolean
}

export default mixins(mutationMixin).extend({
  name: "SListMenu",
  props: {
    baseUrl: String,
    items: Array as () => Array<MenuItem>
  },
  data() {
    return {
      selectedItem: 0
    }
  },
  methods: {
    navigateTo(path: string) {
      if (this.$router.currentRoute.path !== path) {
        // We need to keep query parameter "online-signature-redirect-uri" across all router views
        let queryParams = {'online-signature-redirect-uri': this.$router.currentRoute.query["online-signature-redirect-uri"]} // todo this is sp specific
        this.$router.push({path: `/${this.baseUrl}/${path}`, query: queryParams});
      }
    }
  },
  computed: {
    currentUrl(): string {
      const url = this.$route.path;
      return url.substring(url.lastIndexOf('/') + 1);
    },
    filteredItems(): Array<MenuItem> {
      return this.items.filter(item => item.visible)
    },
    hasActionSlot(): boolean {
      return !!this.$scopedSlots['item-action']
    }
  },
  watch: {
    selectedItem(newValue: number) {
      this.navigateTo(this.items[newValue].url)
    },
    currentUrl: {
      immediate: true,
      handler(newUrl: string) {
        const itemIndex = this.items.findIndex(item => item.url === newUrl)
        if (itemIndex < 0) {
          // unknown item, navigate to the first one
          this.navigateTo(this.items[0].url)
        }
        this.selectedItem = itemIndex;
      }
    }
  }
});
