
























































import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import batchMixin from "@/mixins/batchMixin";
import displayMixin from "@/mixins/displayMixin";
import {apiFlowStepsReject} from "@/api/flowStepsApi";
import {apiWorkflowsRecall} from "@/api/workflowsApi";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SForm from "@/component/ui/form/SForm.vue";
import SDialog from "@/component/ui/SDialog.vue";
import STextarea from "@/component/ui/form/STextarea.vue";
import FlowBatchItem from "@/component/workflow/batch/FlowBatchItem.vue";
import {BatchProcessed, BatchProcessedAction, CancelType, Flow, NoteInputMode, VariantMode} from "@/types";
import {BulkChangeResult} from "@/types/dto";

interface CollapsableFlowItem {
  flow: Flow;
  collapsed: boolean;
}

export default mixins(batchMixin, displayMixin).extend({
  name: 'BatchRejectionDialog',

  components: {FlowBatchItem, STextButton, SDialog, STextarea, SForm},

  props: {
    value: Boolean,
    flows: {
      type: Array as PropType<Flow[]>,
      default: () => [],
    },
    batch: {
      type: Boolean,
      default: false,
    },
    recall: Boolean,
  },

  data() {
    return {
      items: [] as Array<CollapsableFlowItem>,
      note: undefined as string | undefined,
      finished: false,
      processing: false,
      changeResult: null as BulkChangeResult | null,
      isValid: false
    }
  },

  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    dialogTitle(): string {
      if (this.localValue) {
        return this.isBatch ? this.$tc(`workflow.${this.cancelType}.batch.title`, this.selectedFlowCount).toString() :
            this.$t(`workflow.${this.cancelType}.single.title`, {subject: this.currentFlow.subject}).toString();
      }
      else {
        return '';
      }
    },
    currentFlow(): Flow {
      return this.flows[0];
    },
    isBatch(): boolean {
      return this.batch;
    },
    selectedFlowCount(): number {
      return this.flows.length;
    },
    selectedFlowIds(): Array<number> {
      return this.selectedFlows.map((flow: Flow): number => flow.flowId);
    },
    selectedFlows(): Array<Flow> {
      return this.flows;
    },
    cancelType(): string {
      return this.recall ? 'recall' : 'rejection';
    },
    submitText(): string {
      return this.$t(`signature.online.${this.cancelType}.actions.submit`).toString();
    },
    reasonPlaceholder(): string {
      if (this.cancelTextConfig.placeholder || this.cancelTextConfig.placeholder == "") {
        return this.cancelTextConfig.placeholder;
      }
      else {
        switch (this.cancelTextConfig.noteInputMode) {
          case NoteInputMode.OPTIONAL:
            return this.$tc(`workflow.${this.cancelType}.reasonPlaceholder.optional`,
                this.selectedFlowCount).toString();
          case NoteInputMode.REQUIRED:
            return this.$tc(`workflow.${this.cancelType}.reasonPlaceholder.required`,
                this.selectedFlowCount).toString();
          default:
            return "";
        }
      }
    },
    cancelInstructions(): string {
      return this.cancelTextConfig.instructions ?? "";
    },
    cancelTextConfig(): CancelType {
      return this.recall ? this.fullVariant.recall : this.fullVariant.rejection;
    },
    fullVariant(): VariantMode {
      return this.$store.getters['config/fullVariant'];
    },
    reasonValidation(): string {
      return this.rejectionInputRequired ? 'required|max:500' : 'max:500';
    },
    rejectionInputNotAllowed(): boolean {
      return this.cancelTextConfig.noteInputMode == NoteInputMode.NOT_ALLOWED
    },
    rejectionInputRequired(): boolean {
      return this.cancelTextConfig.noteInputMode == NoteInputMode.REQUIRED
    }
  },

  watch: {
    localValue(value: boolean) {
      if (value)
        this.prepareItems();
      else
        this.reset();
    }
  },

  methods: {
    prepareItems(): void {
      this.selectedFlows.forEach(flow => {
        let item: CollapsableFlowItem = {
          flow: flow,
          collapsed: this.selectedFlowCount >= 10
        };
        this.items.push(item);
      });
    },
    async reject(): Promise<void> {
      const isFormValid = await (this.$refs.rejectionForm as InstanceType<typeof SForm>).validate();
      if (!isFormValid) return;

      this.processing = true;
      const ids = this.flows.map((flow) => this.recall ? flow.flowId : flow.myActiveStepId);

      try {
        if (this.recall)
          this.changeResult = await apiWorkflowsRecall(ids, this.note);
        else
          this.changeResult = await apiFlowStepsReject(ids, this.note);

        const succeededFlows = this.flows.filter(
            (flow: Flow): boolean => !!this.changeResult?.succeeded.includes(
                this.recall ? flow.flowId : flow.myActiveStepId));

        if (this.isBatch) {
          this.$store.commit('batches/batchProcessed', {
            action: BatchProcessedAction.REJECT,
            flowIds: this.selectedFlowIds,
            flowsPath: this.$route.path
          } as BatchProcessed);
        }
        else {
          this.$store.commit('workflow/addDisabledFlow', { flow: succeededFlows });
          this.$store.commit('navigation/refreshCounters');
        }

        if (this.changeResult.failed) {
          this.$store.commit('notification/showMessage', {
            content: this.$tc(`workflow.${this.cancelType}.result.failure`, ids.length),
            type: 'error'
          });
        }

        if (succeededFlows.length === ids.length) {
          this.$store.commit('notification/showMessage', {
            content: this.$tc(`workflow.${this.cancelType}.result.success`, succeededFlows.length),
            type: 'info'
          });
          this.$emit('reject-successful');
        }
      }
      catch (e) {
        console.error('Error occurred while rejecting signatures in bulk', e);
      }
      finally {
        this.processing = false;
        this.finished = true;
      }
    },
    hasChangeSucceeded(stepId: number): boolean {
      return this.changeResult?.succeeded.includes(stepId) || false;
    },
    hasChangeFailed(stepId: number): boolean {
      return this.changeResult?.failed.includes(stepId) || false;
    },
    async reset(): Promise<void> {
      this.items = [];
      this.note = undefined;
      this.finished = false;
      this.changeResult = null;
      this.processing = false;
      (this.$refs.rejectionForm as InstanceType<typeof SForm>).reset();
    },
    close(): void {
      this.localValue = false;
    },
    collapseAll(): void {
      this.items.forEach(i => i.collapsed = true);
    },
    expandAll(): void {
      this.items.forEach(i => i.collapsed = false);
    },
    itemIcon(item: CollapsableFlowItem): string|undefined {
      if(this.hasChangeSucceeded(item.flow.myActiveStepId)){
        return '$success';
      } else if(this.hasChangeFailed(item.flow.myActiveStepId)){
        return '$errorCircle';
      } else {
        return undefined;
      }
    },
    itemIconColor(item: CollapsableFlowItem): string|undefined {
      if(this.hasChangeSucceeded(item.flow.myActiveStepId)){
        return 'success';
      } else if(this.hasChangeFailed(item.flow.myActiveStepId)){
        return 'mainContrast';
      } else {
        return undefined;
      }
    }
  }
})
