
















import SFileSelector from "@/component/ui/form/SFileSelector.vue";
import {imageToBase64} from "@/utils/utils";
import formMixin from "@/mixins/formMixin";
import mixins from "vue-typed-mixins";
import mobileMixin from "@/mixins/mobileMixin";

export default mixins(formMixin, mobileMixin).extend({
  name: 'SBase64ImageSelector',
  components: {SFileSelector},
  props: {
    appendText: String,
    label: String,
    validations: {
      type: [String, Object],
      default: 'mimes:image/jpeg,image/png|size:4096'
    },
    value: String,
  },
  data(){
    return {
      file: undefined as File|undefined
    }
  },
  computed: {
    localValue: {
      get(): string|undefined {
        return this.value;
      },
      set(value: string|undefined) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    onFileRemoved(): void {
      this.localValue = undefined;
    },
    clear(): void {
      (this.$refs.fileSelector as InstanceType<typeof SFileSelector>).reset();
    },
    resetValidations(){
      (this.$refs.fileSelector as InstanceType<typeof SFileSelector>).resetValidations();
    }
  },
  watch: {
    file (value: File|undefined) {
      if (value) {
        imageToBase64(value).then(base64 => {
          this.localValue = base64.split(',')[1];
        });
      } else {
        this.localValue = undefined;
      }
    },
    localValue(value: string|undefined){
      if(!value) this.file = undefined;
    }
  }
})
