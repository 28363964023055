import Vue from "vue";
import {redirectTo} from "@/utils/utils";
import {Document} from "@/types";

export default Vue.extend({
  methods: {
    validateDocument(document: Document): void {
      if (document?._links?.['sef:validate-doc']?.href) {
        redirectTo(document._links['sef:validate-doc'].href, true)
      }
    }
  }
})