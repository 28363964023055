import axios from "@/plugin/vueAxios";
import {Flow} from "@/types";
import {BulkChangeResult} from "@/types/dto";

export const WORKFLOWS_PATH = '/workflows'
export const WORKFLOWS_RECALLED_PATH = '/workflows/recalled'

export async function apiWorkflowGet(flowId: number): Promise<Flow> {
  const response = await axios.get(`${WORKFLOWS_PATH}/${flowId}`);
  return response.data as Flow;
}

export async function apiWorkflowsRecall(flowIds: Array<number>, recallNote?: string): Promise<BulkChangeResult> {
  const response = await axios.post(WORKFLOWS_RECALLED_PATH, {
    flowIds,
    recallNote
  });
  return response.data as BulkChangeResult;
}