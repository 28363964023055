import Vue from "vue";
import {DisplayVariant} from "@/types";

export default Vue.extend({
  computed: {
    displayActive(): DisplayVariant {
      return this.forcedDisplay ?? this.displayVariant;
    },
    displayDesktop: {
      get(): boolean {
        return this.forcedDisplay === DisplayVariant.DESKTOP ||
            (!this.forcedDisplay && this.displayVariant === DisplayVariant.DESKTOP);
      },
      set(value: boolean) {
        this.$store.commit('display/setForcedDisplay', value ? DisplayVariant.DESKTOP : undefined);
      }
    },
    displayMobile: {
      get(): boolean {
        return this.forcedDisplay === DisplayVariant.MOBILE ||
            (!this.forcedDisplay && this.displayVariant === DisplayVariant.MOBILE);
      },
      set(value: boolean) {
        this.$store.commit('display/setForcedDisplay', value ? DisplayVariant.MOBILE : undefined);
      }
    },
    displayTablet: {
      get(): boolean {
        return this.forcedDisplay === DisplayVariant.TABLET ||
            (!this.forcedDisplay && this.displayVariant === DisplayVariant.TABLET);
      },
      set(value: boolean) {
        this.$store.commit('display/setForcedDisplay', value ? DisplayVariant.TABLET : undefined);
      }
    },
    displayVariant(): DisplayVariant {
      // Temporary fix - Device detection is unreliable, the UI should be responsive in and user flow in a way where such measures are unnecessary!
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent);
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return DisplayVariant.MOBILE;
        case 'sm':
        case 'md':
          if (isTablet) return DisplayVariant.MOBILE
          else return DisplayVariant.TABLET;
        case 'lg':
        case 'xl':
        default:
          return DisplayVariant.DESKTOP;
      }
    },
    forcedDisplay: {
      get(): DisplayVariant | undefined {
        return this.$store.getters['display/forcedDisplay'];
      },
      set(value: DisplayVariant | undefined) {
        this.$store.commit('display/setForcedDisplay', value);
      }
    }
  }
})
