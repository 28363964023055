import { render, staticRenderFns } from "./AccountInfo.vue?vue&type=template&id=5a176dfc&scoped=true&"
import script from "./AccountInfo.vue?vue&type=script&lang=ts&"
export * from "./AccountInfo.vue?vue&type=script&lang=ts&"
import style0 from "./AccountInfo.vue?vue&type=style&index=0&id=5a176dfc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a176dfc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
installComponents(component, {VBadge})
