






























import {keycloak} from "@/plugin/vueKeycloak";
import InteractionCenter from "@/component/notifications/list/InteractionCenter.vue";
import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import displayMixin from "@/mixins/displayMixin";

interface MenuAction {
  icon: string;
  text: string;
  action: () => void;
}

export default mixins(mutationMixin, displayMixin).extend({
  name: 'NavigationUserPopover',
  components: {InteractionCenter},
  props:{
    visible: Boolean
  },
  computed: {
    actions(): Array<MenuAction> {
      return [
        {
          icon: 'settings',
          text: 'common.profileConfiguration',
          action: this.goToProfile
        },
        {
          icon: 'bell',
          text: 'common.notification',
          action: this.goToNotifications
        },
        {
          icon: 'logout',
          text: 'common.actions.logout',
          action: this.logout
        }
      ]
    }
  },
  methods: {
    goToProfile(): void {
      this.$emit("profile");
    },
    goToNotifications(): void {
      this.$emit("notifications");
    },
    logout(): void {
      keycloak.logout();
    }
  },
  created(){
    this.subscribe((mutation) => {
      if (mutation.type === 'workflow/setFlowActive' && this.displayMobile) {
        this.$emit('close-nav');
      }
    });
  }
})
