import {BATCHES, FLOW_STEPS_BULK} from "@/utils/urls";
import Vue from "vue";
import {BatchSigningProfilesInfo, BulkChangeResult} from "@/types/dto";
import {Batch, BatchCreationMode} from "@/types";

export default Vue.extend({
    methods: {
        async apiGetBulkSigningProfilesInfo(stepIds: Array<number>): Promise<BatchSigningProfilesInfo> {
            const response = await this.axios.post(`${FLOW_STEPS_BULK}/signing-profiles-info`, {
                stepIds
            });
            return response.data as BatchSigningProfilesInfo;
        },
        async apiBulkChangeSigningProfile(changeList: Array<{profileId: number, stepId: number}>): Promise<BulkChangeResult> {
            const response = await this.axios.post(`${FLOW_STEPS_BULK}/signing-profiles`, {
                pairings: changeList
            });
            return response.data as BulkChangeResult;
        },
        async apiBatchPost(creationMode: BatchCreationMode, stepIds: Array<number>, batchName: string|undefined, bulkMode = true,
                           signingTextName:string|undefined = undefined, signatureImage:string|undefined = undefined): Promise<Batch> {
            const steps = stepIds.map(stepId => ({stepId: stepId}));
            const response = await this.axios.post(BATCHES, {
                batchName: batchName,
                bulkMode: bulkMode,
                creationMode: creationMode,
                signingTextName: signingTextName,
                signatureImage: signatureImage,
                _embedded: {
                    steps: steps
                }
            });
            return response.data as Batch;
        },
        async apiBatchReport(stepIds: Array<number>): Promise<Batch> {
            return this.apiBatchPost(BatchCreationMode.DO_NOT_CREATE, stepIds, undefined);
        }
    }
})
