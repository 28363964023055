import {keycloak} from "@/plugin/vueKeycloak";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";

interface AuthState {
  idToken: string;
  accessToken: string;
}

const state = (): AuthState => ({
  idToken: '',
  accessToken: ''
})

const mutations: MutationTree<AuthState> = {
  login(state, payload: AuthState) {
    state.idToken = payload.idToken;
    state.accessToken = payload.accessToken;
  },
  refresh(state, payload: AuthState) {
    state.idToken = payload.idToken;
    state.accessToken = payload.accessToken;
  },
  logout(state) {
    state.idToken = "";
    state.accessToken = "";
  }
}

const actions: ActionTree<AuthState, RootState> = {
  updateToken({commit}, minValidity) : Promise<void> {
    return new Promise((resolve, reject) => {
      keycloak.updateToken(minValidity).then((refreshed: boolean) => {
        if (refreshed) {
          console.info('Authentication Token refreshed');
          //Token validity
          //console.warn(Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          commit("refresh", {
            idToken: keycloak.idToken,
            accessToken: keycloak.token
          });
          resolve();
        }
      }).catch(() => {
        console.error('Failed to refresh token');
        reject();
      });
    });
  }
}

const getters: GetterTree<AuthState, RootState> = {
  token: (state): string => {
    return state.accessToken;
  },
}

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
