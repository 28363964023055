











import {ValidationObserver} from 'vee-validate'
import Vue, {PropType} from "vue";

export default Vue.extend({
  name: 'SForm',
  components: {
    ValidationObserver
  },
  props: {
    /**
     * Funkce pro manuální/asynchronní validaci celého formuláře. Funkce musí vracet objekt obsahující výsledek validace
     * ve formátu "{ vid: ['message'] }".
     */
    handleValidation: Function as PropType<() => Record<string, string[] | string>>,
    /**
     * Funkce provádějící odeslání formuláře.
     */
    handleSubmit: Function as PropType<() => void>
  },
  computed: {
    locale(): string {
      return this.$i18n.locale;
    }
  },
  watch: {
    // Detekce změny lokalizace a přegenerování validačních zpráv.
    locale() {
      (this.$refs.formValidator as InstanceType<typeof ValidationObserver>).validate();
    }
  },
  methods: {
    onSubmit(): void {
      if (this.handleValidation) {
        let errors = this.handleValidation();
        if (errors && Object.keys(errors).length > 0) {
          (this.$refs.formValidator as InstanceType<typeof ValidationObserver>).setErrors(errors);
          return;
        }
      }
      if (this.handleSubmit)
        this.handleSubmit();
    },

    validate(): Promise<boolean> {
      return (this.$refs.formValidator as InstanceType<typeof ValidationObserver>).validate();
    },

    reset(): void {
      (this.$refs.formValidator as InstanceType<typeof ValidationObserver>).reset();
    }

  }
})
