import axios from "@/plugin/vueAxios";
import {BatchSigningProfilesInfo, BulkChangeResult} from "@/types/dto";
import {FlowStepsCounts} from "@/types";

export const FLOW_STEPS_PATH = '/flow-steps'
export const FLOW_STEPS_COUNTS_PATH = `${FLOW_STEPS_PATH}/counts`
export const FLOW_STEPS_REJECTED_PATH = `${FLOW_STEPS_PATH}/rejected`
export const SIGNING_PROFILES_INFO_PATH = `${FLOW_STEPS_PATH}/bulk/signing-profiles-info`

export async function apiFlowStepsCounts(): Promise<FlowStepsCounts> {
  const response = await axios.get(FLOW_STEPS_COUNTS_PATH);
  return response.data as FlowStepsCounts;
}

export async function apiFlowStepsReject(stepIds: Array<number>, rejectionNote?: string): Promise<BulkChangeResult> {
  const response = await axios.post(FLOW_STEPS_REJECTED_PATH, {
    stepIds,
    rejectionNote
  });
  return response.data as BulkChangeResult;
}

export async function apiSigningProfilesInfo(stepIds: Array<number>): Promise<BatchSigningProfilesInfo> {
  const response = await axios.post(SIGNING_PROFILES_INFO_PATH, {
    stepIds
  });
  return response.data as BatchSigningProfilesInfo;
}