














import {SEARCH} from "@/utils/urls";
import {toArray} from "@/utils/utils";
import {SearchKeywordsDTO} from "@/types/navigation";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import FlowList from "@/component/workflow/list/FlowList.vue";
import SList from "@/component/ui/SList.vue";

export default mixins(displayMixin).extend({
  name: 'OverviewContent',
  components: {FlowList, SList},
  props: {
    folderId: String,
    search: Boolean
  },
  computed: {
    folderUrl(): string|undefined {
      if(!this.folderId) return undefined;
      return this.$store.getters['navigation/url'](this.folderId);
    },
    searchQueryParams(): string|undefined {
      if(!this.search) return undefined;
      const query = this.$route.query as SearchKeywordsDTO;
      let accounts = toArray(query.account).map(a => 'account=' + encodeURIComponent(a));
      let subjects = toArray(query.subject).map(s => 'subject=' + encodeURIComponent(s));
      let tags = toArray(query.tag).map(t => 'tag=' + encodeURIComponent(t));
      let category = query.category ? "categoryCode=" + encodeURIComponent(query.category) : "";
      return accounts.concat(subjects).concat(tags).concat(category).join('&');
    },
    state(): string|undefined {
      if(this.search) return 'search';
      return this.$store.getters['navigation/state'](this.folderId);
    },
    url(): string | undefined {
      if (this.search) {
        return SEARCH + "?" + this.searchQueryParams;
      }
      else {
        return this.folderUrl;
      }
    },
    manualFlowCreationEnabled(): boolean {
      return this.$store.getters['config/manualFlowCreationEnabled'];
    },
    hasCategory(): boolean {
      return this.$store.getters['config/categories'].length >= 1;
    }
  },
  methods: {
    createWorkflow(){
      this.$store.commit('workflow/openWorkflowWizard');
    }
  }
})
