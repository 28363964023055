


























import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";

export default mixins(displayMixin).extend({
  name: 'CenteredPanelLayout'
})
