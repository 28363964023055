


































































































import Vue, {PropType} from "vue";
import {VuetifyThemeItem} from "vuetify/types/services/theme";
import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import PDFPageLayerSignature from "@/component/pdf/layers/PDFPageLayerSignature.vue";
import PDFSidebar from "@/component/pdf/sidebar/PDFSidebar.vue";
import PDFToolbar from "@/component/pdf/toolbar/PDFToolbar.vue";
import PDFViewerCore from "@/component/pdf/PDFViewerCore.vue";
import {i18n} from "@/plugin/i18n";
import {vuetify} from "@/plugin/vuetify";
import {createPayload} from "@/store/modules/pdfViewer";
import store from "@/store/store";
import {getStepActive} from "@/utils/flowUtils";
import {
  createSignatureWidget,
  createSignHereWidget,
  emptyDocumentField,
  PDF_FILENAME_DEFAULT,
  transformToDocumentField
} from "@/utils/pdfViewerUtils";
import {downloadBlob} from "@/utils/utils";
import {
  DocumentField,
  DocumentFieldType,
  Flow,
  FlowStep,
  PdfAttachment,
  PdfPageLayerSignatureEntry,
  PdfPageLayerSignatureInstance,
  PdfPageSource,
  PdfPlaceWidgetState,
  PdfScroll,
  PdfSignatureAnnotations,
  PdfViewerInterface,
  PdfViewerPropsPrimary,
  PdfWidgetType,
  VisualFieldUsage
} from "@/types";

/**
 * Prohlížeč PDF dokumentů. Samotné zobrazení PDF dokumentu zajišťuje prohlížeč implementovaný v rámci knihovny PDF.js.
 */
export default mixins(mutationMixin).extend({
  name: 'PDFViewer',

  components: {PDFPageLayerSignature, PDFSidebar, PDFToolbar, PDFViewerCore},

  props: {
    color: {
      type: String,
      default: 'secondaryBackground'
    },
    customTools: Array,
    flow: {
      type: Object as PropType<Flow>,
      required: true
    },
    flowStepCurrent: Object as PropType<FlowStep | undefined>,
    fullscreen: Boolean,
    hasFlowDetailAction: {
      type: Boolean,
      default: false
    },
    id: String,
    signatureAnnotations: {
      type: String,
      default: PdfSignatureAnnotations.NONE
    },
    signatureAnnotationsReadonly: Boolean,
    toolbar: {
      type: Boolean,
      default: true
    },
    dataTestidSnackBarSuffix: {
      type: String,
      default: "",
    }
  },

  data() {
    return {
      currentPageNumber: 0,
      currentScaleValue: 'auto',
      pagesCount: 0,
      pagesRotation: 0,
      pdfDocumentAttachments: undefined,
      pdfDocumentLoaded: false,
      pdfDocumentLoading: false,
      pdfViewerId: -1,
      pdfViewerInterface: undefined as PdfViewerInterface | undefined,
      pdfViewerScrollTop: 0,
      sidebarVisible: false, // Momentálně se sidebar už nevyužívá, ale je zde k dispozici pokud bude potřeba
      signatureLayerPages: {} as Record<number, PdfPageLayerSignatureEntry>
    }
  },

  computed: {
    authToken(): string {
      return this.$store.getters['auth/token'];
    },
    currentStep(): FlowStep | undefined {
      return this.flowStepCurrent ?? getStepActive(this.flow);
    },
    documentDataPath(): string | undefined {
      return this.flow._links['sef:document-data-download']?.href;
    },
    documentDataUrl(): string {
      return (this.axios.defaults.baseURL ?? '') + (this.documentDataPath ?? '');
    },
    documentFields(): Array<DocumentField> {
      return this.flow._embedded?.documentFields ?? [];
    },
    documentFieldAssigned(): DocumentField | undefined {
      /* Podpisové pole přiřazené přímo tvůrcem workflow. Povoleno je pouze jedno. */
      const stepId = this.currentStep?.stepId;

      return stepId ? this.documentFields.find(field => field.stepId === stepId && !field.setBySigner) : undefined;
    },
    documentFieldsUnassigned(): Array<DocumentField> {
      /* Předdefinovaná podpisová pole nepřiřazená nikomu. */
      return this.documentFields.filter(field => field.stepId === undefined);
    },
    documentFieldUsed(): DocumentField | undefined {
      /* Podpisové pole použité/vybrané uživatelem. Povoleno je pouze jedno. */
      const stepId = this.currentStep?.stepId;

      return stepId ? this.documentFields.find(field => field.stepId === stepId && field.setBySigner) : undefined;
    },
    documentName(): string | undefined {
      return this.flow._embedded.document?.documentName;
    },
    filename(): string {
      return this.documentName || PDF_FILENAME_DEFAULT;
    },
    outerContainerStyle(): Record<string, VuetifyThemeItem> {
      return {
        backgroundColor: this.$vuetify.theme.currentTheme[this.color] ?? this.color
      };
    },
    placeWidgetActive(): boolean {
      return !!this.$store.getters['pdfViewer/placeWidget'](this.pdfViewerId);
    },
    placeWidgetText(): string | undefined {
      const widgetType = this.$store.getters['pdfViewer/placeWidget'](this.pdfViewerId)?.type;
      switch (widgetType) {
        case PdfWidgetType.SIGNATURE:
          return this.$t('pdfViewer.fields.signature.instruction').toString();
        case PdfWidgetType.SIGN_HERE:
          return this.$t('pdfViewer.fields.signHere.instruction').toString();
        default:
          return undefined;
      }
    },
    propsPrimary(): PdfViewerPropsPrimary {
      return {
        currentStep: this.currentStep,
        flow: this.flow
      };
    }
  },

  watch: {
    propsPrimary: {
      deep: true,
      async handler(newValue: PdfViewerPropsPrimary, oldValue: PdfViewerPropsPrimary): Promise<void> {
        this.pdfDocumentLoaded = false;

        /* Když se změní flow, změní se i currentStep. Případně se změní pouze currentStep. */
        this.$store.commit('pdfViewer/setCurrentStep', createPayload(this.pdfViewerId, newValue.currentStep));
        this.$store.commit('pdfViewer/setDefaultSignHereWidgetUsage', createPayload(this.pdfViewerId, true));
        this.$store.commit('pdfViewer/resetSignatureLayer', this.pdfViewerId);

        if (newValue.flow?.flowId !== oldValue.flow?.flowId) {
          /* Změna flow */
          this.removeSignatureLayerPages();
        }
        await this.createFields();
      }
    },
    async signatureAnnotations(value: PdfSignatureAnnotations): Promise<void> {
      this.$store.commit('pdfViewer/setSignatureAnnotations', createPayload(this.pdfViewerId, value));
    },
    signatureAnnotationsReadonly(value: boolean): void {
      this.$store.commit('pdfViewer/setSignatureLayerReadonly', createPayload(this.pdfViewerId, value));
    }
  },

  async created() {
    this.pdfViewerId = await this.$store.dispatch('pdfViewer/initData');

    this.subscribe((mutation) => {
      if (mutation.type.startsWith('pdfViewer') && mutation.payload && mutation.payload.pdfViewerId ===
          this.pdfViewerId) {
        /* Obsluha mutací, které náleží konkrétnímu prohlížeči PDF */

        if (mutation.type === 'pdfViewer/setPlaceWidget') {
          const placeWidgetData = mutation.payload.data;
          if (placeWidgetData.widget) {
            this.$emit('place-field', {
              widgetId: placeWidgetData.widget.id,
              state: placeWidgetData.state
            });
          }
        }
        if (mutation.type === 'pdfViewer/sign') {
          this.$emit('sign');
        }
        if (mutation.type === 'pdfViewer/signatureWidgetAdded') {
          const widget = mutation.payload.data;
          this.$emit('signature-field-added', {
            widgetId: widget.id,
            documentField: transformToDocumentField(widget)
          });
        }
        if (mutation.type === 'pdfViewer/signatureWidgetRemoved') {
          const widgetId = mutation.payload.data;
          this.$emit('signature-field-removed', widgetId);
        }
        if (mutation.type === 'pdfViewer/signatureWidgetUpdated') {
          const widget = mutation.payload.data;
          this.$emit('signature-field-updated', {
            widgetId: widget.id,
            documentField: transformToDocumentField(widget)
          });
        }
        if (mutation.type === 'pdfViewer/signHereWidgetAdded') {
          const widget = mutation.payload.data;
          this.$emit('sign-here-field-added', {
            widgetId: widget.id,
            documentField: transformToDocumentField(widget)
          });
        }
        if (mutation.type === 'pdfViewer/signHereWidgetRemoved') {
          const widgetId = mutation.payload.data;
          this.$emit('sign-here-field-removed', widgetId);
        }
        if (mutation.type === 'pdfViewer/signHereWidgetUpdated') {
          const widget = mutation.payload.data;
          this.$emit('sign-here-field-updated', {
            widgetId: widget.id,
            documentField: transformToDocumentField(widget)
          });
        }
      }
    });

    this.$store.commit('pdfViewer/setCurrentStep', createPayload(this.pdfViewerId, this.currentStep));
    this.$store.commit('pdfViewer/setSignatureAnnotations', createPayload(this.pdfViewerId, this.signatureAnnotations));
    this.$store.commit('pdfViewer/setSignatureLayerReadonly',
        createPayload(this.pdfViewerId, this.signatureAnnotationsReadonly));

    await this.createFields();
  },

  async beforeDestroy() {
    await this.$store.dispatch('pdfViewer/deleteData', this.pdfViewerId);
    this.removeSignatureLayerPages();
  },

  methods: {
    /*
     * PDF Viewer
     */
    async documentDataReload(): Promise<void> {
      await this.pdfViewerInterface?.documentDataReload();
    },
    async download(): Promise<void> {
      try {
        const response = await this.axios.get(this.documentDataUrl, {
          responseType: 'blob'
        });
        downloadBlob(new Blob([response.data]), this.filename);
      }
      catch (e) {
        console.error(`An error occurred while downloading the PDF document "${this.filename}"`, e);
      }
    },
    fullscreenToggle(): void {
      this.$emit('fullscreen-toggle');
    },
    onAttachmentsLoaded(value: PdfAttachment): void {
      this.$emit('document-attachments', value);
    },
    onDocumentError(): void {
      this.pdfDocumentLoading = false;
      this.$emit('document-error');
    },
    onDocumentLoaded(): void {
      this.pdfDocumentLoading = false;
      this.pdfDocumentLoaded = true;
      this.$emit('document-loaded');
    },
    onDocumentLoading(): void {
      this.pdfDocumentLoading = true;
    },
    onPageRendered(source: PdfPageSource): void {
      /* Vykreslení podpisové vrstvy přes stránku PDF dokumentu */
      this.renderSignatureLayer(source);
    },
    onScroll(value: PdfScroll): void {
      this.pdfViewerScrollTop = value.top;
    },
    pageFirst(): void {
      this.pdfViewerInterface?.setCurrentPageNumber(1);
    },
    pageLast(): void {
      this.pdfViewerInterface?.setCurrentPageNumber(this.pagesCount);
    },
    pageNext(): void {
      this.pdfViewerInterface?.pageNext();
    },
    pagePrev(): void {
      this.pdfViewerInterface?.pagePrev();
    },
    pagesRefresh(): void {
      this.pdfViewerInterface?.pagesRefresh();
    },
    pagesRotate(delta: number): void {
      this.pdfViewerInterface?.pagesRotate(delta);
    },
    setCurrentPageNumber(value: number): void {
      this.pdfViewerInterface?.setCurrentPageNumber(value);
    },
    setCurrentScaleValue(value: string): void {
      this.pdfViewerInterface?.setCurrentScaleValue(value);
    },
    zoomIn(): void {
      this.pdfViewerInterface?.zoomIn();
    },
    zoomOut(): void {
      this.pdfViewerInterface?.zoomOut();
    },
    /*
     * Správa podpisových polí
     */
    async addSignatureField(): Promise<void> {
      if (this.signatureAnnotationsReadonly) {
        console.warn('The operation "addSignatureField" is not allowed when annotations are read-only');
      }
      else if (this.signatureAnnotations !== PdfSignatureAnnotations.ALL) {
        console.warn('The operation "addSignatureField" is not allowed when signature annotations are disabled');
      }
      else {
        const signHereWidgets = this.$store.getters['pdfViewer/signHereWidgets'](this.pdfViewerId, null);
        const defaultSignHereWidgetUsage = this.$store.getters['pdfViewer/defaultSignHereWidgetUsage'](this.pdfViewerId);
        if (signHereWidgets.length > 0 && defaultSignHereWidgetUsage) {
          /* Při prvním umístění se automaticky použije předdefinované podpisové pole, existuje-li. Při opakovaném umístění je potřeba umístění provést ručně. */
          const signHereWidgetId = signHereWidgets[0].id;
          await this.$store.dispatch('pdfViewer/useSignHereWidget', createPayload(this.pdfViewerId, signHereWidgetId));
          this.$store.commit('pdfViewer/setDefaultSignHereWidgetUsage', createPayload(this.pdfViewerId, false));
        }
        else {
          /* Umístění podpisu je potřeba provést ručně. */
          const field = structuredClone(emptyDocumentField);

          const signatureWidget = await createSignatureWidget(this.pdfViewerId, field, true, true);

          /* Umístění widgetu podpisu */
          const placeWidgetData = {
            widget: signatureWidget,
            state: PdfPlaceWidgetState.START
          };
          this.$store.commit('pdfViewer/setPlaceWidget', createPayload(this.pdfViewerId, placeWidgetData));
        }
      }
    },
    async addSignHereField(field: DocumentField, place = false): Promise<void> {
      if (this.signatureAnnotationsReadonly) {
        console.warn('The operation "addSignHereField" is not allowed when annotations are read-only');
      }
      else if (this.signatureAnnotations === PdfSignatureAnnotations.NONE) {
        console.warn('The operation "addSignHereField" is not allowed when sign here annotations are disabled');
      }
      else {
        const signHereWidget = await createSignHereWidget(this.pdfViewerId, field, true, true);

        if (place) {
          /* Výběr umístění widgetu podpisového pole */
          const placeWidgetData = {
            widget: signHereWidget,
            state: PdfPlaceWidgetState.START
          };
          this.$store.commit('pdfViewer/setPlaceWidget', createPayload(this.pdfViewerId, placeWidgetData));
        }
        else {
          /* Přidání widgetu podpisového pole dle uvedených souřadnic */
          await this.$store.dispatch('pdfViewer/addSignHereWidget', createPayload(this.pdfViewerId, signHereWidget));
        }
      }
    },
    cancelPlaceWidget(): void {
      const placeWidget = this.$store.getters['pdfViewer/placeWidget'](this.pdfViewerId);
      if (placeWidget) {
        const placeWidgetData = {
          widget: placeWidget,
          state: PdfPlaceWidgetState.CANCEL
        };
        this.$store.commit('pdfViewer/setPlaceWidget', createPayload(this.pdfViewerId, placeWidgetData));
      }
    },
    async createFields(): Promise<void> {
      if (this.documentFieldAssigned) {
        const signatureWidget = await createSignatureWidget(this.pdfViewerId, this.documentFieldAssigned);
        this.$store.commit('pdfViewer/setWidget', createPayload(this.pdfViewerId, signatureWidget));
        return;
      }
      else if (this.documentFieldUsed && this.currentStep?.visualFieldUsage !== VisualFieldUsage.NOT_SUPPORTED) {
        const signatureWidgetEditable = this.documentFieldUsed.type === DocumentFieldType.SP;
        const signatureWidget = await createSignatureWidget(this.pdfViewerId, this.documentFieldUsed, true,
            signatureWidgetEditable);
        this.$store.commit('pdfViewer/setWidget', createPayload(this.pdfViewerId, signatureWidget));
        this.$store.commit('pdfViewer/setDefaultSignHereWidgetUsage', createPayload(this.pdfViewerId, false));
      }

      if (this.currentStep?.visualFieldUsage !== VisualFieldUsage.NOT_SUPPORTED) {
        for (const field of this.documentFieldsUnassigned) {
          const signHereWidget = await createSignHereWidget(this.pdfViewerId, field);
          this.$store.commit('pdfViewer/setWidget', createPayload(this.pdfViewerId, signHereWidget));
        }
      }
    },
    async removeSignatureField(): Promise<void> {
      if (this.signatureAnnotations !== PdfSignatureAnnotations.ALL) {
        console.warn('The operation "removeSignatureField" is not allowed when signature annotations are disabled');
      }
      else {
        const signatureWidgets = this.$store.getters['pdfViewer/signatureWidgets'](this.pdfViewerId, null);
        if (signatureWidgets.length > 0) {
          const widgetId = signatureWidgets[0].id; // signature widget by měl existovat maximálně jeden
          await this.$store.dispatch('pdfViewer/removeSignatureWidget', createPayload(this.pdfViewerId, widgetId));
        }
        else {
          console.warn('Signature field cannot be removed because it does not exist');
        }
      }
    },
    async removeSignHereField(widgetId: number): Promise<void> {
      if (this.signatureAnnotations === PdfSignatureAnnotations.NONE) {
        console.warn('The operation "removeSignHereField" is not allowed when signature annotations are disabled');
      }
      else {
        await this.$store.dispatch('pdfViewer/removeSignHereWidget', createPayload(this.pdfViewerId, widgetId));
      }
    },
    /*
     * Podpisová vrstva
     */
    addSignatureLayerPage(pageNumber: number, instance: PdfPageLayerSignatureInstance): void {
      Vue.set(this.signatureLayerPages, pageNumber, { pageNumber, instance });
    },
    removeSignatureLayerPage(pageNumber: number): void {
      const signatureLayerPage = this.signatureLayerPages[pageNumber];
      if (signatureLayerPage) {
        signatureLayerPage.instance.$destroy();
        Vue.delete(this.signatureLayerPages, pageNumber);
      }
    },
    removeSignatureLayerPages(): void {
      Object.values(this.signatureLayerPages).forEach((page) => page.instance.$destroy());
      this.signatureLayerPages = {};
    },
    renderSignatureLayer(source: PdfPageSource): void {
      const { div: page, info: pageInfo, pageNumber } = source;

      /* Odstranění existující podpisové vrstvy dané stránky */
      this.removeSignatureLayerPage(pageNumber);

      /* Vytvoření nové podpisové vrstvy dané stránky */
      const instance = new PDFPageLayerSignature({
        i18n,
        store,
        vuetify,
        propsData: {
          pageInfo,
          pageNumber,
          pdfViewerId: this.pdfViewerId
        }
      });
      instance.$mount();
      page.appendChild(instance.$el);

      this.addSignatureLayerPage(pageNumber, instance);
    }
  }
})
