



























import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import FlowAttachmentItem from "@/component/workflow/detail/FlowAttachmentItem.vue";
import {PropType} from "vue";
import {FlowEnclosure, FlowEnclosureList, PdfAttachment} from "@/types";

export default mixins(mutationMixin).extend({
  name: 'FlowAttachments',
  components: {FlowAttachmentItem, STextButton},
  props: {
    flow: Object,
    pdfAttachments: Array as PropType<Array<PdfAttachment> | undefined>,
    value: Boolean
  },
  data() {
    return {
      loadingEnclosures: false,
      flowEnclosures: null as FlowEnclosureList | null,
    }
  },
  methods: {
    async loadEnclosures() {
      try {
        this.loadingEnclosures = true;
        const response = await this.axios.get(this.flow._links['sef:enclosures-list'].href);
        this.flowEnclosures = response?.data;
      }
      finally {
        this.loadingEnclosures = false
      }
    }
  },
  computed: {
    enclosureList(): Array<FlowEnclosure> {
      return this.flowEnclosures?._embedded?.enclosures ?? [];
    },
    attachmentList(): Array<PdfAttachment> {
      return this.pdfAttachments ?? [];
    },
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    flow() {
      this.loadEnclosures();
    }
  },
  created() {
    this.loadEnclosures();
  }
});
