









import Vue from "vue";

export default Vue.extend({
  name: 'SLoader',
  props: {
    color: {
      type: String,
      default: 'secondaryText'
    },
    size: {
      type: [Number, String],
      default: 50
    },
    width: Number
  }
})
