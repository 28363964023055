import Vue from "vue";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import axios from "@/plugin/vueAxios";
import {RootState} from "@/store/store";
import {
  DocumentField,
  FlowStep,
  PdfPlaceWidgetData,
  PdfPlaceWidgetState,
  PdfSignatureAnnotations,
  PdfWidgetConfig,
  PdfWidgetType
} from "@/types";
import {
  attachSignHereWidget,
  detachSignHereWidget,
  transformToSignatureWidget,
  transformToSignHereWidget
} from "@/utils/pdfViewerUtils";

interface PdfViewerData {
  currentStep: FlowStep | undefined;
  defaultSignHereWidgetUsage: boolean;
  signatureAnnotations: PdfSignatureAnnotations;
  signatureLayer: {
    placeWidget: PdfWidgetConfig | undefined;
    widgetIdSequence: number;
    widgets: Array<PdfWidgetConfig>;
  },
  signatureLayerReadonly: boolean;
}

interface PdfViewerState {
  pdfViewerIdSequence: number;
  dataDefault: PdfViewerData;
  dataMap: Record<number, PdfViewerData>;
}

export type PdfViewerPayload<T> = {
  pdfViewerId: number;
  data: T;
}

export function createPayload<T>(pdfViewerId: number, data: T): PdfViewerPayload<T> {
  return {
    pdfViewerId,
    data
  };
}

const state = (): PdfViewerState => ({
  pdfViewerIdSequence: 0,
  dataDefault: {
    currentStep: undefined,
    defaultSignHereWidgetUsage: true,
    signatureAnnotations: PdfSignatureAnnotations.NONE,
    signatureLayer: {
      placeWidget: undefined,
      widgetIdSequence: 0,
      widgets: []
    },
    signatureLayerReadonly: false
  },
  dataMap: {}
})

const mutations: MutationTree<PdfViewerState> = {
  deleteData(state: PdfViewerState, pdfViewerId: number) {
    Vue.delete(state.dataMap, pdfViewerId);
  },
  incrementPdfViewerIdSequence(state: PdfViewerState) {
    state.pdfViewerIdSequence++;
  },
  incrementWidgetIdSequence(state: PdfViewerState, pdfViewerId: number) {
    state.dataMap[pdfViewerId].signatureLayer.widgetIdSequence++;
  },
  removeWidget(state: PdfViewerState, payload: PdfViewerPayload<number>) {
    const widgets = state.dataMap[payload.pdfViewerId].signatureLayer.widgets;
    const widgetId = payload.data;
    const index = widgets.findIndex(widget => widget.id === widgetId);
    if (index > -1)
      widgets.splice(index, 1);
    else
      console.warn(`Unable to remove widget ${widgetId}: the widget does not exist`);
  },
  resetSignatureLayer(state: PdfViewerState, pdfViewerId: number) {
    Vue.set(state.dataMap[pdfViewerId], 'signatureLayer', structuredClone(state.dataDefault.signatureLayer));
  },
  setCurrentStep(state: PdfViewerState, payload: PdfViewerPayload<FlowStep>) {
    Vue.set(state.dataMap[payload.pdfViewerId], 'currentStep', payload.data);
  },
  setData(state: PdfViewerState, payload: PdfViewerPayload<PdfViewerData>) {
    Vue.set(state.dataMap, payload.pdfViewerId, payload.data);
  },
  setDefaultSignHereWidgetUsage(state: PdfViewerState, payload: PdfViewerPayload<boolean>) {
    Vue.set(state.dataMap[payload.pdfViewerId], 'defaultSignHereWidgetUsage', payload.data);
  },
  setPlaceWidget(state: PdfViewerState, payload: PdfViewerPayload<PdfPlaceWidgetData>) {
    const widget = payload.data.state === PdfPlaceWidgetState.START ? payload.data.widget : undefined;
    Vue.set(state.dataMap[payload.pdfViewerId].signatureLayer, 'placeWidget', widget);
  },
  setSignatureAnnotations(state: PdfViewerState, payload: PdfViewerPayload<PdfSignatureAnnotations>) {
    Vue.set(state.dataMap[payload.pdfViewerId], 'signatureAnnotations', payload.data);
  },
  setSignatureLayerReadonly(state: PdfViewerState, payload: PdfViewerPayload<boolean>) {
    Vue.set(state.dataMap[payload.pdfViewerId], 'signatureLayerReadonly', payload.data);
  },
  setWidget(state: PdfViewerState, payload: PdfViewerPayload<PdfWidgetConfig>) {
    const widgets = state.dataMap[payload.pdfViewerId].signatureLayer.widgets;
    const widget = payload.data;
    const index = widgets.findIndex(existingWidget => existingWidget.id === widget.id);
    if (index > -1)
      widgets.splice(index, 1, widget);
    else
      widgets.push(widget);
  },
  updateWidget(state: PdfViewerState, payload: PdfViewerPayload<PdfWidgetConfig>) {
    const widgets = state.dataMap[payload.pdfViewerId].signatureLayer.widgets;
    const widget = payload.data;
    const index = widgets.findIndex(existingWidget => existingWidget.id === widget.id);
    if (index > -1)
      widgets.splice(index, 1, widget);
    else
      console.warn(`Unable to update widget ${widget.id}: the widget does not exist`);
  },
  /*
   * Global events
   */
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  sign(state: PdfViewerState, payload: PdfViewerPayload<undefined>) {
    // Empty on purpose, acts as global event
  },
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  signatureWidgetAdded(state: PdfViewerState, payload: PdfViewerPayload<PdfWidgetConfig>) {
    // Empty on purpose, acts as global event
  },
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  signatureWidgetRemoved(state: PdfViewerState, payload: PdfViewerPayload<number>) {
    // Empty on purpose, acts as global event
  },
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  signatureWidgetUpdated(state: PdfViewerState, payload: PdfViewerPayload<PdfWidgetConfig>) {
    // Empty on purpose, acts as global event
  },
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  signHereWidgetAdded(state: PdfViewerState, payload: PdfViewerPayload<PdfWidgetConfig>) {
    // Empty on purpose, acts as global event
  },
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  signHereWidgetRemoved(state: PdfViewerState, payload: PdfViewerPayload<number>) {
    // Empty on purpose, acts as global event
  },
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  signHereWidgetUpdated(state: PdfViewerState, payload: PdfViewerPayload<PdfWidgetConfig>) {
    // Empty on purpose, acts as global event
  }
}

const actions: ActionTree<PdfViewerState, RootState> = {
  async addSignatureWidget({ commit, getters }, payload: PdfViewerPayload<PdfWidgetConfig>): Promise<void> {
    const pdfViewerId = payload.pdfViewerId;
    const signatureWidget = payload.data;
    const placeWidget = getters['placeWidget'](pdfViewerId);

    if (placeWidget && placeWidget.id === signatureWidget.id) {
      /* Ukončení umísťování widgetu podpisu */
      const placeWidgetData: PdfPlaceWidgetData = {
        widget: placeWidget,
        state: PdfPlaceWidgetState.DONE
      };
      commit('setPlaceWidget', createPayload(pdfViewerId, placeWidgetData));
    }

    commit('setWidget', createPayload(pdfViewerId, signatureWidget));

    /* Vytvoření podpisu pro aktuální krok skrze API */
    const currentStep: FlowStep | undefined = getters['currentStep'](pdfViewerId);
    if (currentStep?._links['sef:step-set-signature-field']) {
      try {
        const response = await axios.put(currentStep._links['sef:step-set-signature-field'].href, {
          ...signatureWidget.rect,
          page: signatureWidget.pageNumber,
          setBySigner: true
        });
        const createdSignatureField = response.data as DocumentField;
        Vue.set(signatureWidget.params, 'field', createdSignatureField);

        commit('signatureWidgetAdded', createPayload(pdfViewerId, signatureWidget));
      }
      catch (e) {
        console.error(
            `An error occurred while setting selected signature field for current step ${currentStep.stepId}`, e);
        commit('removeWidget', createPayload(pdfViewerId, signatureWidget.id));
      }
    }
  },
  async addSignHereWidget({ commit, getters }, payload: PdfViewerPayload<PdfWidgetConfig>): Promise<void> {
    const pdfViewerId = payload.pdfViewerId;
    const signHereWidget = payload.data;
    const placeWidget = getters['placeWidget'](pdfViewerId);

    if (placeWidget && placeWidget.id === signHereWidget.id) {
      /* Ukončení umísťování widgetu podpisového pole */
      const placeWidgetData: PdfPlaceWidgetData = {
        widget: placeWidget,
        state: PdfPlaceWidgetState.DONE
      };
      commit('setPlaceWidget', createPayload(pdfViewerId, placeWidgetData));
    }

    commit('setWidget', createPayload(pdfViewerId, signHereWidget));
    commit('signHereWidgetAdded', createPayload(pdfViewerId, signHereWidget));
  },
  async attachSignHereWidget({ commit, getters }, payload: PdfViewerPayload<{ targetWidgetId: number, widget: PdfWidgetConfig }>): Promise<void> {
    const pdfViewerId = payload.pdfViewerId;
    const targetSignHereWidgetId = payload.data.targetWidgetId;
    const targetSignHereWidget: PdfWidgetConfig = getters['signHereWidget'](pdfViewerId, targetSignHereWidgetId);

    if (targetSignHereWidget) {
      /* Zrušení umísťování widgetu podpisového pole */
      const placeWidget = getters['placeWidget'](pdfViewerId);
      if (placeWidget) {
        const placeWidgetData: PdfPlaceWidgetData = {
          widget: placeWidget,
          state: PdfPlaceWidgetState.CANCEL
        };
        commit('setPlaceWidget', createPayload(pdfViewerId, placeWidgetData));
      }

      /* Přiřazení podpisového pole k již existujícímu podpisovému poli */
      const attachedSignHereWidget = attachSignHereWidget(targetSignHereWidget, payload.data.widget);
      commit('updateWidget', createPayload(pdfViewerId, attachedSignHereWidget));

      commit('signHereWidgetAdded', createPayload(pdfViewerId, attachedSignHereWidget));
    }
    else {
      console.warn(`Unable to attach sign here widget: target widget ${targetSignHereWidgetId} does not exist`);
    }
  },
  deleteData({ commit }, pdfViewerId: number): void {
    commit('deleteData', pdfViewerId);
  },
  initData({ commit, state }): number {
    commit('incrementPdfViewerIdSequence');
    const pdfViewerId = state.pdfViewerIdSequence;
    commit('setData', createPayload(pdfViewerId, structuredClone(state.dataDefault)));
    return pdfViewerId;
  },
  nextWidgetId({ commit, state }, pdfViewerId: number): number {
    commit('incrementWidgetIdSequence', pdfViewerId);
    return state.dataMap[pdfViewerId].signatureLayer.widgetIdSequence;
  },
  async removeSignatureWidget({ commit, getters }, payload: PdfViewerPayload<number>): Promise<void> {
    const pdfViewerId = payload.pdfViewerId;
    const signatureWidgetId = payload.data;
    const placeWidget = getters['placeWidget'](pdfViewerId);

    if (placeWidget && placeWidget.id === signatureWidgetId) {
      /* Zrušení umísťování widgetu podpisu */
      const placeWidgetData: PdfPlaceWidgetData = {
        widget: placeWidget,
        state: PdfPlaceWidgetState.CANCEL
      };
      commit('setPlaceWidget', createPayload(pdfViewerId, placeWidgetData));
    }
    else {
      const signatureWidget: PdfWidgetConfig = getters['signatureWidget'](pdfViewerId, signatureWidgetId);
      if (signatureWidget) {
        if (signatureWidget.params.transformed) {
          /* Transformace widgetu podpisu zpět na widget podpisového pole  */
          commit('updateWidget', createPayload(pdfViewerId, transformToSignHereWidget(signatureWidget)));
        }
        else {
          /* Odstranění widgetu podpisu */
          commit('removeWidget', createPayload(pdfViewerId, signatureWidget.id));
        }

        /* Odstranění podpisu pro aktuální krok skrze API */
        const currentStep: FlowStep | undefined = getters['currentStep'](pdfViewerId);
        if (currentStep?._links['sef:step-remove-signature-field']) {
          try {
            await axios.delete(currentStep._links['sef:step-remove-signature-field'].href);

            commit('signatureWidgetRemoved', createPayload(pdfViewerId, signatureWidget.id));
          }
          catch (e) {
            console.error(
                `An error occurred while removing selected signature field for current step ${currentStep.stepId}`, e);
            commit('updateWidget', createPayload(pdfViewerId, signatureWidget));
          }
        }
      }
      else {
        console.warn(`Unable to remove signature widget ${signatureWidgetId}: the widget does not exist`);
      }
    }
  },
  removeSignHereWidget({ commit, getters }, payload: PdfViewerPayload<number>): void {
    const pdfViewerId = payload.pdfViewerId;
    const signHereWidgetId = payload.data;
    const placeWidget = getters['placeWidget'](pdfViewerId);

    if (placeWidget && placeWidget.id === signHereWidgetId) {
      /* Zrušení umísťování widgetu podpisového pole */
      const placeWidgetData: PdfPlaceWidgetData = {
        widget: placeWidget,
        state: PdfPlaceWidgetState.CANCEL
      };
      commit('setPlaceWidget', createPayload(pdfViewerId, placeWidgetData));
    }
    else {
      const signHereWidget: PdfWidgetConfig = getters['signHereWidget'](pdfViewerId, signHereWidgetId);
      if (signHereWidget) {
        if (signHereWidget.params.transformed) {
          /* Transformace widgetu podpisového pole */
          commit('updateWidget', createPayload(pdfViewerId, detachSignHereWidget(signHereWidget)));
        }
        else {
          /* Odstranění widgetu podpisového pole */
          commit('removeWidget', createPayload(pdfViewerId, signHereWidgetId));
        }

        commit('signHereWidgetRemoved', createPayload(pdfViewerId, signHereWidgetId));
      }
      else {
        console.warn(`Unable to remove sign here widget ${signHereWidgetId}: the widget does not exist`);
      }
    }
  },
  async updateSignatureWidget({ commit, getters }, payload: PdfViewerPayload<PdfWidgetConfig>): Promise<void> {
    const pdfViewerId = payload.pdfViewerId;
    const signatureWidget = payload.data;
    const signatureWidgetOrig:PdfWidgetConfig = getters['signatureWidget'](pdfViewerId, signatureWidget.id);

    commit('updateWidget', createPayload(pdfViewerId, signatureWidget));

    /* Aktualizace podpisu pro aktuální krok skrze API */
    const currentStep: FlowStep | undefined = getters['currentStep'](pdfViewerId);
    if (currentStep?._links['sef:step-set-signature-field']) {
      try {
        await axios.put(currentStep._links['sef:step-set-signature-field'].href, {
          ...signatureWidget.rect,
          fieldId: signatureWidget.params.field?.fieldId,
          page: signatureWidget.pageNumber
        });

        commit('signatureWidgetUpdated', createPayload(pdfViewerId, signatureWidget));
      }
      catch (e) {
        console.error(
            `An error occurred while updating selected signature field for current step ${currentStep.stepId}`, e);
        commit('updateWidget', createPayload(pdfViewerId, signatureWidgetOrig));
      }
    }
  },
  async updateSignHereWidget({ commit }, payload: PdfViewerPayload<PdfWidgetConfig>) {
    const pdfViewerId = payload.pdfViewerId;
    const signHereWidget = payload.data;

    commit('updateWidget', createPayload(pdfViewerId, signHereWidget));
    commit('signHereWidgetUpdated', createPayload(pdfViewerId, signHereWidget));
  },
  async useSignHereWidget({ commit, dispatch, getters }, payload: PdfViewerPayload<number>): Promise<void> {
    const pdfViewerId = payload.pdfViewerId;
    const signHereWidgetId = payload.data;
    const signHereWidget: PdfWidgetConfig = getters['signHereWidget'](pdfViewerId, signHereWidgetId);

    if (signHereWidget) {
      /* Zrušení umísťování widgetu podpisu */
      const placeWidget = getters['placeWidget'](pdfViewerId);
      if (placeWidget) {
        const placeWidgetData: PdfPlaceWidgetData = {
          widget: placeWidget,
          state: PdfPlaceWidgetState.CANCEL
        };
        commit('setPlaceWidget', createPayload(pdfViewerId, placeWidgetData));
      }

      /* Odstranění všech widgetů podpisu */
      const signatureWidgets: Array<PdfWidgetConfig> = getters['signatureWidgets'](pdfViewerId, null);
      const signatureWidgetIds: Array<number> = signatureWidgets.map(widget => widget.id);
      signatureWidgetIds.forEach(widgetId => dispatch('removeSignatureWidget', { pdfViewerId, data: widgetId }));

      /* Transformace widgetu podpisového pole na widget podpisu */
      const signatureWidget = transformToSignatureWidget(signHereWidget);
      commit('updateWidget', createPayload(pdfViewerId, signatureWidget));

      /* Nastavení podpisu pro aktuální krok skrze API */
      const currentStep: FlowStep | undefined = getters['currentStep'](pdfViewerId);
      if (currentStep?._links['sef:step-set-signature-field']) {
        try {
          await axios.put(currentStep._links['sef:step-set-signature-field'].href, {
            fieldId: signatureWidget.params.field?.fieldId
          });

          commit('signatureWidgetAdded', createPayload(pdfViewerId, signatureWidget));
          commit('setDefaultSignHereWidgetUsage', createPayload(pdfViewerId, false));
        }
        catch (e) {
          console.error(
              `An error occurred while setting selected signature field for current step ${currentStep.stepId}`, e);
          commit('updateWidget', createPayload(pdfViewerId, signHereWidget));
        }
      }
    }
    else {
      console.warn(`Unable to use sign here widget ${signHereWidgetId} for signature: the widget does not exist`);
    }
  }
}

const getters: GetterTree<PdfViewerState, RootState> = {
  currentStep: (state: PdfViewerState) => (pdfViewerId: number): FlowStep | undefined => {
    return state.dataMap[pdfViewerId]?.currentStep;
  },
  defaultSignHereWidgetUsage: (state: PdfViewerState) => (pdfViewerId: number): boolean => {
    return !!state.dataMap[pdfViewerId]?.defaultSignHereWidgetUsage;
  },
  placeWidget: (state: PdfViewerState) => (pdfViewerId: number): PdfWidgetConfig | undefined => {
    return state.dataMap[pdfViewerId]?.signatureLayer?.placeWidget;
  },
  signatureAnnotations: (state: PdfViewerState) => (pdfViewerId: number): PdfSignatureAnnotations => {
    return state.dataMap[pdfViewerId]?.signatureAnnotations ?? PdfSignatureAnnotations.NONE;
  },
  signatureLayerReadonly: (state: PdfViewerState) => (pdfViewerId: number): boolean => {
    return !!state.dataMap[pdfViewerId]?.signatureLayerReadonly;
  },
  signatureWidget: (state: PdfViewerState) => (pdfViewerId: number, widgetId: number): PdfWidgetConfig | undefined => {
    return state.dataMap[pdfViewerId]?.signatureLayer?.widgets?.find(
        widget => widget.type === PdfWidgetType.SIGNATURE && widget.id === widgetId);
  },
  signatureWidgets: (state: PdfViewerState) => (pdfViewerId: number, pageNumber: number | null): Array<PdfWidgetConfig> => {
    return state.dataMap[pdfViewerId]?.signatureLayer?.widgets?.filter(
        widget => widget.type === PdfWidgetType.SIGNATURE &&
            (pageNumber === null || widget.pageNumber === pageNumber)) ?? [];
  },
  signHereWidget: (state: PdfViewerState) => (pdfViewerId: number, widgetId: number): PdfWidgetConfig | undefined => {
    return state.dataMap[pdfViewerId]?.signatureLayer?.widgets?.find(
        widget => widget.type === PdfWidgetType.SIGN_HERE && widget.id === widgetId);
  },
  signHereWidgets: (state: PdfViewerState) => (pdfViewerId: number, pageNumber: number | null): Array<PdfWidgetConfig> => {
    return state.dataMap[pdfViewerId]?.signatureLayer?.widgets?.filter(
        widget => widget.type === PdfWidgetType.SIGN_HERE &&
            (pageNumber === null || widget.pageNumber === pageNumber)) ?? [];
  },
  widgets: (state: PdfViewerState) => (pdfViewerId: number): Array<PdfWidgetConfig> => {
    return state.dataMap[pdfViewerId]?.signatureLayer?.widgets ?? [];
  }
}

export const pdfViewer: Module<PdfViewerState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}