import {formatDate} from "@/utils/dateUtils";
import Vue, {PropType} from "vue";
import {Batch, NavigationMode, ReportRow} from "@/types";
import {FLOW_STEP_STATE_MESSAGE_MAP, UNKNOWN_REPORT_STATE_ERROR_MESSAGE} from "@/utils/constants";

export default Vue.extend({
  props: {
    batch: {
      type: Object as PropType<Batch>,
      required: true
    }
  },

  computed: {
    batchDate(): string {
      return formatDate(this.batch.createdAt, 'd. L. yyyy');
    },

    batchName(): string {
      return this.$t('workflow.interactionCenter.batchName',
          {count: this.totalStepCount, date: this.batchDate}).toString();
    },

    isBatch(): boolean {
      return this.batch.bulkMode;
    },

    stepsStats(): [number, number, number, number] {
      const stats: [number, number, number, number] = [0, 0, 0, 0]; // [finished, in_progress, authorization, error]
      this.batch.reportRows.forEach(row => {
        switch (row.reportStateType) {
          case 'finished':
            stats[0] += row.count;
            break;
          case 'in_progress':
            stats[1] += row.count;
            break;
          case 'authorization':
            stats[2] += row.count;
            break;
          case 'error':
            stats[3] += row.count;
            break;
        }
      });
      return stats;
    },

    finishedStepCount(): number {
      return this.processingStats[0];
    },

    processingDone(): number {
      return this.processingStats[0] + this.processingStats[2] + this.processingStats[3];
    },

    processingStats(): [number, number, number, number] {
      return this.stepsStats;
    },

    totalStepCount(): number {
      return this.batch.count;
    },

    reportRows(): Array<ReportRow> {
      return this.batch.reportRows;
    },

    isProcessingBatch(): boolean {
      return this.reportRows.find(row => row.reportStateType !== "error") !== undefined;
    },

    countErrorStep(): number {
      return this.batch.reportRows.reduce((sum, row) => row.reportStateType === 'error' ? sum + row.count : sum, 0);
    },

    countAuthorizationStep(): number {
      return this.batch.reportRows.reduce((sum, row) => row.reportStateType === 'authorization' ? sum + row.count : sum, 0);
    }
  },
  methods: {
    getErrorMessage(row: ReportRow) {
      const steps = row._embedded?.steps || [];
      let message;
      if (steps.length > 0 && steps[0].lastError)
        message = steps[0].lastError;
      else if (row.reportStateId) {
        message = this.$t(FLOW_STEP_STATE_MESSAGE_MAP[row.reportStateId] ?? UNKNOWN_REPORT_STATE_ERROR_MESSAGE,
            {reportStateId: row.reportStateId});
      }
      else {
        message = this.$t('common.errors.general.short');
      }
      return message;
    },

    getFlowSubject(row: ReportRow) {
      const steps = row._embedded?.steps || [];
      if (steps.length > 0 && steps[0].flowSubject)
        return steps[0].flowSubject;
      else
        return undefined;
    },

    async view(row: ReportRow) {
      const flowUrl = row._embedded?.steps?.[0]?._links?.['sef:workflow'].href;
      try {
        if (this.$store.getters["navigation/mode"] === NavigationMode.PROFILE) {
          this.$router.push('/notifications/error').catch(e => console.log(e));
        }
        await this.$store.dispatch('workflow/viewFlow', {flowUrl: flowUrl});
      }
      catch (e) {
        this.$store.commit('notification/showMessage', {
          content: this.$t('common.errors.general.short'),
          type: 'error'
        });
      }
    }
  }
})
