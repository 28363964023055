import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import store from '../store/store'
import {i18n} from './i18n'

// Extension of AxiosRequest Config type
declare module 'axios' {
    interface AxiosRequestConfig{
        authorized?: boolean;
        errorHandle?: boolean;
    }
}

// eslint-disable-next-line no-undef
axios.defaults.baseURL = obeliskConfig.apiUrl;
axios.defaults.paramsSerializer = {
  indexes: null
};

// Request interceptor
axios.interceptors.request.use(
    function (config: AxiosRequestConfig) {
        if(!config.headers) config.headers = {};
        if (!(Object.prototype.hasOwnProperty.call(config, 'authorized') && config.authorized === false)) {
            //Keycloak
            config.headers['Authorization'] = 'Bearer ' + store.getters["auth/token"];
        }
        config.headers['Accept-language'] = i18n.locale;

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Response interceptor
axios.interceptors.response.use(
    function (response: AxiosResponse) {
        return response;
    },
    function(error) {
        // errorHandle config
        if( Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') && error.config.errorHandle === false ) {
            return Promise.reject(error);
        }
        if (error.response) {
          // we need to check the content type because we could get any of those for a different reason than server validation error
          if (Math.floor(error.response.status / 100) === 4
              && error.response.headers['content-type'] === 'application/json') {
            const errorMessage = error.response.data?.title ?? i18n.t('common.errors.serverValidation');
            store.commit('notification/showMessage', {content: errorMessage, type: 'error', report: true, error: error});
          }
          else {
            store.commit('notification/showMessage',
                {content: i18n.t('common.errors.general.withTryAgain'), type: 'error', report: true, error: error});
          }
        }
        return Promise.reject(error);
    });

export default axios;
