
























import Vue from "vue";

export default Vue.extend({
  name: 'SProgressLinear',

  props: {
    actual: Number,
    color: {
      type: String,
      default: 'secondaryContrast'
    },
    indeterminate: Boolean,
    thin: Boolean,
    title: String,
    total: {
      type: Number,
      default: 100
    }
  },

  computed: {
    height(): string {
      return this.thin ? '4px' : '8px';
    },
    percent(): number {
      return Math.round(this.actual / this.total * 100);
    },
    status(): {actual: number; total: number; percent: number} {
      return {
        actual: this.actual,
        total: this.total,
        percent: this.percent
      };
    }
  }
})
