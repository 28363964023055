
































import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import SAvatar from "@/component/ui/SAvatar.vue";
import {UserAccount} from "@/types";
import {nameToDisplay} from "@/utils/accountUtils";
import STooltip from "@/component/ui/STooltip.vue";

export default mixins(displayMixin).extend({
  name: 'UserInfo',

  components: {STooltip, SAvatar},

  props: {
    small: Boolean,
    text: String,
    user: Object as PropType<UserAccount | undefined>,
  },

  computed: {
    userAvatar(): string | undefined {
      return this.user?._links?.userAvatar?.href;
    },
    userName(): string | undefined {
      return this.user ? nameToDisplay(this.user) : undefined;
    }
  }
})
