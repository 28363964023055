import { render, staticRenderFns } from "./FlowReasonToSign.vue?vue&type=template&id=9004fda4&scoped=true&"
import script from "./FlowReasonToSign.vue?vue&type=script&lang=ts&"
export * from "./FlowReasonToSign.vue?vue&type=script&lang=ts&"
import style0 from "./FlowReasonToSign.vue?vue&type=style&index=0&id=9004fda4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9004fda4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VIcon})
