import { render, staticRenderFns } from "./SignatureExternalContent.vue?vue&type=template&id=2b618e1a&scoped=true&"
import script from "./SignatureExternalContent.vue?vue&type=script&lang=ts&"
export * from "./SignatureExternalContent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b618e1a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VFadeTransition})
