import Vue from 'vue'
import {OS} from "@/utils/constants";
import {CardClientConfig} from "@/types";
import {getOS} from "@/utils/utils";

export default Vue.extend({
  computed: {
    clientConfig(): { [key in OS]?: CardClientConfig } {
      return this.$store.getters["config/clientConfig"];
    },
    supportedOS(): Array<OS> {
      return Object.keys(this.clientConfig) as Array<OS>;
    },
    detectedOS(): OS | null {
      return getOS();
    },
    isClientSupported(): boolean {
      if (!this.detectedOS) return false;
      return this.supportedOS.findIndex(os => os.toLowerCase() === this.detectedOS?.toLowerCase()) !== -1;
    },
    currentClientConfig(): CardClientConfig | undefined {
      if (!this.detectedOS) return undefined;
      return this.clientConfig[this.detectedOS];
    },
    downloadUrl(): string | undefined {
      return this.currentClientConfig?.clientDownloadUrl;
    }
  },
  methods: {
    downloadClient(): void {
      window.open(this.downloadUrl, '_blank')?.focus();
    }
  }
})
