/*
 * https://dev.to/astagi/add-custom-icons-to-font-awesome-4m67
 *
 * 'e001' představuje hodnotu Unicode, která je větší, než největší hodnota Unicode použitá FontAwesome. Pokud budeme
 * přidávat další ikony, použijeme hodnoty Unicode 'e002', 'e003', ...
 */

import {IconDefinition} from "@fortawesome/pro-regular-svg-icons";

export const sefBankId = {
  prefix: 'fab',
  iconName: 'bankid',
  icon: [
      512,
      512,
      [],
      'e001',
      'm 211.41126,125.86589 h 91.9566 c 72.44999,0 108.67583,65.37026 108.67583,130.13411 0,65.37027 -36.22584,130.13396 -108.67583,130.13396 h -91.9566 V 355.8705 h 39.01166 V 156.12951 H 211.41126 Z M 158.46661,467.84646 H 306.15507 C 438.23684,467.84646 504,361.92315 504,256 504,150.0767 438.23684,44.153541 306.15507,44.153541 H 158.46661 Z M 8.0000015,125.86589 H 91.596204 V 44.153541 H 8.0000015 Z m 0,30.26362 H 91.596204 V 467.84646 H 8.0000015 Z'
  ]
} as unknown as IconDefinition
