








import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import SListMenu, {MenuItem} from "@/component/ui/SListMenu.vue";

type ProfileMenuItem = {
  name: string,
  text: string,
  icon: string,
  external: boolean,
  url: string,
  enabled?: boolean
}

export default mixins(mutationMixin).extend({
  name: "ProfileMenu",
  components: {SListMenu},
  props: {
    isExternalUser: Boolean
  },
  computed: {
    // computed 'cause we want to access a computed property here
    items(): Array<ProfileMenuItem> {
      return [
        {
          name: 'personalInfo',
          text: this.$t('nav.profile.items.info').toString(),
          icon: 'user',
          external: true,
          url: 'info'
        },
        {
          name: 'visualizations',
          text: this.$t('nav.profile.items.visual').toString(),
          icon: 'addressCard',
          external: true,
          url: 'visualisation'
        },
        {
          name: 'signature',
          text: this.$t('nav.profile.items.signature').toString(),
          icon: 'sig',
          external: true,
          url: 'signature'
        },
        {
          name: 'identities',
          text: this.$t('nav.profile.items.identities').toString(),
          icon: 'idBadge',
          external: false,
          url: 'identities'
        },
        {
          name: 'support',
          text: this.$t('nav.profile.items.support').toString(),
          icon: 'support',
          external: false,
          url: 'support',
          enabled: this.isSupportEnabled
        },
      ]
    },
    filteredItems(): Array<MenuItem> {
      return this.items
          .map(item => ({
            name: item.name,
            text: item.text,
            icon: item.icon,
            url: item.url,
            visible: (!this.isExternalUser || item.external) && (item.enabled ?? true)
          }))
    },
    isSupportEnabled(): boolean {
      return this.$store.getters["config/supportEnabled"]
    }
  }
});
