

















































import {ValidationProvider} from 'vee-validate';
import formMixin from '@/mixins/formMixin';
import mixins from "vue-typed-mixins";

export default mixins(formMixin).extend({
  name: 'SCombobox',
  components: {
    ValidationProvider
  },
  props: {
    appendIcon: String,
    disabled: Boolean,
    hideNoData: {
      type: Boolean,
      default: false
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    hint: String,
    id: String,
    itemText: {
      type: [Array, Function, String],
      default: 'text'
    },
    itemValue: {
      type: [Array, Function, String],
      default: 'value'
    },
    itemAppearanceFunc: Function,
    items: Array,
    label: String,
    loading: Boolean,
    noFilter: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    prefix: String,
    prependIcon: String,
    readonly: Boolean,
    rules: Array,
    searchInput: String,
    suffix: String,
    uppercase: Boolean,
    value: Array<string | number>
  },
  computed: {
    localValue: {
      get(): Array<string | number> {
        return this.value;
      },
      set(value: Array<string | number>) {
        const uniqueValues = new Set<string | number>();
        value.forEach(item => uniqueValues.add(typeof item === "string" ? item.trim().replace(/\s+/g, ' ') : item))
        const trimmedValues = [...uniqueValues];
        this.$emit('input', trimmedValues);
      }
    },
    localSearchInput: {
      get(): string {
        return this.searchInput;
      },
      set(value: string) {
        this.$emit('update:search-input', value)
      }
    }
  },

  methods: {
    onChange(e: InputEvent) {
      this.$emit('change', e)
    }
  }
})
