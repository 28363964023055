






















































import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import mobileMixin from "@/mixins/mobileMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SBase64ImageSelector from "@/component/ui/form/SBase64ImageSelector.vue";
import SSignaturePad from "@/component/ui/SSignaturePad.vue";
import SImg from "@/component/ui/SImg.vue";

export default mixins(mobileMixin).extend({
  name: 'SSignatureSelector',
  components: {SImg, STextButton, SSignaturePad, SBase64ImageSelector},
  props: {
    drawingOnly: Boolean,
    value: {
      type: String as PropType<string | undefined>,
      required: true
    },
    predefinedValue: {
      type: String as PropType<string>,
      required: false
    },
    showPredefinedValue:{
      type:Boolean,
      required:false,
      default:false
    },
    //for AdHoc Dialog
    drawAvailable: {
      type: Boolean,
      default: true,
    },
    textAvailable: {
      type: Boolean,
      default: true,
    },
    pictureAvailable: {
      type: Boolean,
      default: true,
    },
    defaultTab: {
      type: String as PropType<'predefined'| 'draw' | 'text' | 'image'>,
      default: "#image"
    }
  },
  data(){
    return {
      selectedTab: this.defaultTab,
      drawImage: undefined as string|undefined,
      textImage: undefined as string|undefined,
      error: undefined as string|undefined,
      imageValue:undefined as string|undefined,
      localValue:undefined as string|undefined,
    }
  },
  computed: {
    predefinedImage: {
      get(): string | undefined {
        if (!this.showPredefinedValue)
          return this.value;

        return this.imageValue;
      },
      set(value: string | undefined): void {
        if (!this.showPredefinedValue) {
          this.$emit('input',value);
          this.imageValue = value;
        }
        else {
          this.imageValue = value;
        }
      }
    },
    predefinedValue: {
      get(): string | undefined {
        if (this.showPredefinedValue)
          return this.value;

        return undefined;
      }
    },
    showImageTab(): boolean {
      return !this.drawingOnly;
    },
    showDrawTab(): boolean {
      return true;
    },
    showTextTab(): boolean {
      return !this.drawingOnly;
    },
    signaturePadActions(): boolean {
      return this.selectedTab !== "image" || this.drawingOnly;
    }
  },
  methods: {
    validate(): boolean {
      this.saveSignature();
      if(!this.localValue){
        this.error = this.$t('common.validation.required').toString();
        return false;
      }
      this.error = undefined;
      this.$emit('input', this.localValue);
      return true;
    },
    clear(): void {
      if(this.$refs.drawPad) (this.$refs.drawPad as InstanceType<typeof SSignaturePad>).clear();
      if(this.$refs.textPad) (this.$refs.textPad as InstanceType<typeof SSignaturePad>).clear();
      if(this.$refs.imageSelector) (this.$refs.imageSelector as InstanceType<typeof SBase64ImageSelector>).clear()
      this.error = undefined;
    },
    saveSignature(){
      //clear value
      this.localValue=undefined;

      if(this.selectedTab === "draw" || this.drawingOnly){
        if(!this.drawImage) return;
        this.localValue = this.drawImage.split(',')[1];
      }
      else if(this.selectedTab === "text"){
        if(!this.textImage) return;
        this.localValue = this.textImage.split(',')[1];
      }
      else if(this.selectedTab === "image"){
        if(!this.predefinedImage) return;
        this.localValue = this.predefinedImage;
      }
      else if(this.selectedTab === "predefined"){
        if(!this.predefinedValue) return;
        this.localValue = this.predefinedValue;
      }

      if(this.$refs.imageSelector) {
        (this.$refs.imageSelector as InstanceType<typeof SBase64ImageSelector>).resetValidations();
      }
    },
  },
  watch: {
    drawImage(){
      if(this.selectedTab === "draw")
        this.error=undefined;
    },
    textImage(){
      if(this.selectedTab==="text")
        this.error=undefined;
    },
    imageValue(){
      if(this.selectedTab==="image")
        this.error=undefined;
    },
    value(){
      this.selectedTab=this.defaultTab;
    }
  }
})
