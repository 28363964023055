




























import Vue, {PropType} from "vue";
import SignatureContentLayout from "@/component/signature/SignatureContentLayout.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {Flow} from "@/types";

export default Vue.extend({
  name: 'SignatureOnlineRejectionConfirmation',

  components: {SignatureContentLayout, STextButton},

  props: {
    actionBack: Boolean,
    flow: {
      type: Object as PropType<Flow>,
      required: true
    }
  },

  computed: {
    confirmationPath(): string {
      return this.flowRecalled ? 'signature.online.rejectionConfirmation.recalled.confirmation' :
          'signature.online.rejectionConfirmation.rejected.confirmation';
    },
    flowRecalled(): boolean {
      return this.flow.state === 'recalled';
    },
    subject(): string | undefined {
      return this.flow?.subject;
    },
    title(): string {
      return this.flowRecalled ? this.$t('signature.online.rejectionConfirmation.recalled.title').toString() :
          this.$t('signature.online.rejectionConfirmation.rejected.title').toString();
    }
  },

  methods: {
    back(): void {
      this.$emit('back');
    }
  }
})
