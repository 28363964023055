
































import Vue from "vue";
import {ValidationObserver} from "vee-validate";
import SignatureContentLayout from "@/component/signature/SignatureContentLayout.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SForm from "@/component/ui/form/SForm.vue";
import STextField from "@/component/ui/form/STextField.vue";

export default Vue.extend({
  name: 'SignatureExternalUserName',

  components: {SForm, SignatureContentLayout, STextButton, STextField},

  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    }
  },

  computed: {
    localFirstName: {
      get(): string | undefined {
        return this.firstName;
      },
      set(value: string | undefined) {
        this.$emit('update:firstName', value);
      }
    },
    localLastName: {
      get(): string | undefined {
        return this.lastName;
      },
      set(value: string | undefined) {
        this.$emit('update:lastName', value);
      }
    }
  },

  methods: {
    async next(): Promise<void> {
      const valid = await (this.$refs.externalUserNameForm as InstanceType<typeof ValidationObserver>).validate();
      if (valid)
        this.$emit('next');
    }
  }
})
